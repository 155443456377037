import ToolTip from '@Components/RadixComponents/ToolTip';
import { ReactNode, useRef, useState } from 'react';
import CaptureComponent from '../Charts/CaptureComponent';
import { FlexColumn, FlexRow } from '../Layouts';
import Portal from '../Layouts/Portal';

type FullScreenHeaderProps = {
  title?: string;
  headerContent?: ReactNode;
  children?: ReactNode;
  isDownloadable?: boolean;
  isShow: boolean;
  onToggle?: () => void;
  downloadName?: string;
};

export default function FullScreen({
  isShow,
  title,
  children,
  headerContent,
  isDownloadable,
  onToggle,
  downloadName,
}: FullScreenHeaderProps) {
  const [hideNonDownloadBtn, setHideNonDownloadBtn] = useState(false);
  const contentRef = useRef<HTMLDivElement | null>(null);

  function handleDownload() {
    // wrapping the IO blocking task into macro task
    // this create chances to hide our modal close button
    setHideNonDownloadBtn(true);
    setTimeout(() => {
      CaptureComponent({
        componentRef: contentRef,
        captureName: downloadName || 'dvs_asset',
      });
      setHideNonDownloadBtn(false);
    });
  }

  if (!isShow) return null;

  return (
    <Portal>
      <div className="naxatw-h-full naxatw-w-full naxatw-p-6" ref={contentRef}>
        <div className="naxatw-h-full naxatw-rounded-2xl naxatw-bg-white naxatw-p-6 lg:naxatw-px-10">
          <FlexColumn gap={6}>
            <FlexRow className="naxatw-w-full naxatw-items-center">
              <FlexRow className="naxatw-w-full naxatw-flex-1">
                {title && <h4>{title}</h4>}
                {headerContent}
              </FlexRow>
              {!hideNonDownloadBtn && (
                <FlexRow className="naxatw-items-center naxatw-gap-4">
                  {isDownloadable && (
                    <ToolTip
                      name="download"
                      className="naxatw-text-primary-300"
                      message="Download"
                      iconClick={() => handleDownload()}
                    />
                  )}
                  <ToolTip
                    name="close"
                    className="naxatw-rounded-full naxatw-p-2 naxatw-text-primary-300 hover:naxatw-bg-gray-100"
                    message="Exit fullscreen"
                    iconClick={onToggle}
                  />
                </FlexRow>
              )}
            </FlexRow>
            <div className="scrollbar naxatw-overflow-y-auto">{children}</div>
          </FlexColumn>
        </div>
      </div>
    </Portal>
  );
}
