import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import HeaderSwicthTab from '@Components/common/HeaderSwicthTab';
import Searchbar from '@Components/common/SearchBar';
import useDebouncedInput from '@Hooks/useDebouncedInput';
import { setPublicSearchText } from '@Store/actions/knowledgeRepository';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { knowledgeLibrarytabOptions } from '@Constants/mainDashboard';
import { useQuery } from '@tanstack/react-query';
import { getKnowledgeRepositoryDocumentsCount } from '@Services/knowledgeRepository';
import { prefixWithZero } from '@Utils/index';
import { FlexColumn } from '@Components/common/Layouts';

const HeaderSection = () => {
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState(pathname || '');
  const dispatch = useTypedDispatch();
  const searchText = useTypedSelector(
    state => state.knowledgeRepository.publicSearchText,
  );

  const startDate = useTypedSelector(
    state => state?.mainDashboard?.filterParams?.startDate,
  );
  const endDate = useTypedSelector(
    state => state?.mainDashboard?.filterParams?.endDate,
  );

  const sectorGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.sector,
  );

  const programIds = useTypedSelector(
    state => state.mainDashboard.filterParams.program,
  );
  const fileTypeParams = useTypedSelector(
    state => state.mainDashboard.filterParams.fileType,
  );

  const queryKey = [
    'knowledgeRepository',
    'documentCounts',
    startDate,
    endDate,
    sectorGroupParams,
    programIds,
    fileTypeParams,
  ];
  const { data } = useQuery({
    queryKey,
    queryFn: async () =>
      Promise.all([
        // prgrammatic count
        getKnowledgeRepositoryDocumentsCount({
          is_contextual: false,
          program: programIds?.join(','),
          sector: sectorGroupParams?.join(','),
          start_date: startDate,
          end_date: endDate,
          file_type: fileTypeParams ? fileTypeParams.join(',') : '',
        }),

        // contextual count
        getKnowledgeRepositoryDocumentsCount({
          is_contextual: true,
          program: programIds?.join(','),
          sector: sectorGroupParams?.join(','),
          start_date: startDate,
          end_date: endDate,
          file_type: fileTypeParams ? fileTypeParams.join(',') : '',
        }),
      ]),
    select: res => res?.map(r => r.data?.count),
  });

  const [value, handleChange] = useDebouncedInput({
    ms: 400,
    init: searchText,
    onChange: debouncedEvent => {
      dispatch(setPublicSearchText(debouncedEvent.target.value));
    },
  });

  return (
    <FlexColumn className="naxatw-gap-2">
      <div className="naxatw-grid naxatw-w-full naxatw-gap-5 lg:!naxatw-grid-cols-[3.3fr_1fr]">
        <h5 className="naxatw-leading-[2.375rem] naxatw-tracking-[-0.03rem] naxatw-text-[#0B2E62]">
          Knowledge Repository
        </h5>
        <Searchbar
          value={value}
          onChange={handleChange}
          className="!naxatw-w-full"
        />
      </div>
      <HeaderSwicthTab
        headerOptions={
          knowledgeLibrarytabOptions.map((record, idx) => ({
            ...record,
            label: `${record?.label} (${data ? prefixWithZero(data[idx]) || 0 : 0})`,
          })) || []
        }
        activeTab={activeTab}
        onChange={(path: string) => setActiveTab(path)}
      />
    </FlexColumn>
  );
};

export default hasErrorBoundary(HeaderSection);
