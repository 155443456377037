import Icon from '@Components/common/Icon';
import { ReactNode } from 'react';

type Props = {
  title?: string;
  subTitle?: string;
  iconName?: string;
  children: ReactNode;
  containerClassName?: string;
  headerClassName?: string;
};

export default function SegmentCard({
  title,
  subTitle,
  iconName,
  children,
  containerClassName,
  headerClassName,
}: Props) {
  return (
    <article
      className={`naxatw-flex naxatw-flex-col naxatw-gap-4 ${containerClassName}`}
    >
      {title || iconName ? (
        <div
          className={`naxatw-flex naxatw-items-center naxatw-gap-1 naxatw-text-primary-700 ${headerClassName}`}
        >
          {iconName && (
            <Icon name={iconName} className="naxatw-text-[1.5625rem]" />
          )}
          <div className="naxatw-flex naxatw-flex-wrap naxatw-items-center naxatw-gap-x-3">
            {title && (
              <span className="naxatw-body-subtitle-lg naxatw-font-bold">
                {title}
              </span>
            )}
            {subTitle && (
              <span className="naxatw-body-caption naxatw-flex naxatw-font-bold naxatw-text-matt-200">
                {subTitle}
              </span>
            )}
          </div>
        </div>
      ) : null}
      <div className="segment-body">{children}</div>
    </article>
  );
}
