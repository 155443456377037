interface IIconProps extends React.HTMLAttributes<HTMLElement> {
  name: string;
  className?: string;
  onClick?: () => void;
  type?: string;
}

export default function Icon({
  name,
  className,
  onClick,
  type = 'material-symbols-outlined',
  ...restProps
}: IIconProps): JSX.Element {
  return (
    <span
      className="naxatw-flex naxatw-items-center naxatw-justify-center"
      role="button"
      tabIndex={0}
      onKeyUp={() => {}}
      onClick={onClick}
      {...restProps}
    >
      <i
        className={`${type} naxatw-text-icon-sm lg:naxatw-text-icon-md ${className}`}
      >
        {name}
      </i>
    </span>
  );
}
