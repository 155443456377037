/* eslint-disable camelcase */
import { Link } from 'react-router-dom';

import Skeleton from '@Components/RadixComponents/Skeleton';
import useOverallCountData from '@Hooks/getOverallCountData';

interface CardProps {
  img: string;
  heading: string;
  link: string;
  provinceId: number;
}

const Cards = ({ img, heading, link, provinceId }: CardProps) => {
  const { data: provinceData, isLoading: provinceDataIsLoading } =
    useOverallCountData({ province: provinceId });

  return (
    <>
      {provinceDataIsLoading ? (
        <Skeleton className="naxatw-h-[22rem] naxatw-p-8 max-xl:naxatw-h-[20rem]" />
      ) : (
        <div className="naxatw-w-full naxatw-cursor-pointer naxatw-rounded-md naxatw-bg-white naxatw-p-8 naxatw-shadow-sm naxatw-transition-shadow hover:naxatw-shadow-dark">
          <Link to={link}>
            <div className="naxatw-flex naxatw-h-4/6 naxatw-justify-center naxatw-p-4">
              <img
                src={img}
                alt=""
                className="naxatw-aspect-video naxatw-w-11/12 naxatw-object-contain"
              />
            </div>
            <div className="naxatw-flex-col">
              <h5 className="naxatw-text-center naxatw-text-[1.5rem] naxatw-font-medium naxatw-text-blue-900">
                {heading}
              </h5>
              <p className="naxatw-mt-4 naxatw-text-center naxatw-text-sm naxatw-font-normal naxatw-leading-5 naxatw-text-matt-200">
                {provinceData?.program_count || 0} programmes with{' '}
                {provinceData?.project_count || 0} projects across{' '}
                {provinceData?.sector_count || 0} sectors collaborating with{' '}
                {provinceData?.first_tier_partner_count || 0} first-tier
                partners
                <br />
                {/* Allocated budget: £{' '}
                {convertToCurrencySystem(provinceData?.allocated_budget) || 0} */}
              </p>
            </div>
          </Link>
        </div>
      )}
    </>
  );
};

export default Cards;
