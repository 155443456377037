import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { ColumnSort } from '@tanstack/react-table';
import useRouteParamQuery from '@Hooks/useRouteParamQuery';
import useSortingConfig from '@Hooks/useSortingConfig';
import { useTypedSelector } from '@Store/hooks';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import NestedDataTable from '@Components/ExploreByProgramme/NestedDataTable';
import ToolTip from '@Components/RadixComponents/ToolTip';
import {
  mapSortbyParamValue,
  reverseSortbyParamValue,
} from '@Constants/programmeDetails';
import { getProgrammeDetailsByProvince } from '@Services/provincialProfile';

const columns = [
  {
    header: 'PROGRAMME',
    accessorKey: 'programs',
    className: 'naxatw-min-w-[300px]',
  },
  {
    header: 'PROJECT',
    accessorKey: 'project_name',
    className: 'naxatw-min-w-[300px]',
  },
  {
    header: 'FIRST TIER PARTNER',
    accessorKey: 'first_tier_partner',
    className: 'naxatw-min-w-[250px]',
  },
  {
    header: 'SECTOR',
    accessorKey: 'sector',
    className: 'naxatw-min-w-[250px]',
  },
  {
    header: 'BUDGET ALLOCATED (£)',
    accessorKey: 'budget',
  },
  {
    header: '',
    accessorKey: 'icon',
    size: 35,
    cell: ({ parentRowData, childRowData }: Record<string, any>) => {
      return (
        <Link
          to={`/explore-by/programmes/${parentRowData?.program_id}/?componentId=${childRowData?.project_id}`}
        >
          <ToolTip
            name="open_in_new"
            className="naxatw-rounded-full naxatw-p-1 naxatw-text-xl naxatw-text-primary-600 naxatw-duration-300 hover:naxatw-bg-primary-200"
            message="Component Details"
            preventDefault={false}
          />
        </Link>
      );
    },
  },
];

const ProgrammeDetailsTable = () => {
  const { provinceId } = useParams();
  const getQueryParams = useRouteParamQuery();
  const municipalityCode = getQueryParams.get('municipality');
  const filterBy = useTypedSelector(state => state.provincialProfile.filterBy);
  const [sorting, setSorting] = useState<ColumnSort>({ id: '', desc: false });

  const { sortBy, sortOrderKey, sortDir } = useSortingConfig(
    sorting?.id,
    sorting.desc,
  );

  const { data, isLoading } = useQuery({
    queryKey: [
      'programme-details-table-data',
      provinceId,
      municipalityCode,
      filterBy,
      sortBy,
      sortDir,
    ],
    queryFn: async () =>
      getProgrammeDetailsByProvince({
        province: provinceId,
        municipality: municipalityCode,
        status: filterBy,
        [sortOrderKey]: sortDir,
        sort_by: sortBy,
      }),
    select: res => res.data,
  });

  return (
    <div className="scrollbar naxatw-overflow-auto">
      <NestedDataTable
        columns={columns}
        data={data}
        mainDataKey="programs"
        nestingDataKey="data"
        isLoading={isLoading}
        sortingState={{ ...sorting, id: reverseSortbyParamValue(sorting.id) }}
        onSorting={opt =>
          setSorting({ id: mapSortbyParamValue(opt?.id), desc: opt.desc })
        }
      />
    </div>
  );
};

export default hasErrorBoundary(ProgrammeDetailsTable);
