import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTypedSelector } from '@Store/hooks';
import ChartRenderer from '@Components/common/Charts/ChartRenderer';
import { viewByOptionsSectorsCard } from '@Constants/mainDashboard';
import { getPartnersByProgrammeProject } from '@Services/provincialProfile';
import { setPartnersSelectedTab } from '@Store/actions/provincialProfile';

export default function Partners() {
  const { provinceId } = useParams();
  const downloadComponentRef = useRef<any>(null);
  const partner = useTypedSelector(
    state => state.provincialProfile.chartSwitchTab.partner,
  );
  const filterBy = useTypedSelector(state => state.provincialProfile.filterBy);

  const chartData = {
    id: `top-partners-by-budget-allocation-province-${provinceId}-${partner}`,
    name: 'top-partners-by-project-programme',
    queryFn: getPartnersByProgrammeProject,
    params: {
      province: provinceId,
      by: partner === 'Programme' ? 'program' : partner.toLocaleLowerCase(),
      project_status: partner,
      status: filterBy,
    },
    title: `Top Partners by ${partner}`,
    hasDownloadBtn: true,
    chartType: 'verticalBar',
    hasHeader: true,
    fill: ['#B7E7E4'],
    xLabel: `${partner}`,
    yLabel: 'Partners',
    height: '22rem',
    currencyConvert: true,
    switchTabData: {
      switchTabOptions: viewByOptionsSectorsCard,
      dispatchAction: setPartnersSelectedTab,
      activeLabel: partner,
    },
    hoverTitle: `${partner} Count`,
  };

  return (
    <div ref={downloadComponentRef} className="naxatw-min-h-[22rem]">
      <ChartRenderer data={chartData} />
    </div>
  );
}
