/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Button } from '@Components/RadixComponents/Button';
import useDebouncedInput from '@Hooks/useDebouncedInput';
import { useParams } from 'react-router-dom';
import { setAdminSearchState } from '@Store/actions/common';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '@Store/hooks';
import ToolTip from '@Components/RadixComponents/ToolTip';
import ReportFilterButton from '@Components/AdminPanel/Report/ReportFilterButton/ReportFilterButton';
import Searchbar from '../SearchBar';
import hasErrorBoundary from '../hasErrorBoundary';
import Icon from '../Icon';

interface AdminHeaderProps {
  title?: string;
  children?: React.ReactNode;
  onAddNewClick?: () => void;
  hasBulkUpDownload?: boolean;
  hasBulkUpload?: boolean;
  registerButtonText?: string;
  needAddNewButton?: boolean;
  downloadAction?: () => void;
  hasFilterBtn?: boolean;
  needSearchBar?: boolean;
  leadingDownloadAction?: () => void;
  leadingDownloadLable?: string;
}

const AdminHeader = ({
  title,
  children,
  onAddNewClick,
  hasBulkUpDownload = false,
  hasBulkUpload = false,
  registerButtonText = 'Register',
  needAddNewButton = true,
  downloadAction,
  hasFilterBtn = false,
  needSearchBar = true,
  leadingDownloadAction,
  leadingDownloadLable,
}: AdminHeaderProps) => {
  const dispatch = useDispatch();
  const { '*': section } = useParams<'*'>();
  const search = section || '';
  const serchTerm = useTypedSelector(
    /* @ts-ignore */
    state => state.common.adminSearchState?.[section],
  );

  const [value, handleChange] = useDebouncedInput({
    ms: 400,
    init: serchTerm,
    onChange: debouncedEvent => {
      dispatch(
        setAdminSearchState({
          key: search,
          value: debouncedEvent.target.value,
        }),
      );
    },
  });

  return (
    <div className="naxatw-flex naxatw-w-full naxatw-items-center naxatw-justify-between naxatw-gap-4 naxatw-px-4">
      <span className="naxatw-text-base naxatw-font-medium naxatw-tracking-[-0.5px] naxatw-text-[#0B2E62] lg:naxatw-text-2xl">
        {title}
      </span>
      <div
        className={`naxatw-flex naxatw-flex-1 naxatw-items-center naxatw-justify-end naxatw-gap-4 sm:naxatw-flex-row sm:naxatw-items-center ${needAddNewButton ? 'naxatw-flex-wrap' : ''}`}
      >
        {leadingDownloadAction && leadingDownloadLable && (
          <button
            type="button"
            className="naxatw-flex naxatw-items-center naxatw-gap-1 naxatw-rounded-lg naxatw-border naxatw-bg-white naxatw-p-2"
            onClick={leadingDownloadAction}
          >
            <Icon name="download" />
            <span className="naxatw-text-sm naxatw-font-semibold">
              {leadingDownloadLable}
            </span>
          </button>
        )}
        {needSearchBar && (
          <div className="naxatw-flex naxatw-w-fit naxatw-justify-end">
            <Searchbar
              placeholder="Search"
              value={value}
              onChange={handleChange}
              className="naxatw-table-body naxatw-w-full md:naxatw-max-w-[17.813rem]"
            />
          </div>
        )}
        <div className="naxatw-flex naxatw-items-center naxatw-justify-end naxatw-gap-2">
          {hasBulkUpload && (
            <ToolTip
              name="upload"
              message="Bulk Upload"
              className="naxatw-text-icon-md naxatw-rounded naxatw-border naxatw-border-grey-600 naxatw-bg-white naxatw-px-[6px] naxatw-py-[0.375rem] naxatw-text-matt-100 naxatw-duration-200 hover:naxatw-bg-[#E2E7F3]"
              iconClick={() => {}}
            />
          )}
          {hasBulkUpDownload && (
            <ToolTip
              name="download"
              message="Bulk Download"
              className="naxatw-text-icon-md naxatw-rounded naxatw-border  naxatw-border-grey-600 naxatw-bg-white naxatw-px-[6px] naxatw-py-[0.375rem] naxatw-text-matt-100 naxatw-duration-200 hover:naxatw-bg-[#E2E7F3]"
              iconClick={downloadAction}
            />
          )}
          {hasFilterBtn ? <ReportFilterButton /> : null}
          {needAddNewButton && (
            <div>
              <Button onClick={onAddNewClick}>
                <div className="naxatw-flex naxatw-items-center naxatw-justify-center naxatw-gap-2">
                  <span className="material-symbols-outlined naxatw-text-[1.5rem]">
                    add
                  </span>

                  <p className="naxatw-whitespace-nowrap naxatw-text-[0.875rem] naxatw-font-[600]">
                    {registerButtonText}
                  </p>
                </div>
              </Button>
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

export default hasErrorBoundary(AdminHeader);
