/* eslint-disable no-unused-vars */
import { Button } from '@Components/RadixComponents/Button';
import ToolTip from '@Components/RadixComponents/ToolTip';
import DropDown from '@Components/common/DropDown';
import MultipleDatePicker from '@Components/common/FormUI/MultipleDatePicker/MultipleDatePicker';
import Icon from '@Components/common/Icon';
import { FlexRow } from '@Components/common/Layouts';
import Searchbar from '@Components/common/SearchBar';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { IDropDownData } from '@Constants/interface';
import { getRolesList, getUserList } from '@Services/activityLogs';
import {
  clearFilterState,
  setEndDate,
  setRole,
  setStartDate,
  setUser,
} from '@Store/actions/activityLogs';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';

const baseApi = process.env.API_URL_V1;

const ActivityLogsHeader = ({
  activityCount,
  searchLogs,
  setSearchLogs,
}: {
  activityCount: number;
  searchLogs: string;
  setSearchLogs: any;
}) => {
  const dispatch = useTypedDispatch();
  const { startDate, endDate, role, user } = useTypedSelector(
    state => state.acitivityLogsSlice.filterState,
  );
  const { data: rolesList } = useQuery({
    queryKey: ['roles'],
    queryFn: () => getRolesList(),
    select: res => {
      const rolesData = res.data;
      const modifiedRolesData = rolesData.map((roleX: any) => ({
        id: roleX.role,
        label: roleX.role__name,
        name: roleX.role__name,
      }));
      return modifiedRolesData;
    },
  });
  const { data: usersList } = useQuery({
    queryKey: ['users'],
    queryFn: () => getUserList(),
    select: res => {
      const usersData = res.data;
      const modifiedRolesData = usersData.map((userX: any) => ({
        id: userX.user,
        label: userX.user__username,
        name: userX.user__username,
      }));
      return modifiedRolesData;
    },
  });

  function createDownloadParams(obj: Record<string, any>): string {
    return Object.keys(obj)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
      .join('&');
  }

  function handleDownloadLogs() {
    const aTag = document.createElement('a');
    aTag.href = `${baseApi}/activity-log/?${createDownloadParams({
      role,
      user,
      is_export: true,
      start_date: startDate,
      end_date: endDate,
    })}`;
    aTag.click();
    aTag.remove();
  }
  return (
    <div className="activity-logs-header naxatw-flex naxatw-flex-wrap naxatw-items-center naxatw-justify-end naxatw-gap-4 naxatw-py-2 lg:naxatw-justify-between">
      <div className="title naxatw-flex naxatw-flex-1 naxatw-items-center naxatw-gap-2">
        <p className="naxatw-text-nowrap naxatw-text-xl naxatw-font-bold naxatw-tracking-[-0.01125rem] naxatw-text-matt-100">
          Activity Logs
        </p>
        <div className="naxatw-flex naxatw-h-6 naxatw-min-w-6 naxatw-items-center naxatw-justify-center naxatw-rounded-[6.25rem] naxatw-bg-secondary-500 naxatw-px-1 naxatw-text-xs naxatw-font-medium naxatw-leading-3 naxatw-text-white">
          <span className="">{activityCount || 0}</span>
        </div>
      </div>
      <FlexRow className="scrollbar naxatw-items-center naxatw-gap-4 naxatw-overflow-x-auto md:naxatw-min-w-[25rem] md:naxatw-justify-center lg:naxatw-w-3/5">
        <DropDown
          options={(rolesList as IDropDownData[]) || []}
          value={role}
          onChange={(roleValue: any) => {
            dispatch(setRole(roleValue));
          }}
          placeholder="Role"
          className="naxatw-w-1/5 naxatw-min-w-[9rem]"
        />
        <DropDown
          options={(usersList as IDropDownData[]) || []}
          value={user}
          onChange={userValue => dispatch(setUser(userValue))}
          placeholder="User"
          className="naxatw-w-1/5 naxatw-min-w-[9rem]"
        />
        <div className="naxatw-w-fit naxatw-min-w-[9rem]">
          <MultipleDatePicker
            startDate={startDate}
            setStartDate={(date: any) => dispatch(setStartDate(date))}
            enableButton
            endDate={endDate}
            setEndDate={(date: any) => dispatch(setEndDate(date))}
            placeHolder={
              startDate && endDate
                ? `${format(new Date(startDate), 'MMMM dd, yyyy')} to ${format(new Date(endDate), 'MMMM dd, yyyy')}`
                : 'Date'
            }
            // needCustomPlaceholderDate
            clearDateRange={() => {
              dispatch(setStartDate(undefined));
              dispatch(setEndDate(undefined));
            }}
          />
        </div>
        <ToolTip
          name="restart_alt"
          message="Reset Filters"
          className="naxatw-text-primary-600"
          iconClick={() => {
            dispatch(clearFilterState());
          }}
        />
      </FlexRow>
      <div className="searchbar-container">
        {/* <Searchbar
          placeholder="Search Activity Logs"
          value={searchLogs}
          onChange={setSearchLogs}
          className="naxatw-table-body naxatw-max-w-[17.813rem]"
        /> */}
        <Button
          variant="secondary"
          className="itms-center naxatw-flex naxatw-min-w-[9rem] naxatw-gap-2"
          onClick={() => handleDownloadLogs()}
        >
          <Icon name="download" className="naxatw-text-[1.5rem]" />
          Download Logs
        </Button>
      </div>
    </div>
  );
};

export default hasErrorBoundary(ActivityLogsHeader);
