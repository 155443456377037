import { useSpring, animated } from '@react-spring/web';
import { useParams, useSearchParams } from 'react-router-dom';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { useQuery } from '@tanstack/react-query';
import useScrollPosition from '@Hooks/useScrollPosition';
import Skeleton from '@Components/RadixComponents/Skeleton';
import React from 'react';
import { generateReportListStakeholder } from '@Services/report';

import ProgrammeDetailsHeader from './Header';
import StatusBar from './StatusBar';
import ComponentsTable from './ComponentsTable';
import CampaignGoals from './CampaignGoals';
import SectorsComponent from './Sectors';
import MapSection from './MapSection';
import Metrices from './Metrices';
import SegmentCard from './Cards/Segment';
import WorkingAreas from './WorkingAreas';
import Markers from './Markers';

const ProgrammeDetails = () => {
  const scrollPosition = useScrollPosition();
  const [searchParams] = useSearchParams();

  const springs = useSpring({
    from: { y: 100 },
    to: { y: 0 },
  });
  const { programmeId } = useParams();
  const componentId = searchParams.get('componentId');
  function transformStakeholders(data: any[]) {
    const result: any[] = [];

    data?.forEach(stakeholder => {
      stakeholder.stakeholders?.forEach((levelObj: any) => {
        Object?.keys(levelObj)?.forEach(level => {
          const content = levelObj[level]?.map((categoryObj: any) => {
            const category = Object?.keys(categoryObj)[0];
            const items = categoryObj[category];
            return { category, items };
          });

          result.push({ level, content });
        });
      });
    });

    return result;
  }

  const { data: stakeholderData, isLoading: stakeholderDataIsLoading } =
    useQuery({
      queryKey: ['stakeolderData', programmeId, componentId],
      queryFn: async () =>
        generateReportListStakeholder(
          componentId ? { project: componentId } : { program: programmeId },
        ),
      select: res => transformStakeholders(res.data),
    });

  return (
    <>
      <animated.div style={{ ...springs }} className="naxatw-px-5">
        <div className="naxatw-mx-auto naxatw-flex naxatw-w-full naxatw-max-w-[90rem] naxatw-flex-col naxatw-gap-5 naxatw-py-4">
          <div
            className={`${scrollPosition > 0 ? 'naxatw-fixed naxatw-top-[6.8vh] naxatw-pt-10' : 'naxatw-relative'} naxatw-z-[45] naxatw-w-full naxatw-max-w-[80rem] naxatw-bg-white naxatw-pb-5 naxatw-pr-12 lg:naxatw-max-w-[90rem] 2xl:naxatw-pr-0`}
          >
            <ProgrammeDetailsHeader />
          </div>
          <div
            className={`${scrollPosition > 0 ? 'naxatw-mt-[7rem]' : 'naxatw-mt-0'} naxatw-flex naxatw-flex-col naxatw-gap-5`}
          >
            <Metrices />
            <div className="naxatw-grid naxatw-grid-cols-1 naxatw-gap-10 lg:naxatw-grid-cols-[1fr_3fr]">
              <FlexColumn className="naxatw-gap-7 lg:naxatw-min-w-[28.1875rem]">
                <SegmentCard title="Timeline" iconName="timeline">
                  <StatusBar />
                </SegmentCard>
                <WorkingAreas
                  projectId={componentId!!}
                  programmeId={programmeId!!}
                />
              </FlexColumn>
              <FlexColumn className="naxatw-gap-8">
                <CampaignGoals />
                <SectorsComponent />
                <Markers />
              </FlexColumn>
            </div>

            {!componentId && <ComponentsTable />}
            <SegmentCard title="Stakeholders" iconName="groups">
              {stakeholderDataIsLoading ? (
                <FlexColumn className="naxatw-gap-2">
                  <Skeleton className="naxatw-h-[3rem] naxatw-w-full naxatw-rounded-lg" />
                  <Skeleton className="naxatw-h-[3rem] naxatw-w-full naxatw-rounded-lg" />
                  <Skeleton className="naxatw-h-[3rem] naxatw-w-full naxatw-rounded-lg" />
                  <Skeleton className="naxatw-h-[3rem] naxatw-w-full naxatw-rounded-lg" />
                </FlexColumn>
              ) : (
                <FlexColumn className="naxatw-gap-3">
                  <FlexColumn className="naxatw-gap-3">
                    {stakeholderData?.map((stakeholder: any) => (
                      <FlexColumn
                        className="naxatw-gap-2"
                        key={stakeholder?.level}
                      >
                        <p className="naxatw-text-[0.625rem] naxatw-font-medium naxatw-uppercase naxatw-tracking-[0.03125rem] naxatw-text-primary-700">
                          {stakeholder?.level}
                        </p>
                        <FlexColumn className="naxatw-w-full naxatw-gap-1 naxatw-rounded-lg naxatw-border naxatw-bg-white naxatw-p-3 naxatw-shadow-sm">
                          {stakeholder?.content?.map(
                            (level: any, index: number) => {
                              const isLastIndex =
                                index === stakeholder.content.length - 1;
                              return (
                                <React.Fragment key={level?.category}>
                                  <FlexColumn className="naxatw-gap-1">
                                    <p className="naxatw-text-[0.75rem] naxatw-font-bold naxatw-tracking-[-0.015rem] naxatw-text-matt-200">
                                      {level?.category}
                                    </p>
                                    <FlexColumn className="naxatw-gap-[0.62rem]">
                                      {level?.items?.map(
                                        (subStakeholders: any) => {
                                          return (
                                            <FlexRow
                                              className="naxatw-gap-[0.62rem]"
                                              key={subStakeholders.province}
                                            >
                                              {subStakeholders?.province && (
                                                <>
                                                  <p className="naxatw-text-[0.75rem] naxatw-font-semibold naxatw-text-primary-700">
                                                    {subStakeholders?.province}
                                                  </p>
                                                  <div className="naxatw-h-[1rem] naxatw-w-[1px] naxatw-bg-secondary-300" />
                                                </>
                                              )}
                                              {subStakeholders?.names?.map(
                                                (
                                                  items: any,
                                                  itemsIndex: number,
                                                ) => {
                                                  const isLastSubIndex =
                                                    itemsIndex ===
                                                    subStakeholders.names
                                                      .length -
                                                      1;
                                                  return (
                                                    <FlexRow
                                                      className="naxatw-gap-[0.62rem]"
                                                      key={items}
                                                    >
                                                      <p className="naxatw-text-[0.75rem] naxatw-font-medium naxatw-text-matt-200">
                                                        {items}
                                                      </p>
                                                      <div
                                                        className={` ${isLastSubIndex ? 'naxatw-hidden' : 'naxatw-block'} naxatw-h-[1rem] naxatw-w-[1px] naxatw-bg-secondary-300 `}
                                                      />
                                                    </FlexRow>
                                                  );
                                                },
                                              )}
                                            </FlexRow>
                                          );
                                        },
                                      )}
                                    </FlexColumn>
                                  </FlexColumn>
                                  <div
                                    className={` ${isLastIndex ? 'naxatw-hidden' : 'naxatw-block'} naxatw-h-[1px] naxatw-w-full naxatw-bg-neutral-200`}
                                  />
                                </React.Fragment>
                              );
                            },
                          )}
                        </FlexColumn>
                      </FlexColumn>
                    ))}
                  </FlexColumn>
                </FlexColumn>
              )}
            </SegmentCard>

            <div className="naxatw-h-[35rem] naxatw-overflow-hidden naxatw-rounded-xl naxatw-border naxatw-border-gray-200 naxatw-bg-white naxatw-shadow-sm">
              <MapSection lat={28.3949} lon={84.124} zoom={6} />
            </div>
            {/* <KeyStakeHolders /> */}
          </div>
          {/* <AssociatedDocuments /> */}
        </div>
      </animated.div>
    </>
  );
};

export default ProgrammeDetails;
