import { getProvincialCountData } from '@Services/provincialProfile';
import { useQuery } from '@tanstack/react-query';

const useOverallCountData = (params?: {
  province?: number | string;
  municipality?: number | string;
  status?: string;
}) => {
  return useQuery({
    queryKey: ['province-cards-data-mapping', params],
    queryFn: async () => getProvincialCountData(params || {}),
    select: res => res.data,
  });
};
export default useOverallCountData;
