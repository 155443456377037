// import { useRef } from 'react';
// import { useQuery } from '@tanstack/react-query';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import { useSpring, animated } from '@react-spring/web';
// import Slider, { LazyLoadTypes } from 'react-slick';
import {
  getTopFiveSectorsByComponent,
  getTopFiveSectorsByProgramme,
  // getProgrammesByBudgetAllocated,
  getTopPartnerByBudget,
  getTopComponentProgram,
  getGovernmentStakeHoldersDataPro,
  // getChartDescription,
  getBudgetExpenditureTypeChart,
} from '@Services/mainDashboard';
import { useTypedSelector } from '@Store/hooks';
import {
  setSectorsSelectedTab,
  setGovernmentStakeHoldersSelectedTab,
  setBudgetExpenditureSelectedTab,
} from '@Store/actions/mainDashboardSwitchtab';
import {
  viewByOptionsSectorsCard,
  governmentStakeHoldersCardHeader,
  budgetExpenditureChartHeader,
} from '@Constants/mainDashboard';
// import ChartButton from './ChartButton';
// import Chart from './Chart';
import ChartContainer from './ChartContainer';

// interface ISettingsProps {
//   slidesToShow: number;
//   slidesToScroll: number;
//   lazyLoad: LazyLoadTypes | undefined;
//   speed: number;
//   infinite: boolean;
//   autoplay?: boolean;
//   cssEase: string;
//   autoplaySpeed: number;
//   afterChange?: any;
//   rtl?: boolean;
// }

const ChartSection = () => {
  // const sliderRef = useRef<Slider>(null);

  const selectedTabOfCharts = useTypedSelector(
    state => state.setMainDashboardChartTabs.selectedTab,
  );

  const programmeFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.program,
  );

  const projectFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.component,
  );

  const firstTierPartnerParams = useTypedSelector(
    state => state.mainDashboard.filterParams.firstTierPartner,
  );

  const sectorGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.sector,
  );

  const markerGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.markerGroup,
  );

  const subMarkersParams = useTypedSelector(
    state => state.mainDashboard.filterParams.subMarkers,
  );

  const startDate = useTypedSelector(
    state => state.mainDashboard.filterParams.startDate,
  );
  const endDate = useTypedSelector(
    state => state.mainDashboard.filterParams.endDate,
  );

  const statusFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.status,
  );

  // const sliderSettings: ISettingsProps = {
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   lazyLoad: 'ondemand',
  //   infinite: true,
  //   speed: 500,
  //   autoplaySpeed: 2000,
  //   cssEase: 'linear',
  // };
  const filterCount = useTypedSelector(
    state => state?.mainDashboard?.filterCount,
  );

  // const { data: chartDescriptionData } = useQuery({
  //   queryKey: [
  //     'chart-description',
  //     programmeFilterParams,
  //     projectFilterParams,
  //     firstTierPartnerParams,
  //     sectorGroupParams,
  //     markerGroupParams,
  //     subMarkersParams,
  //     startDate,
  //     endDate,
  //     statusFilterParams,
  //   ],
  //   queryFn: async () =>
  //     getChartDescription({
  //       program: programmeFilterParams?.join(','),
  //       project: projectFilterParams?.join(','),
  //       first_tier_partner: firstTierPartnerParams?.join(','),
  //       sector: sectorGroupParams?.join(','),
  //       marker: markerGroupParams?.join(','),
  //       marker_category: subMarkersParams?.join(','),
  //       start_date: startDate,
  //       end_date: endDate,
  //       status: statusFilterParams,
  //     }),
  //   select: (res: Record<string, any>) => res.data,
  // });

  const mainDashboardCharts = [
    {
      xLabelClassname: '!naxatw-absolute !naxatw-bottom-[5%]',
      id: 'programme-vs-projects',
      title: `Top 5 Programmes by Budget allocated`,
      subTitle:
        'Showcasing the Most Active Sectors Based on Programme/Project Counts',
      chartType: 'donut',
      queryFn: getTopComponentProgram,
      xLabel: 'Programmes',
      descriptionKey: 'top_project_program',
      params: {
        program: programmeFilterParams?.join(','),
        project: projectFilterParams?.join(','),
        first_tier_partner: firstTierPartnerParams?.join(','),
        sector: sectorGroupParams?.join(','),
        marker: markerGroupParams?.join(','),
        marker_category: subMarkersParams?.join(','),
        start_date: startDate,
        end_date: endDate,
        status: statusFilterParams,
      },
    },
    {
      id:
        selectedTabOfCharts.sectors === 'Project'
          ? 'top-five-sector-trend-component'
          : 'top-five-sector-trend-programme',
      querykey: selectedTabOfCharts.sectors,
      title: `Top ${filterCount ? '' : '5'} Sectors by Number of ${selectedTabOfCharts.sectors === 'Project' ? 'Projects' : 'Programmes'}`,
      subTitle: 'Showcasing the Most Active Sectors Based on Programme/Project',
      chartType: 'treeMap',
      queryFn:
        selectedTabOfCharts.sectors === 'Project'
          ? getTopFiveSectorsByComponent
          : getTopFiveSectorsByProgramme,
      params: {
        program: programmeFilterParams?.join(','),
        project: projectFilterParams?.join(','),
        first_tier_partner: firstTierPartnerParams?.join(','),
        sector: sectorGroupParams?.join(','),
        marker: markerGroupParams?.join(','),
        marker_category: subMarkersParams?.join(','),
        start_date: startDate,
        end_date: endDate,
        status: statusFilterParams,
      },
      switchTabData: {
        switchTabOptions: viewByOptionsSectorsCard,
        dispatchAction: setSectorsSelectedTab,
        activeLabel: selectedTabOfCharts.sectors,
      },
      descriptionKey:
        selectedTabOfCharts.sectors === 'Project'
          ? 'top_sectors_project'
          : 'top_sectors_program',
      xLabelClassname: '!naxatw-absolute !naxatw-bottom-[10%]',
    },
    {
      id:
        selectedTabOfCharts.govermentStakeHolders === 'Project'
          ? 'government-stakeholders-project'
          : 'government-stakeholders-programme',
      title: `Number of Government Stakeholders by ${selectedTabOfCharts.govermentStakeHolders === 'Project' ? 'Project' : 'Programme'}`,
      subTitle:
        'Showcasing the Count of Government Stakeholders Involved in Various Programmes and Projects',
      queryFn: getGovernmentStakeHoldersDataPro,
      chartType: 'pieChart',
      params: {
        by: `${selectedTabOfCharts.govermentStakeHolders === 'Project' ? 'project' : 'program'}`,
        program: programmeFilterParams?.join(','),
        project: projectFilterParams?.join(','),
        first_tier_partner: firstTierPartnerParams?.join(','),
        sector: sectorGroupParams?.join(','),
        marker: markerGroupParams?.join(','),
        marker_category: subMarkersParams?.join(','),
        start_date: startDate,
        end_date: endDate,
        status: statusFilterParams,
      },
      switchTabData: {
        switchTabOptions: governmentStakeHoldersCardHeader,
        dispatchAction: setGovernmentStakeHoldersSelectedTab,
        activeLabel: selectedTabOfCharts.govermentStakeHolders,
      },
      chartId: 'government-stakeholders',
      descriptionKey:
        selectedTabOfCharts.govermentStakeHolders === 'Project'
          ? 'project_stakeholder_piechart'
          : 'program_stakeholder_piechart',
      hasDetails: true,
    },
    // {
    //   id: 'top-programmes-by-budget-allocation',
    //   title: `Top ${filterCount ? '' : 5} Programmes by Budget Allocated`,
    //   subTitle:
    //     ' Showcasing Programmes/Projects with the Highest Allocated Budgets',
    //   chartType: 'bar',
    //   queryFn: getProgrammesByBudgetAllocated,
    //   params: {
    //     program: programmeFilterParams?.join(','),
    //     project: projectFilterParams?.join(','),
    //     first_tier_partner: firstTierPartnerParams?.join(','),
    //     sector: sectorGroupParams?.join(','),
    //     marker: markerGroupParams?.join(','),
    //     marker_category: subMarkersParams?.join(','),
    //     start_date: startDate,
    //     end_date: endDate,
    //     status: statusFilterParams,
    //   },
    //   yLabel: 'Budget',
    //   xLabel: 'Programmes',
    //   descriptionKey: 'top_program_budget',
    //   xLabelClassname: '!naxatw-absolute !naxatw-bottom-[8%]',
    //   barSize: '45',
    //   needLegend: false,
    // },
    {
      id: 'top-partners-by-budget-allocation',
      name: 'top-partners-by-budget-allocation',
      title: `Top ${filterCount ? '' : '5'} Implementing Partners by Budget Allocated`,
      subTitle: 'Highlighting the Partners with the Largest Budget Allocations',
      chartType: 'verticalBar',
      queryFn: getTopPartnerByBudget,
      yLabel: 'Partners',
      xLabel: 'Budget',
      descriptionKey: 'top_partner_budget',
      params: {
        program: programmeFilterParams?.join(','),
        project: projectFilterParams?.join(','),
        first_tier_partner: firstTierPartnerParams?.join(','),
        sector: sectorGroupParams?.join(','),
        marker: markerGroupParams?.join(','),
        marker_category: subMarkersParams?.join(','),
        start_date: startDate,
        end_date: endDate,
        status: statusFilterParams,
      },
      xLabelClassname: '!naxatw-absolute !naxatw-bottom-[8%]',
    },
    {
      id:
        selectedTabOfCharts.budgetExpenditureType === 'Expenditure Type'
          ? 'overview-expenditure-type'
          : 'overview-budget-type',
      title: `Overview of  ${selectedTabOfCharts.budgetExpenditureType === 'Expenditure Type' ? 'Expenditure' : 'Budget'} Types`,
      queryFn: getBudgetExpenditureTypeChart,
      chartType: 'pieChart',
      params: {
        by: `${selectedTabOfCharts.budgetExpenditureType === 'Expenditure Type' ? 'expenditure_type' : 'budget_type'}`,
        program: programmeFilterParams?.join(','),
        project: projectFilterParams?.join(','),
        first_tier_partner: firstTierPartnerParams?.join(','),
        sector: sectorGroupParams?.join(','),
        marker: markerGroupParams?.join(','),
        marker_category: subMarkersParams?.join(','),
        start_date: startDate,
        end_date: endDate,
        status: statusFilterParams,
      },
      switchTabData: {
        switchTabOptions: budgetExpenditureChartHeader,
        dispatchAction: setBudgetExpenditureSelectedTab,
        activeLabel: selectedTabOfCharts.budgetExpenditureType,
      },
      descriptionKey:
        selectedTabOfCharts.budgetExpenditureType === 'Expenditure Type'
          ? 'total_expenditure'
          : 'total_budget',
      chartId: 'budget-expenditure-type',
      showPound: true,
    },
  ];

  // const springs = useSpring({
  //   from: { y: 100 },
  //   to: { y: 0 },
  // });

  // console.log(chartDescriptionData, 'add');

  // const sliderBtnStyle =
  //   'naxatw-absolute naxatw-top-2/4 naxatw-z-40 naxatw-col-span-1 -naxatw-translate-y-2/4 naxatw-duration-300 hover:naxatw-bg-grey-100 md:naxatw-relative md:naxatw-translate-y-0 md:naxatw-top-0';
  return (
    // <animated.div
    //   style={{ ...springs }}
    //   className="chart-section naxatw-relative naxatw-mx-auto naxatw-h-full naxatw-grid-cols-12 naxatw-gap-3 md:naxatw-grid"
    // >
    //   <ChartButton
    //     icon="west"
    //     className={`${sliderBtnStyle} naxatw-left-0`}
    //     handleClick={() => sliderRef?.current?.slickPrev()}
    //   />

    //   <div className="slider-container naxatw-relative naxatw-z-10 naxatw-col-span-10 naxatw-h-full naxatw-w-full">
    //     <Slider {...sliderSettings} ref={sliderRef} className="naxatw-h-full">
    //       {mainDashboardCharts?.map((chart: Record<string, any>) => {
    //         console.log(chart, 'data', chartDescriptionData);

    //         return (
    //           <Chart
    //             chart={chart}
    //             key={chart.id}
    //             chartDescriptionData={chartDescriptionData}
    //           />
    //         );
    //       })}
    //     </Slider>
    //   </div>

    //   <ChartButton
    //     icon="east"
    //     className={`${sliderBtnStyle} naxatw-right-0 naxatw-justify-self-end`}
    //     handleClick={() => sliderRef?.current?.slickNext()}
    //   />
    // </animated.div>
    <ChartContainer chartList={mainDashboardCharts} />
  );
};

export default ChartSection;
