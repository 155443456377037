import { useParams, useSearchParams } from 'react-router-dom';
import { getSectorWiseCountData } from '@Services/provincialProfile';
import { useQuery } from '@tanstack/react-query';
import Skeleton from '@Components/RadixComponents/Skeleton';
import SegmentCard from '../Cards/Segment';

const SectorsComponent = () => {
  const [searchParams] = useSearchParams();
  const componentId = searchParams.get('componentId');
  const { programmeId } = useParams();
  const { data, isLoading } = useQuery({
    queryKey: ['sectors-data-by-component', programmeId, componentId],
    queryFn: async () =>
      getSectorWiseCountData({
        program: programmeId,
        project: componentId,
      }),
    select: res => res.data,
  });

  if (!isLoading && (!data || data?.length === 0)) return null;

  return (
    <SegmentCard title="Sectors" iconName="category">
      <div className="naxatw-flex naxatw-w-full naxatw-flex-col naxatw-gap-5 naxatw-overflow-hidden">
        {isLoading ? (
          <Skeleton className="naxatw-h-[10rem] naxatw-w-full" />
        ) : (
          <div className="naxatw-flex naxatw-flex-wrap naxatw-gap-2 naxatw-gap-y-5">
            {data?.map((sectorData: Record<string, any>) => (
              <span
                className="naxatw-body-sm naxatw-rounded-3xl naxatw-border naxatw-border-[#E3E4E3] naxatw-px-3 naxatw-py-2"
                key={sectorData?.sector_id}
              >
                {sectorData?.sector_name}
              </span>
            ))}
          </div>
        )}
      </div>
    </SegmentCard>
  );
};

export default SectorsComponent;
