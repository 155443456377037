import { v4 as uuid } from 'uuid';
import { useParams, useSearchParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import { getCampaignGoals } from '@Services/provincialProfile';
import Skeleton from '@Components/RadixComponents/Skeleton';
import DefaultCard from '../Cards/Default';
import SegmentCard from '../Cards/Segment';

const CampaignGoals = () => {
  const [searchParams] = useSearchParams();
  const { programmeId } = useParams();
  const projectId = searchParams.get('componentId');

  const { data, isLoading } = useQuery({
    queryKey: ['campaignGoals', projectId],
    queryFn: async () =>
      projectId
        ? getCampaignGoals({ project_id: projectId })
        : getCampaignGoals({ program_id: programmeId }),

    select: res => res.data,
  });

  if ((!isLoading && data?.length === 0) || !data) return null;

  return (
    <SegmentCard title="Campaign Goals" iconName="extension">
      <div className="naxatw-flex naxatw-flex-col naxatw-items-start naxatw-gap-5">
        {isLoading ? (
          <div className="naxatw-flex naxatw-gap-2">
            <Skeleton className="naxatw-h-4 naxatw-w-[4rem]" />
            <Skeleton className="naxatw-h-4 naxatw-w-[4rem]" />
            <Skeleton className="naxatw-h-4 naxatw-w-[4rem]" />
          </div>
        ) : (
          <div className="naxatw-flex naxatw-w-full  naxatw-flex-wrap naxatw-items-center naxatw-gap-3">
            {data?.map((goal: Record<string, any>) => {
              return (
                <>
                  <DefaultCard
                    className="naxatw-w-fit naxatw-min-w-[12rem] naxatw-rounded-lg naxatw-border naxatw-p-5 naxatw-shadow-sm"
                    key={uuid()}
                  >
                    <div className="naxatw-flex naxatw-flex-col naxatw-gap-[0.625rem]">
                      <h5 className="naxatw-text-primary-700">
                        CG {goal?.campaign_goals__id}
                      </h5>
                      <p className="naxatw-body-sm naxatw-whitespace-nowrap naxatw-text-matt-200 ">
                        {goal?.campaign_goals__name?.split(':')[1]}
                      </p>
                    </div>
                  </DefaultCard>
                  {/* <p
                    className="naxatw-text-ellipsis naxatw-text-[0.875rem] naxatw-font-medium naxatw-leading-normal naxatw-text-[#484848]"
                    key={uuid()}
                  >
                    {goal.campaign_goals__name}
                  </p>
                  {!(index === campaignGoals.length - 1) && (
                    <div className="naxatw-h-4 naxatw-w-[2px] naxatw-bg-[#484848]" />
                  )} */}
                </>
              );
            })}
          </div>
        )}
      </div>
    </SegmentCard>
  );
};

export default CampaignGoals;
