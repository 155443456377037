import capitalizeFirstLetter from '@Utils/capitalizeFirstLetter';
import PopupAccordion from '@Components/MainDashboard/MapSection/MapPopup/Accordion';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { getPopupData } from '@Services/mainDashboard';
import { useQuery } from '@tanstack/react-query';
import { cn } from '@Utils/index';
import { Accordion } from '@radix-ui/react-accordion';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { Button } from '@Components/RadixComponents/Button';
import { useNavigate } from 'react-router-dom';

export default function MapPopup({
  hoveredMunicipality,
  selectedKey = 'municipality',
  queryParams,
  className,
  visible,
}: {
  hoveredMunicipality: Record<string, any> | null;
  selectedKey?: string;
  queryParams?: Record<string, any>;
  className?: string;
  visible?: boolean;
}) {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const { data: popUpData, isFetching: isPopupLoading } = useQuery({
    queryKey: ['bek-popup-data', hoveredMunicipality?.id, selectedKey],
    queryFn: async () =>
      getPopupData({
        by: selectedKey,
        [selectedKey]: hoveredMunicipality?.id,
        ...queryParams,
      }),
    enabled: !!hoveredMunicipality?.id,
    select: res => res.data,
  });

  if (!hoveredMunicipality?.id) {
    return <></>;
  }

  return (
    <div
      className={cn(
        className,
        `actions ${
          visible ? 'enter-active' : 'exit-active'
        } scrollbar naxatw-absolute naxatw-right-4 naxatw-top-[4.25rem] naxatw-z-[11] naxatw-max-h-[calc(100%-0.6rem)] naxatw-max-w-[75%] naxatw-gap-4 naxatw-overflow-y-auto naxatw-rounded-[4px]  naxatw-border-[1px] naxatw-border-[#EAECF0] naxatw-bg-white naxatw-p-3 naxatw-text-[#475467] sm:naxatw-w-[25rem] sm:naxatw-max-w-full md:naxatw-top-[2%]`,
      )}
    >
      {isPopupLoading ? (
        <div className=" naxatw-mt-2 naxatw-flex naxatw-flex-col naxatw-gap-2">
          <Skeleton className=" naxatw-h-6 naxatw-w-full" />
          <hr />
          <Skeleton className=" naxatw-h-10 naxatw-w-full" />
          {/* <Skeleton className=" naxatw-h-5 naxatw-w-full" /> */}
          {/* <Skeleton className=" naxatw-h-5 naxatw-w-full" />
          <Skeleton className=" naxatw-h-5 naxatw-w-full" /> */}
        </div>
      ) : (
        <FlexColumn className="naxatw-flex naxatw-flex-col naxatw-gap-1">
          {popUpData?.length === 0 ? (
            <p>No data found</p>
          ) : (
            <FlexColumn className="naxatw-border-b">
              <FlexRow className="naxatw-flex naxatw-w-full naxatw-items-center naxatw-gap-2">
                <div className="naxatw-h-[0.625rem] naxatw-w-[0.625rem] naxatw-rounded-full naxatw-bg-[#5DB8B2]" />
                <p className="naxatw-text-[0.875rem] naxatw-font-bold naxatw-text-[#5DB8B2]">
                  {capitalizeFirstLetter(popUpData?.[0].name || '')}
                </p>
              </FlexRow>
              <p className="naxatw-text-xs naxatw-font-medium naxatw-tracking-[0.0375rem] naxatw-text-[#475467]">
                {popUpData[0].summary?.program || 0} Programmes with{' '}
                {popUpData[0].summary?.project || 0} Projects across{' '}
                {popUpData[0].summary?.sector || 0} Sectors
                {/* ,Allocated budget: £
                {convertToCurrencySystem(popUpData[0].summary?.budget || 0)} */}
              </p>
            </FlexColumn>
          )}
          <div className="scrollbar naxatw-h-fit naxatw-max-h-[35vh] naxatw-overflow-y-scroll naxatw-px-2 naxatw-py-2">
            {popUpData[0]?.programs?.length > 0 && (
              <>
                <Accordion type="single" collapsible>
                  {popUpData[0].programs?.map((program: any, index: number) => (
                    <PopupAccordion
                      key={program?.program_id}
                      title={program?.program_name}
                      data={program?.data}
                      listNumber={index + 1}
                    />
                  ))}
                </Accordion>
              </>
            )}
          </div>
          {popUpData[0]?.programs?.length > 0 && (
            <div className="naxatw-flex naxatw-justify-center naxatw-py-2">
              <Button
                onClick={() =>
                  navigate(
                    `/explore-by/geography/${hoveredMunicipality?.province_id}?municipality=${hoveredMunicipality?.id}`,
                  )
                }
                variant="secondary"
                color="primary"
                className="naxatw-text-sm"
              >
                View More Detail
              </Button>
            </div>
          )}
        </FlexColumn>
      )}
    </div>
  );
}
