/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable camelcase */
import { Button } from '@Components/RadixComponents/Button';
import dvsLogo from '@Assets/images/dvs-logo-new.svg';
import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useQuery } from '@tanstack/react-query';
import { convertToCurrencySystem } from '@Utils/index';
import html2pdf from 'html2pdf.js';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { IProvincialProfilePdfState } from '@Store/slices/adminPdfSlice';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import IconHeader from '@Components/common/PdfDesign/IconHeaders';
import Icon from '@Components/common/Icon';
import {
  getPartnersByProgrammeProject,
  getProvincialPrioritiesGoalsData,
  getProvincialProfileReport,
} from '@Services/provincialProfile';
import { useParams } from 'react-router-dom';
import { getProvinceData } from '@Services/portfolioProfile';
import lumbini from '@Assets/images/lumbiniProvince.png';
import useOverallCountData from '@Hooks/getOverallCountData';
import madhesh from '@Assets/images/madeshProvince.png';
import karnali from '@Assets/images/karnaliProvince.png';
import DataTable from '@Components/common/DataTable';
import { useTypedSelector } from '@Store/hooks';
import ChartRenderer from '@Components/common/Charts/ChartRenderer';
import {
  getTopFiveSectorsByComponent,
  getTopFiveSectorsByProgramme,
} from '@Services/mainDashboard';

type IReportPdfPopUpProps = {
  // display?: boolean;
  handleClose?: () => void;
  compoenentId?: string;
  checkList: IProvincialProfilePdfState;
};

const labels = [
  { id: 'program_count', title: 'Programme ' },
  { id: 'project_count', title: 'Projects' },
  { id: 'sector_count', title: 'Sector' },
  // { id: 'allocated_budget', title: 'Allocated Budget' },
];

function transformStakeholders(data: any[]) {
  const result: any[] = [];

  data?.forEach(stakeholder => {
    stakeholder.stakeholders?.forEach((levelObj: any) => {
      Object?.keys(levelObj)?.forEach(level => {
        const content = levelObj[level]?.map((categoryObj: any) => {
          const category = Object?.keys(categoryObj)[0];
          const items = categoryObj[category];
          return { category, items };
        });

        result.push({ level, content });
      });
    });
  });

  return result;
}

function getProvinceImage(provinceId: string) {
  switch (provinceId) {
    case '5':
      return lumbini;
    case '2':
      return madhesh;
    case '6':
      return karnali;
    default:
      return '';
  }
}

const ProvincailProfilePdfPopUp = ({
  // display = false,
  compoenentId,
  handleClose,
  checkList,
}: IReportPdfPopUpProps) => {
  const { provinceId } = useParams();
  const [customIsLoading, setCustomIsLoading] = useState(false);
  const [stakeholderData, setStakeholderData] = useState<any[]>([]);
  const contentRef = useRef<HTMLDivElement>(null);
  const partner = useTypedSelector(
    state => state.provincialProfile.chartSwitchTab.partner,
  );
  const sector = useTypedSelector(
    state => state.provincialProfile.chartSwitchTab.sector,
  );
  const filterBy = useTypedSelector(state => state.provincialProfile.filterBy);

  const {
    top_partners_by_budget_allocated: partnerBudget,
    sectors,
    programme_details,
    provincial_priorities: provincialPriorities,
    programme_details: programmeDetails,
    working_palikas,
    stakeholders,
  } = checkList;

  const { data: provinceCountData, isLoading: provinceCountDataIsLoading } =
    useOverallCountData({ province: provinceId });

  const { data: reportListData, isLoading: reportDataIsLoading } = useQuery({
    queryKey: ['reportList', compoenentId, filterBy],
    queryFn: async () =>
      getProvincialProfileReport({ province: provinceId, status: filterBy }),
    select: res => res.data[0],
  });

  const {
    data: provincialPrioritiesGoalsData,
    isLoading: provincialPrioritiesGoalsDataIsLoading,
  } = useQuery({
    queryKey: ['provincial-priorities-goals-data', filterBy],
    queryFn: async () =>
      getProvincialPrioritiesGoalsData({
        province: provinceId,
        status: filterBy,
      }),
    select: res => res.data,
  });

  const { data: provinceData, isLoading: provinceDataLoading } = useQuery({
    queryKey: ['provinceData', provinceId],
    queryFn: async () => getProvinceData(provinceId),
    select: res => res.data,
  });

  const handleDownloadPdf = () => {
    const element = contentRef.current;
    const style = document.createElement('style');
    document.head.appendChild(style);
    style.sheet?.insertRule(
      'body > div:last-child img { display: inline-block; }',
    );
    if (element) {
      const opt = {
        margin: 0.25,
        filename: 'my-document.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 1.5 },
        jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
        pagebreak: { mode: ['css', 'avoid-all'], avoid: '.no-page-break' },
      };

      // Create PDF
      html2pdf().from(element).set(opt).save();
    }
  };

  const chartData = {
    id: `top-partners-by-budget-allocation-province-${provinceId}-${partner}`,
    name: 'top-partners-by-project-programme',
    queryFn: getPartnersByProgrammeProject,
    params: {
      province: provinceId,
      by: partner === 'Programme' ? 'program' : partner.toLocaleLowerCase(),
      status: filterBy,
    },
    // title: `Top Partners by ${partner}`,
    hasDownloadBtn: false,
    chartType: 'verticalBar',
    hasHeader: false,
    fill: ['#E2ECF7'],
    xLabel: `${partner}`,
    yLabel: 'Partners',
    height: '18rem',
    currencyConvert: true,
    hoverTitle: `${partner} Count`,
    exportMode: true,
  };

  const sectorsChartData = {
    id: `top-sectors-by-budget-allocation-province-${provinceId}-${sector}`,
    queryFn:
      sector === 'Project'
        ? getTopFiveSectorsByComponent
        : getTopFiveSectorsByProgramme,
    params: { province: provinceId, status: filterBy },
    title: `Top Sectors by ${sector}`,
    hasDownloadBtn: true,
    chartType: 'bar',
    hasHeader: false,
    fill: ['#B7E7E4'],
    xLabel: 'Sectors',
    yLabel: `${sector}`,
    name: 'top-sectors-by-programme-project',
    height: '18rem',
    xLabelClassname: '!naxatw-py-4',
    hoverTitle: `${sector} Count`,
    // yLabelClassNames: 'custom-y-label',
    showYAxisIntOnly: true,
    wrapXAxis: true,
  };

  const hasSubNationalLevel = stakeholderData.some(
    item => item.level === 'Sub-National Level',
  );

  useEffect(() => {
    setCustomIsLoading(true);
    if (reportDataIsLoading) return;
    setStakeholderData(
      reportListData ? transformStakeholders(reportListData.stakeholder) : [],
    );
    setCustomIsLoading(false);
  }, [reportListData, reportDataIsLoading]);

  return (
    <>
      <div className="naxatw-flex naxatw-h-full naxatw-w-full naxatw-items-center naxatw-justify-center naxatw-overflow-hidden">
        <div className="naxatw-relative naxatw-mx-auto naxatw-h-[93vh] naxatw-w-11/12 naxatw-overflow-hidden naxatw-rounded-2xl naxatw-border naxatw-bg-white naxatw-px-5 naxatw-shadow-sm lg:naxatw-w-3/5 xl:naxatw-w-2/5">
          {reportDataIsLoading ||
          customIsLoading ||
          provinceCountDataIsLoading ||
          provincialPrioritiesGoalsDataIsLoading ||
          provinceDataLoading ? (
            <Skeleton className="naxatw-mt-4 naxatw-h-[93vh] naxatw-w-full" />
          ) : (
            <div
              ref={contentRef}
              className="no-scrollbar no-page-break naxatw-flex naxatw-h-full naxatw-w-full naxatw-flex-col naxatw-gap-5 naxatw-overflow-y-auto"
            >
              <FlexColumn className="naxatw-w-full naxatw-gap-6 naxatw-pb-[6rem]">
                <div className="naxatw-py-2">
                  <img src={dvsLogo} alt="" />
                </div>
                <div className="naxatw-grid naxatw-grid-cols-10 naxatw-gap-[0.62rem]">
                  <FlexColumn className="naxatw-col-span-5 naxatw-w-full naxatw-gap-6 ">
                    <p className="naxatw-text-xl naxatw-font-medium naxatw-leading-normal naxatw-text-primary-700">
                      {provinceData?.name}
                    </p>
                    <FlexColumn className="naxatw-max-w-[70%] naxatw-overflow-hidden naxatw-rounded-xl naxatw-border naxatw-shadow-sm">
                      {labels.map((data, index) => (
                        <FlexRow
                          className={`naxatw-items-center naxatw-justify-between ${index % 2 !== 0 ? 'naxatw-bg-gray-100' : ''}  naxatw-px-2 naxatw-py-1`}
                          key={uuidv4()}
                        >
                          <p className="naxatw-min-w-[30%] naxatw-text-[0.75rem] naxatw-font-medium naxatw-text-gray-500">
                            {data.title}
                          </p>
                          <p className="naxatw-text-[0.75rem] naxatw-font-bold naxatw-text-[#08519C]">
                            {data.id === 'allocated_budget'
                              ? `£ ${convertToCurrencySystem(
                                  provinceCountData[data.id],
                                )}`
                              : provinceCountData[data.id] || '-'}
                          </p>
                        </FlexRow>
                      ))}
                    </FlexColumn>
                  </FlexColumn>
                  <div className="naxatw-col-span-5">
                    <img
                      src={getProvinceImage(provinceId || '')}
                      className="naxatw-aspect-square naxatw-max-h-[9rem] naxatw-w-full naxatw-object-contain"
                      alt="Province"
                    />
                  </div>
                </div>
                <FlexColumn className="naxatw-gap-8">
                  {provincialPriorities &&
                  provincialPrioritiesGoalsData.length ? (
                    <FlexColumn className="naxatw-gap-3">
                      <p className="naxatw-text-[0.75rem] naxatw-font-bold naxatw-text-primary-700">
                        Provincial Priorities Alignment with BEK Programme
                      </p>
                      <FlexColumn className="naxatw-gap-2 naxatw-rounded-lg naxatw-border naxatw-bg-white naxatw-py-2 naxatw-shadow-sm">
                        {provincialPrioritiesGoalsData?.map(
                          (goals: any, goalsIndex: number) => {
                            const isGoalsLastIndex =
                              goalsIndex ===
                              provincialPrioritiesGoalsData.length - 1;
                            return (
                              <React.Fragment key={goals.id}>
                                <FlexColumn className="naxatw-gap-2 naxatw-px-3 naxatw-py-2">
                                  <p className="naxatw-text-[0.75rem] naxatw-font-medium naxatw-text-[#2E2E2E]">
                                    {goals.goal}
                                  </p>
                                  <FlexRow className="naxatw-gap-2">
                                    {goals?.program_abbreviation?.map(
                                      (program: any, index: number) => {
                                        const isLastIndex =
                                          index ===
                                          goals.program_abbreviation.length - 1;
                                        return (
                                          <React.Fragment key={program}>
                                            <p className="naxatw-text-[0.75rem] naxatw-font-bold naxatw-tracking-[-0.015rem] naxatw-text-matt-200">
                                              {program}
                                            </p>
                                            {!isLastIndex && (
                                              <div className="naxatw-h-[1rem] naxatw-w-[1px] naxatw-bg-[#667085]" />
                                            )}
                                          </React.Fragment>
                                        );
                                      },
                                    )}
                                  </FlexRow>
                                </FlexColumn>
                                {!isGoalsLastIndex && (
                                  <div className="naxatw-h-[1px] naxatw-w-full naxatw-bg-neutral-200" />
                                )}
                              </React.Fragment>
                            );
                          },
                        )}
                      </FlexColumn>
                    </FlexColumn>
                  ) : (
                    <></>
                  )}

                  <FlexColumn className="naxatw-gap-8">
                    {programme_details && (
                      <FlexColumn className="naxatw-gap-3">
                        <IconHeader name="article" title="Programme Details" />

                        <div className="table-content ">
                          {reportListData?.program_details?.map(
                            (tableData: any, index: any) => {
                              return (
                                <FlexColumn
                                  className="naxatw-gap-3 naxatw-py-3"
                                  key={tableData.program_id}
                                >
                                  <p className="naxatw-text-xs naxatw-font-medium naxatw-leading-5 naxatw-text-[#0B2E62]">
                                    {index + 1}. {tableData.programs}
                                  </p>
                                  <DataTable
                                    queryKey="programme details"
                                    queryFn={() => {}}
                                    columns={[
                                      {
                                        header: 'PROJECT',
                                        accessorKey: 'project',
                                        // eslint-disable-next-line react/no-unstable-nested-components
                                        cell: row => {
                                          return (
                                            <p className="naxatw-text-xs naxatw-font-bold">
                                              {index + 1}.
                                              {Number(row?.row?.id) + 1}{' '}
                                              {row?.row?.original.project}
                                            </p>
                                          );
                                        },
                                      },
                                      {
                                        header: 'FIRST TIER PARTNER',
                                        accessorKey: 'first_tier_partner',
                                        // eslint-disable-next-line react/no-unstable-nested-components
                                        cell: row => {
                                          return (
                                            <p className="naxatw-text-xs naxatw-font-semibold">
                                              {
                                                row?.row?.original
                                                  .first_tier_partner
                                              }
                                            </p>
                                          );
                                        },
                                      },
                                    ]}
                                    className="!naxatw-border-[0]"
                                    demoData={tableData.data}
                                    isPaginated={false}
                                    searchInput=""
                                    tableStyle={{ tableLayout: 'fixed' }}
                                    needSorting={false}
                                    cellClassName="!naxatw-items-end"
                                    exportMode
                                  />
                                </FlexColumn>
                              );
                            },
                          )}
                        </div>
                      </FlexColumn>
                    )}
                    {/* -----------------sectors------------------------- */}
                    {sectors && (
                      <FlexColumn className="naxatw-w-full naxatw-gap-3">
                        <IconHeader
                          name="category"
                          title={`Top Sectors By ${sector}`}
                        />
                        <ChartRenderer data={sectorsChartData} />
                      </FlexColumn>
                    )}

                    {/* ----------------top-tier-partners---------------- */}
                    {partnerBudget && (
                      <FlexColumn className="naxatw-gap-3">
                        <IconHeader
                          name="handshake"
                          title={`Top Partners by ${partner === 'Programme' ? 'program' : partner.toLocaleLowerCase()} `}
                        />
                        <ChartRenderer data={chartData} />
                      </FlexColumn>
                    )}
                    {/* ---------------working palikas ------------------ */}
                    {working_palikas && (
                      <FlexColumn className="naxatw-gap-2">
                        <IconHeader
                          name="place"
                          title={`Working Areas (${reportListData?.districts?.length})`}
                        />

                        <FlexColumn className="naxatw-gap-6 naxatw-rounded-lg naxatw-border naxatw-px-3 naxatw-py-2 naxatw-shadow-sm">
                          {reportListData?.districts?.map(
                            (district: Record<string, any>) => (
                              <FlexRow className="naxatw-items-center naxatw-gap-5">
                                <p className="naxatw-min-w-[7rem] naxatw-text-xs naxatw-font-bold naxatw-tracking-[-0.015rem] naxatw-text-matt-100">
                                  {district.district_name}
                                </p>
                                <div className="naxatw-w-[1px] naxatw-self-stretch naxatw-bg-secondary-200" />
                                <FlexRow className="naxatw-flex-wrap naxatw-gap-2">
                                  {district.municipalities?.map(
                                    (municipality: any) => {
                                      if (!municipality.municipality_name)
                                        return null;
                                      return (
                                        <div
                                          className="naxatw-rounded-3xl naxatw-border naxatw-border-matt-100 naxatw-bg-secondary-100 naxatw-px-2 naxatw-py-1"
                                          key={uuidv4()}
                                        >
                                          <p className="naxatw-text-[0.75rem] naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-200">
                                            {municipality?.municipality_name}
                                          </p>
                                        </div>
                                      );
                                    },
                                  )}
                                </FlexRow>
                              </FlexRow>
                            ),
                          )}
                        </FlexColumn>
                      </FlexColumn>
                    )}
                    {stakeholders && hasSubNationalLevel && (
                      <FlexColumn className="naxatw-gap-3">
                        <IconHeader name="groups" title="Stakeholders" />
                        <FlexColumn className="naxatw-gap-3">
                          {stakeholderData?.map((stakeholder: any) => {
                            if (stakeholder.level === 'National Level')
                              return null;
                            return (
                              <FlexColumn className="naxatw-gap-2">
                                <p className="naxatw-text-[0.625rem] naxatw-font-medium naxatw-uppercase naxatw-tracking-[0.03125rem] naxatw-text-primary-700">
                                  {stakeholder?.level}
                                </p>
                                <FlexColumn className="naxatw-w-full naxatw-gap-1 naxatw-rounded-lg naxatw-border naxatw-bg-white naxatw-p-3 naxatw-shadow-sm">
                                  {stakeholder?.content.map(
                                    (level: any, index: number) => {
                                      const isLastIndex =
                                        index ===
                                        stakeholder.content.length - 1;
                                      return (
                                        <>
                                          <FlexColumn className="naxatw-gap-1">
                                            <p className="naxatw-text-[0.75rem] naxatw-font-bold naxatw-tracking-[-0.015rem] naxatw-text-matt-200">
                                              {level.category}
                                            </p>
                                            <FlexRow className="naxatw-gap-[0.62rem]">
                                              {level?.items?.map(
                                                (
                                                  subStakeholders: any,
                                                  subIndex: number,
                                                ) => {
                                                  const isLastSubIndex =
                                                    subIndex ===
                                                    level.items.length - 1;
                                                  return (
                                                    <FlexRow className="naxatw-gap-[0.62rem]">
                                                      <p className="naxatw-text-[0.75rem] naxatw-font-medium naxatw-text-matt-200">
                                                        {subStakeholders}
                                                      </p>
                                                      <div
                                                        className={` ${isLastSubIndex ? 'naxatw-hidden' : 'naxatw-block'} naxatw-h-[1rem] naxatw-w-[1px] naxatw-bg-secondary-300 `}
                                                      />
                                                    </FlexRow>
                                                  );
                                                },
                                              )}
                                            </FlexRow>
                                          </FlexColumn>
                                          <div
                                            className={` ${isLastIndex ? 'naxatw-hidden' : 'naxatw-block'} naxatw-h-[1px] naxatw-w-full naxatw-bg-neutral-200`}
                                          />
                                        </>
                                      );
                                    },
                                  )}
                                </FlexColumn>
                              </FlexColumn>
                            );
                          })}
                        </FlexColumn>
                      </FlexColumn>
                    )}
                  </FlexColumn>
                </FlexColumn>
              </FlexColumn>
            </div>
          )}
          <div className="naxatw-absolute naxatw-bottom-0 naxatw-left-0 naxatw-z-50 naxatw-flex naxatw-h-[5rem] naxatw-w-full naxatw-items-center naxatw-justify-center naxatw-gap-4 naxatw-bg-white naxatw-shadow-[0px_2px_20px_4px_rgba(0,0,0,0.12)]">
            <Button
              size="normal"
              type="button"
              variant="secondary"
              className="naxatw-w-fit naxatw-px-4"
              onClick={handleDownloadPdf}
            >
              <Icon
                name="download"
                className="naxatw-flex naxatw-items-center naxatw-text-[1.5rem]"
              />
              <p>Download Report</p>
            </Button>
            <Button
              variant="link"
              className="naxatw-text-[0.875rem] naxatw-font-semibold naxatw-text-secondary-500"
              onClick={handleClose}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProvincailProfilePdfPopUp;
