import { useState } from 'react';
import ToolTip from '@Components/RadixComponents/ToolTip';
import FullScreen from '@Components/common/FullScreen';

interface ChartCardHeaderProps {
  title: string;
  needFullScreen?: boolean;
  iconClick?: any;
  headerContent?: React.ReactNode;
  content?: React.ReactNode;
  subHeaderContent?: React.ReactNode;
  exportName?: string;
}

const ChartCardHeader = ({
  title,
  needFullScreen = true,
  iconClick,
  content,
  headerContent,
  subHeaderContent,
  exportName,
}: ChartCardHeaderProps) => {
  const [fullScreen, setFullScreen] = useState(false);

  return (
    <div className="header-container naxatw-flex naxatw-items-center naxatw-justify-between naxatw-gap-4 naxatw-px-[1.5rem] naxatw-py-[1.25rem]">
      <p className="naxatw-text-sm naxatw-font-bold naxatw-text-matt-100 md:naxatw-text-[1.125rem]">
        {title}
      </p>
      {subHeaderContent}
      <div className="actions naxatw-flex naxatw-h-fit naxatw-items-center naxatw-gap-4">
        <ToolTip
          name="open_in_full"
          className={`!naxatw-text-xl naxatw-text-primary-300 ${
            needFullScreen ? 'naxatw-block' : 'naxatw-hidden'
          }`}
          message="Full Screen"
          iconClick={() => setFullScreen(true)}
        />
        <ToolTip
          name="download"
          className="naxatw-text-primary-300"
          message="Download"
          iconClick={iconClick}
        />
      </div>
      <FullScreen
        isShow={fullScreen}
        onToggle={() => setFullScreen(false)}
        headerContent={headerContent}
        downloadName={exportName}
        isDownloadable
      >
        {content}
      </FullScreen>
    </div>
  );
};

export default ChartCardHeader;
