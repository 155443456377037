import { ComponentPropsWithoutRef } from 'react';

type Props = ComponentPropsWithoutRef<'div'>;
export default function DefaultCard({
  className,
  children,
  ...restProps
}: Props) {
  return (
    <article
      className={`naxatw-rounded-lg naxatw-border naxatw-border-[#E3E4E3] naxatw-p-5  ${className}`}
      {...restProps}
    >
      {children}
    </article>
  );
}
