/* eslint-disable no-unused-vars */
import { useRef, useState } from 'react';
import CaptureComponent from '@Components/common/Charts/CaptureComponent';
import { FlexRow } from '@Components/common/Layouts';
import DropDown from '@Components/common/DropDown';
import { getFiscalYearOptions } from '@Constants/FormConstants/goalsForm';
import ChartCardHeader from './Header';
import ProvincialProritiesTable from './Table/ProvincialProritiesTable';

export function getCurrentFiscalYear(d?: Date) {
  const date = d || new Date();
  let curYear = date.getFullYear();
  const curMonth = date.getMonth();
  const curDay = date.getDate();

  if (curMonth === 6 && curDay >= 16) {
    curYear += 1;
  }
  if (curMonth > 6) {
    curYear += 1;
  }
  return `${curYear - 1}/${curYear}`;
}

type ProvincialHeaderContentProps = {
  title: string;
  fiscalYear: string;
  setFiscalYear: (value: string) => void;
};
const ProvincialHeaderContent = ({
  title,
  fiscalYear,
  setFiscalYear,
}: ProvincialHeaderContentProps) => {
  const fYearsOptions = getFiscalYearOptions(5);
  return (
    <FlexRow className="naxatw-w-full naxatw-items-center naxatw-justify-between">
      <div className="header-content naxatw-pt-[0.375rem]">
        <p className="naxatw-subtitle-lg naxatw-text-matt-100">{title}</p>
      </div>
      <DropDown
        className="naxatw-mr-5 naxatw-w-[8rem]"
        placeholder="Fiscal Year"
        options={fYearsOptions || []}
        value={fiscalYear}
        choose="value"
        onChange={fiscalYearValue => setFiscalYear(fiscalYearValue)}
        enableSearchbar={false}
      />
    </FlexRow>
  );
};

const ProvincialPriorities = () => {
  const prioritiesRef = useRef<HTMLDivElement | null>(null);
  const [fiscalYear, setFiscalYear] = useState<string>(
    () => '2023/2024' || getCurrentFiscalYear(),
  );
  const fYearsOptions = getFiscalYearOptions(5);

  return (
    <div
      className="priorities-chart-container naxatw-w-full naxatw-overflow-hidden naxatw-rounded-xl naxatw-border naxatw-border-primary-200 naxatw-bg-white"
      ref={prioritiesRef}
    >
      <div className="naxatw-flex naxatw-flex-col">
        <ChartCardHeader
          title="Provincial Priorities Alignment with new BEK Programmes"
          iconClick={() =>
            CaptureComponent({
              componentRef: prioritiesRef,
              captureName: 'Provincial Priorities',
            })
          }
          subHeaderContent={
            <DropDown
              className="naxatw-mr-5 naxatw-w-[8rem]"
              placeholder="Fiscal Year"
              options={fYearsOptions || []}
              value={fiscalYear}
              choose="value"
              onChange={fiscalYearValue => setFiscalYear(fiscalYearValue)}
              enableSearchbar={false}
            />
          }
          headerContent={
            <ProvincialHeaderContent
              title="Provincial Priorities Alignment with BEK Programme"
              fiscalYear={fiscalYear}
              setFiscalYear={fiscalYearValue => setFiscalYear(fiscalYearValue)}
            />
          }
          content={<ProvincialProritiesTable fiscalYear={fiscalYear} />}
          exportName="Provincial Priorities"
        />
        <div className="naxatw-w-full naxatw-bg-primary-200" />
        <ProvincialProritiesTable fiscalYear={fiscalYear} />
      </div>
    </div>
  );
};

export default ProvincialPriorities;
