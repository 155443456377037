import AdminHeader from '@Components/common/AdminHeader';
import DataTable from '@Components/common/DataTable';
import { FlexRow } from '@Components/common/Layouts';
import ToolTip from '@Components/RadixComponents/ToolTip';
import { deleteVideoTutorial, getVideoTutorial } from '@Services/tutorials';
// import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import Portal from '@Components/common/Layouts/Portal';
import DeleteConfirmationOverlay from '@Components/common/PopupOverlays/DeleteConfirmationOverlay';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import TutorialsHeaderSwitchTab from '../TutorialsHeader';
import VideosRegistrationForm from './VideosRegistrationForm';

const VideosTutorial = () => {
  const [showRegistrationForm, setShowRegistrationForm] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [idToDelete, setIdToDelete] = useState<string>('');
  const [idToEdit, setIdToEdit] = useState<string>('');
  const queryClient = useQueryClient();

  const videosDataColumns = [
    {
      header: 'ID',
      accessorKey: 'id',
    },
    {
      header: 'TITLE',
      accessorKey: 'title',
    },
    {
      header: 'DESCRIPTION',
      accessorKey: 'description',
    },
    {
      header: '',
      accessorKey: 'icon',
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ row }: any) => {
        // eslint-disable-next-line no-console
        const cellId = row?.original?.id;
        return (
          <FlexRow className="naxatw-relative naxatw-mr-6 naxatw-mt-2 naxatw-cursor-pointer naxatw-justify-end naxatw-gap-4">
            <ToolTip
              name="edit"
              message="Edit"
              className="naxatw-text-icon-md naxatw-text-matt-100 naxatw-duration-200"
              iconClick={() => {
                setShowRegistrationForm(true);
                setIdToEdit(cellId);
              }}
            />
            <ToolTip
              name="delete"
              message="Delete"
              className="naxatw-text-icon-md naxatw-text-matt-100 naxatw-duration-200 hover:naxatw-text-red-500"
              iconClick={() => {
                setOpenDeleteConfirmation(true);
                setIdToDelete(cellId);
              }}
            />
          </FlexRow>
        );
      },
    },
  ];

  const springs = useSpring({
    from: { y: 100 },
    to: { y: 0 },
  });

  const {
    mutate: deleteVideoTutorialData,
    isError,
    isLoading,
    error,
  } = useMutation({
    mutationFn: () => deleteVideoTutorial(idToDelete),
    onSuccess: () => {
      toast.success('Video deleted successfully');
      queryClient.invalidateQueries(['videos-table-data']);
      setOpenDeleteConfirmation(false);
    },
    onError: () => {
      toast.error('Failed to delete video');
    },
  });

  return (
    <>
      <div className="naxatw-space-y-6">
        <AdminHeader
          title="Tutorials"
          onAddNewClick={() => {
            setShowRegistrationForm(true);
            // navigate(
            //   `/data-bank/tutorials/${pathname.split('/')[3]}?registration=true`,
            // );
          }}
          needSearchBar={false}
          hasBulkUpload={false}
          registerButtonText="Add"
        />
        <TutorialsHeaderSwitchTab />
        <animated.div
          style={{ ...springs }}
          className="naxatw-h-[calc(100vh-18rem)] naxatw-w-full naxatw-overflow-hidden lg:naxatw-h-[calc(100vh-15.6rem)]"
        >
          <DataTable
            columns={videosDataColumns}
            queryFn={getVideoTutorial}
            queryKey="videos-table-data"
            searchInput=""
          />
        </animated.div>
      </div>
      {showRegistrationForm && (
        <Portal>
          <VideosRegistrationForm
            onClose={() => {
              setShowRegistrationForm(false);
              setIdToEdit('');
            }}
            id={idToEdit}
          />
        </Portal>
      )}
      {openDeleteConfirmation && (
        <Portal>
          <DeleteConfirmationOverlay
            onClose={() => setOpenDeleteConfirmation(false)}
            onDelete={() => deleteVideoTutorialData()}
            isError={isError}
            isLoading={isLoading}
            error={error}
          />
        </Portal>
      )}
    </>
  );
};

export default VideosTutorial;
