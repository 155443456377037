import NoChartDataComponent from '@Components/common/Charts/NoChartDataComponent';
import Skeleton from '@Components/RadixComponents/Skeleton';
import ToolTip from '@Components/RadixComponents/ToolTip';
import { cn } from '@Utils/index';
import isEmpty from '@Utils/isEmpty';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { ColumnSort } from '@tanstack/react-table';
import Icon from '@Components/common/Icon';

interface IColumnItem {
  header: string;
  accessorKey: string;
  className?: string;
  // eslint-disable-next-line no-unused-vars
  cell?: (rowData: Record<string, any>) => ReactNode;
}

interface INestedDataTable {
  className?: string;
  tableStyle?: Record<string, any>;
  data: Record<string, any>;
  columns: IColumnItem[];
  mainDataKey: string;
  nestingDataKey: string;
  isLoading: boolean;
  needSorting?: boolean;
  sortingState?: ColumnSort;
  // eslint-disable-next-line no-unused-vars
  onSorting?: (sorting: ColumnSort) => void;
}

let clickCount = 0;

function getSortIconClassName(predicate: boolean) {
  return `naxatw-text-secondary-500 active-col ${predicate ? '!naxatw-hidden' : 'naxatw-visible'}`;
}

const NestedDataTable = ({
  className,
  tableStyle,
  columns,
  data,
  mainDataKey,
  nestingDataKey = 'data',
  isLoading,
  needSorting = true,
  sortingState,
  onSorting,
}: INestedDataTable) => {
  const { id: sortId, desc } = sortingState || {};

  // change sorting icon direction
  function handleSortingChange(column: IColumnItem) {
    if (!onSorting) return;

    if (sortId !== column.accessorKey) {
      clickCount = 0;
    }

    // reset sorting
    if (clickCount === 2) {
      clickCount = 0;
      onSorting({ id: '', desc: false });
      return;
    }

    if (sortId === column?.accessorKey) {
      onSorting({ id: sortId, desc: !desc });
    } else {
      onSorting({ id: column.accessorKey, desc: false });
    }
    clickCount += 1;
  }

  if (isLoading)
    return (
      <div className="naxatw-h-full naxatw-w-full naxatw-px-2 naxatw-py-2">
        <table className="naxatw-w-full">
          <tbody>
            {[...new Array(10)].map((_, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <tr key={i} style={{ background: 'none' }}>
                {[...new Array(5)].map((__, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <td key={index}>
                    <Skeleton className="naxatw-h-16 naxatw-rounded-none" />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );

  if (isEmpty(data))
    return (
      <NoChartDataComponent className="naxatw-flex naxatw-min-h-[60vh] naxatw-items-center naxatw-justify-center naxatw-overflow-hidden max-lg:naxatw-w-screen max-md:naxatw-min-h-[30vh]" />
    );

  return (
    <table className={`naxatw-w-full ${className}`} style={tableStyle}>
      <thead>
        <tr className="naxatw-h-10 naxatw-border-b naxatw-text-left">
          {columns?.map((column: IColumnItem) => {
            const showExpendMoreIcon =
              sortId === column?.accessorKey
                ? getSortIconClassName(!desc!!)
                : null;

            const showExpendLessIcon =
              sortId === column?.accessorKey
                ? getSortIconClassName(desc!!)
                : null;

            return (
              <th
                key={column.accessorKey}
                className={cn(
                  'naxatw-whitespace-nowrap naxatw-px-4',
                  column.className,
                )}
                onClick={() => handleSortingChange(column)}
              >
                <div
                  className={`naxatw-flex naxatw-cursor-pointer naxatw-items-center naxatw-justify-start naxatw-gap-1 !naxatw-text-sm hover:naxatw-text-secondary-500 ${sortId === column.accessorKey ? 'naxatw-text-secondary-500' : ''}`}
                >
                  <span className="naxatw-whitespace-nowrap !naxatw-text-sm naxatw-font-medium">
                    {column.header}
                  </span>
                  <span
                    className={`naxatw-flex naxatw-flex-col naxatw-items-center naxatw-justify-center naxatw-gap-1 ${needSorting ? 'naxatw-block' : 'naxatw-hidden'}`}
                  >
                    <Icon
                      name="expand_less"
                      className={`!naxatw-flex !naxatw-h-[6px] !naxatw-items-center !naxatw-justify-start !naxatw-text-base group-hover:naxatw-text-secondary-500 ${
                        showExpendLessIcon
                      } naxatw-text-matt-100`}
                    />
                    <Icon
                      name="expand_more"
                      className={`!naxatw-text-icon-sm !naxatw-flex !naxatw-h-[6px] !naxatw-items-center !naxatw-justify-start !naxatw-text-base group-hover:naxatw-text-secondary-500 ${
                        showExpendMoreIcon
                      } naxatw-text-matt-100`}
                    />
                  </span>
                </div>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {data?.map((tableData: Record<string, any>) => (
          <>
            <tr
              className="naxatw-w-full naxatw-border-b !naxatw-bg-none"
              key={tableData.id}
              style={{ background: 'none' }}
            >
              <td
                className="naxatw-border-r naxatw-px-4 naxatw-py-2 naxatw-text-sm  naxatw-font-medium"
                rowSpan={tableData?.[nestingDataKey]?.length}
              >
                {tableData?.[mainDataKey]}
                {/* this is only for DVS case to add link after the data of main data key */}
                <Link to={`/explore-by/programmes/${tableData?.program_id}/`}>
                  <ToolTip
                    name="open_in_new"
                    message="Programme Details"
                    className="naxatw-ml-1 naxatw-rounded-full naxatw-p-1 naxatw-text-[0.938rem] naxatw-text-primary-600 naxatw-duration-300 hover:naxatw-bg-primary-200"
                    iconClick={() => {
                      window.open(
                        `/explore-by/programmes/${tableData?.program_id}/`,
                        '_blank',
                      );
                    }}
                  />
                </Link>
                {/* end */}
              </td>
              {columns.map(column => {
                if (column.accessorKey === mainDataKey) return null;
                const value =
                  tableData?.[nestingDataKey]?.[0]?.[column.accessorKey];
                if (column?.cell) {
                  return (
                    <td
                      key={`${column.accessorKey}-${tableData.id}`}
                      className="naxatw-px-4 naxatw-py-2 naxatw-text-sm"
                    >
                      {column?.cell?.({
                        parentRowData: tableData,
                        childRowData: tableData?.[nestingDataKey]?.[0],
                      })}
                    </td>
                  );
                }
                return (
                  <td
                    className="naxatw-px-4 naxatw-py-2 naxatw-text-sm"
                    key={`${tableData.id}-${column.accessorKey}`}
                  >
                    {Array.isArray(value) ? (
                      <div className="naxatw-flex naxatw-flex-col naxatw-gap-1">
                        {value?.map(valuex => (
                          <div
                            key={valuex}
                            className="naxatw-flex naxatw-gap-1"
                          >
                            <div>-</div>
                            <div>{valuex}</div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      value || '-'
                    )}
                  </td>
                );
              })}
            </tr>

            {tableData?.[nestingDataKey]
              ?.slice(1)
              ?.map((remainingRows: Record<string, any>) => (
                <tr
                  key={remainingRows.id}
                  className="naxatw-border-b !naxatw-bg-none"
                  style={{ background: 'none' }}
                >
                  {columns?.map(column => {
                    if (column.accessorKey === mainDataKey) return null;
                    const value = remainingRows?.[column.accessorKey];
                    if (column?.cell) {
                      return (
                        <td
                          key={`${column.accessorKey}-${remainingRows.id}`}
                          className="naxatw-px-4 naxatw-py-2 naxatw-text-sm 2xl:naxatw-text-[0.938rem]"
                        >
                          {column?.cell?.({
                            parentRowData: tableData,
                            childRowData: remainingRows,
                          })}
                        </td>
                      );
                    }
                    return (
                      <td
                        className="naxatw-px-4 naxatw-py-2 naxatw-text-sm 2xl:naxatw-text-[0.938rem]"
                        key={`${column.accessorKey}-${remainingRows.id}`}
                      >
                        {Array.isArray(value) ? (
                          <div className="naxatw-flex naxatw-flex-col naxatw-gap-1">
                            {value?.map(valuex => (
                              <div
                                key={valuex}
                                className="naxatw-flex naxatw-gap-1"
                              >
                                <div>-</div>
                                <div>{valuex}</div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          remainingRows?.[column.accessorKey]
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))}
          </>
        ))}
      </tbody>
    </table>
  );
};

export default NestedDataTable;
