/* eslint-disable no-unused-vars */
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Text,
  Legend,
} from 'recharts';
import { convertToCurrencySystem } from '@Utils/index';
import { ChartFills } from '../constants';
import { IChartProps } from '../types';

const xAxisTickFormatter = (value: any) => {
  const limit = 15;
  if (value.length < limit) return value;
  return `${value.slice(0, limit)}...`;
};

const CustomizedLabel = (props: any) => {
  const { x, y, payload } = props;
  return (
    <Text
      x={x}
      y={y + 3}
      style={{
        fontSize: '12px',
        fill: 'rgb(71, 84, 103)',
        fontWeight: 500,
      }}
      color="#475467"
      textAnchor="middle"
      dominantBaseline="hanging"
    >
      {xAxisTickFormatter(payload?.value)}
    </Text>
  );
};
const WrapCustomizedLabel = (props: any) => {
  const { x, y, payload } = props;
  const label = payload?.value;
  const maxLength = 12; // Limit the length of each line
  const words = label.split(' '); // Split the label into words
  const lines: string[] = [];
  let currentLine = '';

  words.forEach((word: string) => {
    if (currentLine.length + word.length <= maxLength) {
      currentLine += `${word} `;
    } else {
      lines.push(currentLine.trim());
      currentLine = `${word} `;
    }
  });
  lines.push(currentLine.trim()); // Push the last line

  return (
    <text x={x} y={y + 10} textAnchor="middle" fontSize="10px" fill="#475467">
      {lines.map((line, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <tspan key={index} x={x} dy={index === 0 ? 0 : 10}>
          {line}
        </tspan>
      ))}
    </text>
  );
};

const CustomTooltip = ({ active, payload, label, hoverTitle }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip naxatw-relative naxatw-z-20 naxatw-rounded-xl naxatw-border-2 naxatw-bg-white naxatw-px-3 naxatw-py-2 naxatw-text-sm">
        <p className="label naxatw-font-bold">{payload[0].payload.fullName}</p>
        {payload?.map((dataPoint: any) => {
          if (dataPoint.dataKey !== 'name')
            return (
              <div
                key={dataPoint.dataKey}
                className="naxatw-flex naxatw-w-fit naxatw-items-center naxatw-justify-between naxatw-gap-5"
              >
                <div className="naxatw-flex naxatw-items-center naxatw-justify-center naxatw-gap-1">
                  <div
                    className="naxatw-h-3 naxatw-w-3 naxatw-rounded-sm"
                    style={{ backgroundColor: `${dataPoint?.fill}` }}
                  />
                  <span>{hoverTitle}</span>
                </div>
                <p className="naxatw-pt-1 naxatw-font-semibold">
                  {convertToCurrencySystem(dataPoint?.value)}
                </p>
              </div>
            );
          return <></>;
        })}
      </div>
    );
  }

  return null;
};

export default function CustomBarChart({
  data,
  fills = ChartFills,
  scrollable = false,
  barSize = 22,
  yAxisLabel = '',
  width = '150%',
  layout = 'horizontal',
  height,
  needLegend = false,
  hoverTitle = 'Budget',
  // height = '24rem',
  showYAxisIntOnly,
  showFullScreen = false,
  wrapXAxis = false,
}: IChartProps) {
  const dataObject = data.length > 0 ? data[0] : {};

  const { name, ...datax } = dataObject;
  const keys = Object.keys(datax);

  const getBottomMargin = () => {
    // Check the length of the labels or any other condition
    const labelLength = Math.max(
      ...data.map((dataValue: any) => dataValue.name.length),
    );

    switch (true) {
      case labelLength > 20:
        return 30;
      case labelLength > 40:
        return 40;
      case labelLength > 60:
        return 50;
      default:
        return 80;
    }

    // return labelLength > 0 ? 50 : 40; // Adjust the threshold based on your label length
  };

  return (
    <div style={{ height: showFullScreen ? '100%' : height || '24rem' }}>
      <ResponsiveContainer
        width={scrollable && width ? width : '100%'}
        height="100%"
      >
        <BarChart
          data={data}
          layout={layout}
          margin={{
            top: 20,
            right: 30,
            left: 0,
            bottom: wrapXAxis ? getBottomMargin() : 5,
          }}
        >
          <CartesianGrid vertical={false} stroke="#EBEBEB" strokeWidth={0.5} />
          <XAxis
            dataKey="name"
            tickLine={false}
            tick={wrapXAxis ? <WrapCustomizedLabel /> : <CustomizedLabel />}
            interval={0}
            style={{
              strokeWidth: '0.5px',
              stroke: '#BDBDBD',
            }}
            // label={<CustomizedLabel />}
          />

          <YAxis
            yAxisId="left"
            style={{
              strokeWidth: '0.5px',
              fontSize: '12px',
              fill: 'rgb(71, 84, 103)',
              fontWeight: 500,
            }}
            stroke="#BDBDBD"
            tickLine={false}
            tickFormatter={(value: any) =>
              String(convertToCurrencySystem(Math.round(value)))
            }
            domain={
              showYAxisIntOnly
                ? [
                    0,
                    Math.ceil(
                      Math.max(
                        ...data.map(
                          (dataItem: Record<string, any>) => dataItem.value,
                        ),
                      ),
                    ),
                  ]
                : undefined
            }
            ticks={
              showYAxisIntOnly
                ? Array.from(
                    {
                      length:
                        Math.ceil(
                          Math.max(
                            ...data.map(
                              (dataItem: Record<string, any>) => dataItem.value,
                            ),
                          ),
                        ) + 1,
                    },
                    (_, i) => i,
                  )
                : undefined
            }
            label={{
              value: yAxisLabel,
              angle: -90,
              position: 'insideLeft',
              style: {
                textAnchor: 'middle',
                fill: 'rgb(71, 84, 103)',
                fontSize: '12px',
                fontWeight: 500,
              },
            }}
          />
          {keys.length > 1 && needLegend && <Legend />}

          <Tooltip
            content={<CustomTooltip hoverTitle={hoverTitle} />}
            cursor={{ fill: '#f2f9ff' }}
          />
          {keys.map((key, i) => {
            if (key === 'fullName') return null;
            return (
              <Bar
                key={key}
                yAxisId="left"
                dataKey={key}
                fill={fills[i]}
                barSize={barSize}
                radius={[4, 4, 0, 0]}
              />
            );
          })}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
