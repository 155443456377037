/* eslint-disable no-nested-ternary */
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useOverallCountData from '@Hooks/getOverallCountData';
import useRouteParamQuery from '@Hooks/useRouteParamQuery';
import useScrollPosition from '@Hooks/useScrollPosition';
import { Button } from '@Components/RadixComponents/Button';
import Skeleton from '@Components/RadixComponents/Skeleton';
import ToolTip from '@Components/RadixComponents/ToolTip';
import SwitchTab from '@Components/common/FormUI/SwitchTab';
import { mappedStatusParams, statusOptions } from '@Constants/filters';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { setFilterBy } from '@Store/actions/provincialProfile';
import { useEffect } from 'react';

interface TopHeaderProps {
  provinceName: string;
  municipalityName?: string;
  isLoading?: boolean;
  onClick?: () => void;
}

let parentPageFilterByValue: string | null = null;

const TopHeader = ({
  provinceName,
  municipalityName,
  isLoading,
  // eslint-disable-next-line no-unused-vars
  onClick,
}: TopHeaderProps) => {
  const { provinceId } = useParams();
  const scrollPosition = useScrollPosition();
  const getQueryParams = useRouteParamQuery();
  const municipalityCode = getQueryParams.get('municipality');
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useTypedDispatch();
  const filterBy = useTypedSelector(state => state.provincialProfile.filterBy);
  const navigatefromPage = location?.state?.from;

  const { data: provinceData, isLoading: provinceDataIsLoading } =
    useOverallCountData(
      municipalityCode
        ? {
            province: provinceId,
            municipality: municipalityCode,
            status: filterBy,
          }
        : { province: provinceId, status: filterBy },
    );

  // preserve parent page filter
  useEffect(() => {
    if (municipalityCode && parentPageFilterByValue === null) {
      parentPageFilterByValue = filterBy;
      dispatch(setFilterBy('Ongoing'));
      return;
    }
    if (!municipalityCode && parentPageFilterByValue !== null) {
      dispatch(setFilterBy(parentPageFilterByValue));
      parentPageFilterByValue = null;
    }
  }, [municipalityCode, dispatch, filterBy]);

  return (
    <>
      {provinceDataIsLoading || isLoading ? (
        <Skeleton className="naxatw-mb-2 naxatw-h-12 naxatw-w-full" />
      ) : (
        <div
          className={`${scrollPosition > 0 ? 'naxatw-fixed naxatw-top-16 naxatw-px-6 naxatw-py-4 xl:naxatw-px-0' : 'naxatw-relative'} naxatw-left-0 naxatw-z-[45] naxatw-w-full naxatw-bg-primary-100 naxatw-pb-5 lg:naxatw-max-w-[90rem] xl:naxatw-left-auto`}
        >
          <div className="naxatw-mx-auto naxatw-max-w-[90rem]">
            <div className="naxatw-flex naxatw-items-center naxatw-justify-between">
              <div className="naxatw-flex naxatw-items-center">
                <div className="naxatw-flex naxatw-items-center naxatw-gap-2">
                  <ToolTip
                    name="arrow_back"
                    className="naxatw-flex naxatw-items-center naxatw-text-xl naxatw-text-[#0B2E62]"
                    iconClick={() =>
                      navigate(
                        navigatefromPage === 'dashboard-map'
                          ? '/dashboard/map'
                          : municipalityCode
                            ? `/explore-by/geography/${provinceId}`
                            : '/explore-by/geography/',
                      )
                    }
                    message="Back"
                  />
                  <h5 className="naxatw-text-lg naxatw-font-medium naxatw-text-[#0B2E62] md:naxatw-text-xl">
                    {provinceName} {municipalityName && `/${municipalityName}`}
                  </h5>
                </div>

                <div className="naxatw-flex naxatw-items-center naxatw-gap-2 naxatw-px-3">
                  <div className="naxatw-h-6 naxatw-w-[0.1rem] naxatw-bg-gray-400" />

                  <p className="naxatw-text-sm naxatw-font-semibold naxatw-leading-normal naxatw-text-[#0B2E62] sm:naxatw-text-base">
                    {provinceData?.program_count || 0} Programme &nbsp;
                    <span className="naxatw-text-sm naxatw-font-medium naxatw-text-matt-200">
                      with &nbsp;
                    </span>
                    {provinceData?.project_count || 0} Projects &nbsp;
                    <span className="naxatw-text-sm naxatw-font-medium naxatw-text-matt-200">
                      across &nbsp;
                    </span>
                    {provinceData?.sector_count || 0} Sectors
                  </p>
                </div>
              </div>
              <div className="naxatw-flex naxatw-items-center naxatw-gap-4">
                <SwitchTab
                  title=""
                  activeLabel="All"
                  activeValue={
                    filterBy === 'Ongoing' ? 'On-Going' : filterBy || 'All'
                  }
                  options={statusOptions}
                  wrapperClassName="!naxatw-cursor-pointer !naxatw-gap-0  !naxatw-items-start"
                  defaultBg={false}
                  defaultBehaviour={false}
                  className="naxatw-bg-white naxatw-text-sm naxatw-leading-5 naxatw-text-[#484848]"
                  titleClassName="naxatw-text-base naxatw-font-medium naxatw-tracking-[0.00625rem] naxatw-text-matt-100"
                  onChange={status =>
                    dispatch(setFilterBy(mappedStatusParams[status]))
                  }
                />
                <Button
                  variant="secondary"
                  color="primary"
                  className="naxatw-text-sm"
                  onClick={onClick}
                >
                  <ToolTip
                    name="download"
                    className="naxatw-flex naxatw-items-center naxatw-text-xl naxatw-text-secondary-500"
                    message="Export"
                  />
                  <span className="naxatw-hidden sm:naxatw-inline">Export</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TopHeader;
