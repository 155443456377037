import { forwardRef, useState } from 'react';
import Icon from '@Components/common/Icon';
import { cn } from '@Utils/index';

export interface IInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const PasswordInput = forwardRef<HTMLInputElement, IInputProps>(
  ({ placeholder, className, ...rest }, ref) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
      <div className="naxatw-body-sm naxatw-input naxatw-relative naxatw-mt-2 naxatw-flex naxatw-items-center naxatw-py-0">
        <input
          ref={ref}
          type={showPassword ? 'text' : 'password'}
          placeholder={placeholder}
          className={`!naxatw-m-0 naxatw-flex-1 naxatw-p-0 naxatw-outline-none ${cn(`!naxatw-m-0 naxatw-flex-1 naxatw-p-0`, className)}`}
          {...rest}
        />
        <button
          type="button"
          className="naxatw-flex"
          onClick={() => setShowPassword(prev => !prev)}
        >
          <Icon
            name={showPassword ? 'visibility' : 'visibility_off'}
            className="naxatw-text-xl"
            tabIndex={-1}
            aria-hidden="true"
          />
        </button>
      </div>
    );
  },
);

PasswordInput.displayName = 'PasswordInput';

export default PasswordInput;
