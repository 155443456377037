/* eslint-disable no-nested-ternary */
import Portal from '@Components/common/Layouts/Portal';
import ReportPdfPopUp from '@Components/common/PdfDesign';
import SelectionBox from '@Components/common/PdfDesign/SelectionBox';
import StatusChips from '@Components/common/StatusChips';
import ProgrammeDetailsPdfPopup from '@Components/ProgrammeDetails/PdfDesign';
import { Button } from '@Components/RadixComponents/Button';
import ToolTip from '@Components/RadixComponents/ToolTip';
import { mappedStatus } from '@Constants/mainDashboard';
import {
  programmeDetailsCheckboxData,
  reportCheckList,
} from '@Constants/pdfGeneration';
import {
  resetCheckBoxProgrammeDetails,
  resetCheckBox,
} from '@Store/actions/adminPdfAction';
import { useTypedSelector } from '@Store/hooks';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface ITopHeaderProps {
  title: string;
  code?: string;
  status?: string;
}

const TopHeader = ({ title, status, code }: ITopHeaderProps) => {
  const [showCheckList, setShowCheckList] = useState(false);
  const [pdfPreviewOpen, setPdfPreviewOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [pdfOpen, setPdfOpen] = useState(false);

  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('componentId');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const programmeDetails = useTypedSelector(
    state => state.adminPdfSlice.programmeDetails,
  );
  const report = useTypedSelector(state => state.adminPdfSlice.report);

  function handlePdfPreviewClose() {
    setPdfOpen(false);
    dispatch(resetCheckBox());
  }

  return (
    <>
      {pdfPreviewOpen && (
        <Portal>
          <ProgrammeDetailsPdfPopup
            handleClose={() => {
              setPdfPreviewOpen(false);
              dispatch(resetCheckBoxProgrammeDetails());
            }}
            checkList={programmeDetails}
          />
        </Portal>
      )}
      {showCheckList && (
        <Portal>
          <SelectionBox
            handleClose={() => setShowCheckList(false)}
            handlePdfPreview={() => setPdfPreviewOpen(true)}
            checkBoxData={programmeDetailsCheckboxData}
            pdfType="programmeDetail"
          />
        </Portal>
      )}

      {open && (
        <Portal>
          <SelectionBox
            handleClose={() => setOpen(false)}
            handlePdfPreview={() => setPdfOpen(true)}
            componentId={projectId || ''}
            checkBoxData={reportCheckList}
          />
        </Portal>
      )}
      {pdfOpen && (
        <Portal>
          <ReportPdfPopUp
            handleClose={() => handlePdfPreviewClose()}
            compoenentId={projectId || ''}
            checkList={report}
          />
        </Portal>
      )}
      <div className="naxatw-flex naxatw-max-w-[90rem] naxatw-justify-between">
        <div className="naxatw-flex naxatw-items-center naxatw-gap-2 naxatw-text-xl naxatw-font-medium naxatw-text-[#0B2E62]">
          <ToolTip
            name="arrow_back"
            message="Back to Programmes"
            className="naxatw-flex naxatw-items-center naxatw-text-xl naxatw-text-[#0B2E62]"
            iconClick={() => navigate('/explore-by/programmes')}
          />
          <p className="naxatw-text-xl naxatw-font-medium naxatw-text-[#0B2E62]">
            {title}
          </p>
          {code && <span>(ID-{code})</span>}
          {status && (
            <StatusChips status={mappedStatus[`${status}`]} label={status} />
          )}
        </div>

        <Button
          variant="secondary"
          onClick={() => {
            if (!projectId) {
              setShowCheckList(true);
              return;
            }
            setOpen(true);
          }}
        >
          <ToolTip
            name="download"
            message="Export Programme"
            className="naxatw-flex naxatw-items-center naxatw-text-xl naxatw-text-secondary-500"
          />
          Export
        </Button>
      </div>
    </>
  );
};

export default TopHeader;
