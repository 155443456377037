/* eslint-disable no-nested-ternary */
import { useQuery } from '@tanstack/react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  getProgrammeDataByProgrammeId,
  getComponentDetailCount,
} from '@Services/provincialProfile';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { useTypedDispatch } from '@Store/hooks';
import { useEffect } from 'react';
import { setStatus } from '@Store/actions/programmeDetails';
import TopHeader from './TopHeader';

const ProgrammeDetailsHeader = () => {
  const { programmeId } = useParams();
  const [searchParams] = useSearchParams();
  const componentId = searchParams.get('componentId');

  const { data: programmeDataList, isLoading: programmeDataIsLoading } =
    useQuery({
      queryKey: ['programmeList', programmeId],
      queryFn: async () =>
        getProgrammeDataByProgrammeId({ program: programmeId }),
      // enabled: !!provinceId || !!searchedText,
      select: res => res.data[0],
      enabled: !!programmeId,
    });

  const { data: componentDataList, isLoading: componentDataIsLoading } =
    useQuery({
      queryKey: ['componentList', programmeId, componentId],
      queryFn: async () =>
        getComponentDetailCount({
          program: programmeId,
          project: componentId,
        }),
      enabled: !!componentId,
      select: res => res.data[0],
    });

  const dispatch = useTypedDispatch();
  const status = componentId
    ? componentDataList?.project_status
    : programmeDataList?.program_status;

  useEffect(() => {
    if (status) {
      dispatch(setStatus(status));
    }

    // reset status if component unmount
    return () => {
      dispatch(setStatus(''));
    };
  }, [status, dispatch]);
  return (
    <>
      {programmeDataIsLoading || (componentId && componentDataIsLoading) ? (
        <Skeleton className="naxatw-h-[4rem] naxatw-w-full" />
      ) : (
        <TopHeader
          title={
            !componentId
              ? programmeDataList?.program_name || "Programme's Name"
              : componentDataList?.project_name || "Projects's Name"
          }
          code={
            !componentId
              ? programmeDataList?.program_code || programmeId
              : componentDataList?.project_code || componentId
          }
          status={status}
        />
      )}
    </>
  );
};

export default ProgrammeDetailsHeader;
