import { IDonutLegendItemProps } from '../types';

export default function DonutLegendItem({
  color,
  name,
  value,
  showPound,
}: IDonutLegendItemProps) {
  return (
    <div className="naxatw-flex naxatw-min-w-[200px] naxatw-items-start naxatw-justify-between naxatw-gap-4 naxatw-text-sm naxatw-text-grey-800">
      <div className="legend-box-name naxatw-flex naxatw-flex-grow naxatw-items-start naxatw-justify-items-start naxatw-gap-2">
        <div
          className="naxatw-my-[2px] naxatw-min-h-[16px] naxatw-min-w-[16px] naxatw-rounded"
          style={{
            backgroundColor: color,
          }}
        />
        <div
          className="name naxatw-button naxatw-line-clamp-1 naxatw-max-w-52 naxatw-text-start naxatw-font-normal"
          title={name}
        >
          {name}
        </div>
      </div>
      <div className="value-percentage naxatw-flex naxatw-min-w-[2rem] naxatw-items-center naxatw-justify-end naxatw-gap-2 naxatw-font-bold">
        <div className="naxatw-button naxatw-min-w-[60px] naxatw-max-w-[60px] naxatw-whitespace-nowrap naxatw-text-start ">
          {showPound && '£'} {value}
        </div>
      </div>
    </div>
  );
}
