/* eslint-disable no-nested-ternary */
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { v4 } from 'uuid';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import Icon from '@Components/common/Icon';
import Skeleton from '@Components/RadixComponents/Skeleton';
import {
  getProvincialPrioritiesGoalsData,
  getProvincialPrioritiesHeaderData,
} from '@Services/provincialProfile';
import NoChartDataComponent from '@Components/common/Charts/NoChartDataComponent';
import isEmpty from '@Utils/isEmpty';

import './index.css';

type Goal = {
  goal: string;
  program_abbreviation: string[];
  program_ids: number[];
  program_names: string[];
};
type GoalHeader = {
  id: number;
  abbreviation: string;
  name: string;
};

type FicalYearPorps = {
  fiscalYear?: string;
};

const ProvincialProritiesTable = ({ fiscalYear }: FicalYearPorps) => {
  const { provinceId } = useParams();
  const {
    data: provincialPrioritiesHeaderData,
    isLoading: provincialPrioritiesHeaderDataIsLoading,
  } = useQuery<any, any, GoalHeader[]>({
    queryKey: ['provincial-priorities-header-data', provinceId, fiscalYear],
    queryFn: async () =>
      getProvincialPrioritiesHeaderData({
        province: provinceId,
        fiscal_year: fiscalYear,
      }),
    select: res => res.data,
  });

  const {
    data: provincialPrioritiesGoalsData,
    isLoading: provincialPrioritiesGoalsDataIsLoading,
  } = useQuery<any, any, Goal[]>({
    queryKey: ['provincial-priorities-goals-data', provinceId, fiscalYear],
    queryFn: async () =>
      getProvincialPrioritiesGoalsData({
        province: provinceId,
        fiscal_year: fiscalYear,
      }),
    select: res => res.data,
  });

  const goalHeaders = useMemo(() => {
    const columns = [{ name: 'Provincial Priorities', id: 1 }];

    provincialPrioritiesHeaderData?.forEach(dt =>
      columns.push({ id: dt.id, name: dt.abbreviation }),
    );
    return columns;
  }, [provincialPrioritiesHeaderData]);

  const headerIdArray = provincialPrioritiesHeaderData?.map(
    (objId: any) => objId.id,
  );

  // map the `priority data` with the `priority header`
  const priorityData = provincialPrioritiesGoalsData?.map(item => {
    const data: Array<string | boolean> = [item?.goal];
    headerIdArray?.forEach(id => {
      const hasItem = item.program_ids.includes(id);
      data.push(hasItem);
    });
    return data;
  });

  const isLoading =
    provincialPrioritiesHeaderDataIsLoading ||
    provincialPrioritiesGoalsDataIsLoading;

  const hasData =
    !isEmpty(provincialPrioritiesHeaderData!!) &&
    !isEmpty(provincialPrioritiesGoalsData!!);

  if (isLoading) {
    return <Skeleton className="naxatw-h-96 naxatw-w-full" />;
  }

  if (!hasData) {
    return (
      <NoChartDataComponent
        className="naxatw-py-8"
        message="Provincial Priorities are not yet available."
        messageStyles="naxatw-text-sm sm:naxatw-text-xl"
      />
    );
  }

  return (
    <div className="scroll scrollbar naxatw-w-full naxatw-overflow-x-auto">
      <div className="naxatw-flex">
        <table className="naxatw-w-full naxatw-border-collapse">
          <thead>
            <tr>
              {goalHeaders?.map((goal, idx) => (
                <th
                  key={goal?.id}
                  className={`naxatw-border-[0.5px] naxatw-bg-white ${idx === 0 ? 'naxatw-sticky naxatw-left-0 naxatw-min-w-80 md:naxatw-min-w-[32rem]' : ''}`}
                >
                  <div className="naxatw-flex naxatw-h-full naxatw-min-h-[5rem] naxatw-items-center naxatw-px-6 naxatw-py-5 naxatw-text-[0.875rem] naxatw-font-bold naxatw-leading-5 md:naxatw-text-base">
                    {goal?.name}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {priorityData?.map(items => (
              <tr key={v4()} className="!naxatw-bg-transparent">
                {items?.map(item =>
                  typeof item === 'string' ? (
                    <td
                      className="naxatw-sticky naxatw-left-0 naxatw-min-h-[4rem] naxatw-border-[0.5px] naxatw-border-gray-200 naxatw-bg-white"
                      key={v4()}
                    >
                      <div className="naxatw-flex naxatw-w-full naxatw-items-center naxatw-self-stretch naxatw-px-6 naxatw-py-5 naxatw-text-sm naxatw-font-medium">
                        {item}
                      </div>
                    </td>
                  ) : (
                    <td
                      className={`naxatw-border-[0.5px] ${item ? 'naxatw-bg-[#E8F7F6]' : ''}`}
                      key={v4()}
                    >
                      <div className="naxatw-flex naxatw-min-h-[5rem] naxatw-min-w-[6rem] naxatw-items-center naxatw-justify-center naxatw-p-[0.625rem]">
                        {item ? (
                          <Icon
                            type="material-icons"
                            name="check_circle"
                            className="lg:naxatw-text-icon-md naxatw-text-[2.5rem] naxatw-text-[#45C1B9]"
                          />
                        ) : null}
                      </div>
                    </td>
                  ),
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default hasErrorBoundary(ProvincialProritiesTable);
