import { api, authenticated } from '.';

export const getKnowledgeRepository = (params: Record<string, any>) => {
  return authenticated(api).get(`/knowledge-repository/`, { params });
};
export const getKnowledgeRepositoryTableData = (
  params: Record<string, any>,
) => {
  return authenticated(api).get(`/knowledge-repo-info/`, { params });
};

export const getKnowledgeRepositoryById = (params: Record<string, any>) => {
  return authenticated(api).get(`/knowledge-repository/`, { params });
};

export const postKnowledgeRepository = (params: Record<string, any>) => {
  return authenticated(api).post(`/knowledge-repository/`, params);
};
export const postKnowledgeRepositoryProjects = (
  payload: Record<string, any>,
) => {
  return authenticated(api).post(`/knowledge-repository-project/`, payload);
};

export const postKnowledgeRepositoryProgrammes = (
  payload: Record<string, any>,
) => {
  return authenticated(api).post(`/knowledge-repository-program/`, payload);
};

export const postKnowledgeRepositoryThematicField = (
  payload: Record<string, any>,
) => {
  return authenticated(api).post(
    `/knowledge-repository-thematic-field/`,
    payload,
  );
};

export const patchKnowledgeRepository = (
  id: string,
  payload: Record<string, any>,
) => {
  return authenticated(api).patch(`/knowledge-repository/${id}/`, payload);
};

export const deleteKnowledgeRepository = (id: string) => {
  return authenticated(api).delete(`/knowledge-repository/${id}/`);
};

export const getKnowledgeRepositoryDocById = (id: string) => {
  return authenticated(api).get(
    `/knowledge-repository-document/?knowledge_repository=${id}`,
  );
};

export const postKnowledgeRepositoryDoc = (payload: Record<string, any>) => {
  return authenticated(api).post(`/knowledge-repository-document/`, payload);
};

export const deleteKnowledgeRepositoryDoc = (id: number) => {
  return authenticated(api).delete(`/knowledge-repository-document/${id}/`);
};

export const getProgrammeOptions = () => {
  return authenticated(api).get(`/program-dropdown/`);
};

export const getComponentOptions = () => {
  return authenticated(api).get(`/project-dropdown/`);
};

export const getThematicFieldOptions = () => {
  return authenticated(api).get(`/sector-dropdown/`);
};

export const getChoiceDropdownList = (type: string) => {
  return authenticated(api).get(`/choice/?type=${type}`);
};

export const getProgrammeProjectsDropDown = () => {
  return authenticated(api).get(`/project-by-program/`);
};

export const deleteKnowledgeRepositoryProgramme = (id: number) => {
  return authenticated(api).delete(`/knowledge-repository-program/${id}/`);
};

export const deleteKnowledgeRepositoryProject = (id: number) => {
  return authenticated(api).delete(`/knowledge-repository-project/${id}/`);
};

export const deleteKnowledgeRepositoryThematicField = (id: number) => {
  return authenticated(api).delete(
    `/knowledge-repository-thematic-field/${id}/`,
  );
};

export const shareKnowledgeRepo = (payload: Record<string, any>) => {
  return authenticated(api).post('/share-knowledge-repository/', payload);
};

export const getKnowledgeRepositoryDocumentsCount = (
  params: Record<string, any>,
) => {
  return authenticated(api).get('/knowledge-repo-document-count/', { params });
};
