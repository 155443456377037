import AdminHeader from '@Components/common/AdminHeader';
import { useEffect, useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useLocation, useNavigate } from 'react-router-dom';
import Portal from '@Components/common/Layouts/Portal';
import DailyTriviaTable from './DailyTriviaTable';
import DailyTriviaForm from './DailyTriviaForm';

const DailyTrivia = () => {
  const navigate = useNavigate();
  const [toggleForm, setToggleForm] = useState<boolean>(false);
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.includes('edit') || pathname.includes('add')) {
      setToggleForm(true);
    } else {
      setToggleForm(false);
    }
  }, [pathname]);

  const springs = useSpring({
    from: { y: 100 },
    to: { y: 0 },
  });

  return (
    <animated.div
      className="naxatw-flex naxatw-h-full naxatw-flex-col naxatw-space-y-6"
      style={{ ...springs }}
    >
      <AdminHeader
        title="Fun Trivia"
        onAddNewClick={() => {
          navigate('/data-bank/fun-trivia/add');
        }}
        registerButtonText="Add"
      />
      <DailyTriviaTable />
      {toggleForm && (
        <Portal>
          <DailyTriviaForm onClose={() => navigate('/data-bank/fun-trivia')} />
        </Portal>
      )}
    </animated.div>
  );
};

export default DailyTrivia;
