import { useSpring, animated } from '@react-spring/web';
import AdminHeader from '@Components/common/AdminHeader';
import { useQuery } from '@tanstack/react-query';
import { downloadReportList } from '@Services/report';
import getFileExtension from '@Utils/getFileExtension';
import ReportTable from './ReportTable';

const OldReportDownloadURL =
  'https://dvs-phase-2-dev.s3.ap-south-1.amazonaws.com/dvs-email-images/2021-2024data.xlsx';

const ReportComponent = () => {
  const springs = useSpring({
    from: { y: 100 },
    to: { y: 0 },
  });
  const { refetch } = useQuery({
    queryKey: ['getReportList'],
    queryFn: () =>
      downloadReportList({
        page: 1,
        items_per_page: 10,
        is_export: 'True',
      }),
    enabled: false,
    onSuccess: response => {
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(response.data);
      a.download = `reportData.csv`;
      a.click();
    },
  });

  function downloadOldData() {
    const a = document.createElement('a');
    const fileExt = getFileExtension(OldReportDownloadURL);
    a.href = OldReportDownloadURL;
    a.download = `reportData.${fileExt}`;
    a.click();
  }

  return (
    <div className="reports-form-container naxatw-h-full naxatw-space-y-6">
      <AdminHeader
        title="5WH Report"
        needAddNewButton={false}
        downloadAction={refetch}
        hasBulkUpDownload
        leadingDownloadLable="Old 5WH Data"
        leadingDownloadAction={() => downloadOldData()}
        hasFilterBtn
      />

      <animated.div style={{ ...springs }} className="naxatw-h-full">
        <ReportTable />
      </animated.div>
    </div>
  );
};

export default ReportComponent;
