/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState, useCallback, useEffect } from 'react';
import { Editor } from '@tiptap/react';
import { TooltipWrapper } from '@Components/RadixComponents/ToolTip';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuItem,
} from '@Components/RadixComponents/DropDownMenu';
import { DropdownMenuContent } from '@radix-ui/react-dropdown-menu';
import Input from '@Components/common/FormUI/Input';
import TipTapExtensionContainer from './TipTapExtenstionContainer';

type ToolbarProps = {
  editor: Editor | null;
};

export default function Toolbar({ editor }: ToolbarProps) {
  // const [imageURL, setImageURL] = useState('');
  const [linkText, setLinkText] = useState('');
  const [linkDropdownOpen, setLinkDropdownOpen] = useState(false);
  // const [imageDropdownOpen, setImageDropdownOpen] = useState(false);

  // set active extension styles
  const isEditorActive = (editorItem: string) => {
    if (editor?.isActive(editorItem)) {
      return 'naxatw-text-primary-700 naxatw-bg-secondary-200';
    }
    return '';
  };

  // add image to editor
  // const addImage = useCallback(() => {
  //   if (imageURL) {
  //     editor?.chain().focus().setImage({ src: imageURL }).run();
  //   }
  //   setImageURL('');
  // }, [editor, imageURL]);

  const setLink = useCallback(() => {
    const url = linkText;
    // cancelled
    if (url === null) {
      return;
    }
    // empty
    if (url === '') {
      editor?.chain().focus().extendMarkRange('link').unsetLink().run();
      return;
    }
    // update link
    editor
      ?.chain()
      .focus()
      .extendMarkRange('link')
      .setLink({ href: url })
      .run();
    setLinkText('');
    setLinkDropdownOpen(false);
  }, [editor, linkText]);

  // prevent form submission
  // when link dropdown is opened and user hit "Enter" key
  useEffect(() => {
    function handlePreventFormSubmit(eve: KeyboardEvent) {
      if (eve.key === 'Enter') {
        if (linkDropdownOpen) {
          eve?.preventDefault();
          setLink();
        }
      }
    }
    window.addEventListener('keypress', handlePreventFormSubmit);
    return () => {
      window.removeEventListener('keypress', handlePreventFormSubmit);
    };
  }, [linkDropdownOpen, setLink]);

  return (
    <div className="naxatw-flex naxatw-justify-between naxatw-border-b-[1px] naxatw-border-gray-300 naxatw-px-2">
      <div className="naxatw-flex naxatw-w-full naxatw-items-center naxatw-justify-between naxatw-py-1">
        <div className="naxatw-flex naxatw-flex-wrap naxatw-gap-2 ">
          <DropdownMenu>
            <DropdownMenuTrigger>
              <TooltipWrapper
                tooltipMessage="Heading"
                disabled
                triggerStyleClassName="extension-btn hover:naxatw-bg-teal-green-100 naxatw-cursor-pointer"
              >
                <div
                  className={` ${isEditorActive(
                    'heading',
                  )} naxatw-flex naxatw-h-[24px] naxatw-w-[24px] naxatw-items-center naxatw-justify-center naxatw-overflow-hidden naxatw-rounded naxatw-text-xl naxatw-text-gray-800`}
                >
                  <strong>H</strong>
                </div>
              </TooltipWrapper>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              className="custom-box-shadow naxatw-bg-red naxatw-z-50 naxatw-flex naxatw-gap-1 naxatw-rounded naxatw-border naxatw-border-gray-300 naxatw-bg-white"
              sideOffset={7}
              align="start"
            >
              <DropdownMenuItem className="!naxatw-px-3 !naxatw-py-1 !naxatw-text-lg hover:!naxatw-bg-transparent">
                <TooltipWrapper
                  tooltipMessage="Heading 1"
                  disabled
                  triggerStyleClassName="extension-btn hover:naxatw-bg-teal-green-100 naxatw-cursor-pointer"
                >
                  <p
                    onClick={() =>
                      editor?.chain().focus().toggleHeading({ level: 1 }).run()
                    }
                    className={`naxatw-rounded naxatw-p-1 ${
                      editor?.isActive('heading', { level: 1 })
                        ? 'naxatw-text-teal-green-600 naxatw-bg-teal-green-100'
                        : ''
                    } `}
                  >
                    H<sub>1</sub>
                  </p>
                </TooltipWrapper>
                <TooltipWrapper
                  tooltipMessage="Heading 2"
                  disabled
                  triggerStyleClassName="extension-btn hover:naxatw-bg-teal-green-100 naxatw-cursor-pointer"
                >
                  <p
                    onClick={() =>
                      editor?.chain().focus().toggleHeading({ level: 2 }).run()
                    }
                    className={`naxatw-rounded naxatw-p-1  ${
                      editor?.isActive('heading', { level: 2 })
                        ? 'naxatw-text-teal-green-600 naxatw-bg-teal-green-100'
                        : ''
                    }`}
                  >
                    H<sub>2</sub>
                  </p>
                </TooltipWrapper>
                <TooltipWrapper
                  tooltipMessage="Heading 3"
                  disabled
                  triggerStyleClassName="extension-btn hover:naxatw-bg-teal-green-100 naxatw-cursor-pointer"
                >
                  <p
                    onClick={() =>
                      editor?.chain().focus().toggleHeading({ level: 3 }).run()
                    }
                    className={`naxatw-rounded naxatw-p-1  ${
                      editor?.isActive('heading', { level: 3 })
                        ? 'naxatw-text-teal-green-600 naxatw-bg-teal-green-100'
                        : ''
                    } `}
                  >
                    H<sub>3</sub>
                  </p>
                </TooltipWrapper>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>

          {/* bold */}
          <TipTapExtensionContainer
            tooltipMessage="Bold"
            icon="format_bold"
            className={`${isEditorActive('bold')}`}
            onClick={() => editor?.chain().focus().toggleBold().run()}
          />

          {/* italics */}
          <TipTapExtensionContainer
            tooltipMessage="Italic"
            icon="format_italic"
            className={`${isEditorActive('italic')}`}
            onClick={() => editor?.chain().focus().toggleItalic().run()}
          />

          {/* strike-through */}
          {/* <TipTapExtensionContainer
            tooltipMessage="Strike"
            icon="strikethrough_s"
            className={`${isEditorActive('strike')}`}
            onClick={() => editor?.chain().focus().toggleStrike().run()}
          /> */}

          <TipTapExtensionContainer
            tooltipMessage="Ordered List"
            icon="format_list_numbered"
            onClick={() => editor?.chain().focus().toggleOrderedList().run()}
            className={`${isEditorActive('orderedList')}`}
          />

          {/* bullet-list */}
          <TipTapExtensionContainer
            tooltipMessage="Bullet List"
            icon="format_list_bulleted"
            onClick={() => editor?.chain().focus().toggleBulletList().run()}
            className={`${isEditorActive('bulletList')}`}
          />

          {/* code-block */}
          {/* <TipTapExtensionContainer
            tooltipMessage="Code"
            icon="code"
            onClick={() => editor?.chain().focus().toggleCodeBlock().run()}
            className={`${isEditorActive('codeBlock')}`}
          /> */}

          {/* quoute */}
          <TipTapExtensionContainer
            tooltipMessage="Quote"
            icon="format_quote"
            onClick={() => editor?.chain().focus().toggleBlockquote().run()}
            iconType="material-icons"
            className={`${isEditorActive('blockquote')}`}
          />

          {/* horizontal-ruler */}
          <TipTapExtensionContainer
            tooltipMessage="Horizontal Ruler"
            icon="horizontal_rule"
            onClick={() => editor?.chain().focus().setHorizontalRule().run()}
          />

          {/* link */}
          <DropdownMenu
            open={linkDropdownOpen}
            onOpenChange={state => {
              setLinkDropdownOpen(state);
              if (!state) {
                setLinkText('');
              }
            }}
          >
            <DropdownMenuTrigger>
              <TipTapExtensionContainer tooltipMessage="Link" icon="link" />
            </DropdownMenuTrigger>
            <DropdownMenuContent sideOffset={7} className="naxatw-z-[9999]">
              <div className="custom-box-shadow naxatw-flex naxatw-items-center naxatw-gap-2 naxatw-rounded naxatw-border naxatw-border-gray-300 naxatw-bg-white naxatw-p-1">
                <Input
                  placeholder="URL"
                  value={linkText}
                  type="text"
                  onChange={e => {
                    setLinkText(e.target.value);
                  }}
                  className="!naxatw-h-9 !naxatw-rounded"
                />

                <button
                  type="button"
                  onClick={setLink}
                  className="naxatw-bg-teal-green-600 naxatw-flex naxatw-justify-center naxatw-overflow-hidden naxatw-rounded naxatw-px-4 naxatw-py-1 naxatw-text-center naxatw-text-base naxatw-font-normal"
                >
                  Add
                </button>
              </div>
            </DropdownMenuContent>
          </DropdownMenu>

          {/* image */}
          {/* <DropdownMenu open={imageDropdownOpen} onOpenChange={(state) => setImageDropdownOpen(state)}>
            <DropdownMenuTrigger>
              <TipTapExtensionContainer tooltipMessage="Image" icon="add_photo_alternate" />
            </DropdownMenuTrigger>
            <DropdownMenuContent sideOffset={7} className="naxatw-z-[9999]">
              <div className="naxatw-flex naxatw-p-1 naxatw-gap-2 naxatw-items-center custom-box-shadow naxatw-rounded naxatw-border naxatw-border-gray-300 naxatw-bg-white">
                <Input
                  type="text"
                  placeholder="Image URL"
                  onChange={(e) => {
                    setImageURL(e.target.value);
                  }}
                  value={imageURL}
                  className="!naxatw-h-9 !naxatw-rounded"
                />
                <button
                  type="button"
                  onClick={addImage}
                  className="naxatw-bg-teal-green-600 naxatw-text-white naxatw-px-4 naxatw-py-1 naxatw-rounded naxatw-overflow-hidden naxatw-text-center naxatw-flex naxatw-justify-center naxatw-text-base naxatw-font-normal"
                >
                  Insert
                </button>
              </div>
            </DropdownMenuContent>
          </DropdownMenu> */}
        </div>

        {/* redo / undo */}
        <div className="redo-undo naxatw-flex naxatw-items-center naxatw-gap-2">
          <TipTapExtensionContainer
            tooltipMessage="Undo"
            icon="undo"
            onClick={() => editor?.chain().focus().undo().run()}
          />

          <TipTapExtensionContainer
            tooltipMessage="Redo"
            icon="redo"
            onClick={() => editor?.chain().focus().redo().run()}
          />
        </div>
      </div>
    </div>
  );
}
