/* eslint-disable camelcase */
import formatDate from '@Utils/formatDate';
import {
  getProgrammeProgressDataByProgrammeId,
  getProgrammeProgressDataByComponentId,
} from '@Services/provincialProfile';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { FlexColumn } from '@Components/common/Layouts';
import DefaultCard from '../Cards/Default';

const StatusBar = () => {
  const { programmeId } = useParams();
  const [searchParams] = useSearchParams();
  const componentId = searchParams.get('componentId');
  const { data: programmeProgressData, isLoading: programmeProgressIsLoading } =
    useQuery({
      queryKey: ['programme-progress-data', programmeId],
      queryFn: async () =>
        getProgrammeProgressDataByProgrammeId({ program: programmeId }),
      // enabled: !!provinceId || !!searchedText,
      select: res => res.data,
    });

  const { data: componentProgressData } = useQuery({
    queryKey: ['programme-progress-data', programmeId, componentId],
    queryFn: async () =>
      getProgrammeProgressDataByComponentId({
        program: programmeId,
        project: componentId,
      }),
    enabled: !!componentId,
    select: res => res.data,
  });

  const { start_date, end_date, progress_percentage } = !componentId
    ? programmeProgressData || {}
    : componentProgressData || {};
  const formatStartDate = formatDate(start_date);
  const formatEndDate = formatDate(end_date);

  if (programmeProgressIsLoading)
    return <Skeleton className="naxatw-h-[10rem] naxatw-w-full" />;

  return (
    <DefaultCard className="naxatw-px-3">
      <FlexColumn className="naxatw-gap-2 naxatw-bg-white">
        <h5 className="naxatw-text-primary-700">
          {progress_percentage < 0 ? 0 : progress_percentage}%
        </h5>
        <div
          style={{
            width: `${progress_percentage < 0 ? 0 : progress_percentage || 0}%`,
          }}
          className={`naxatw-relative naxatw-h-3 ${progress_percentage === 100 ? 'naxatw-rounded-2xl' : 'naxatw-rounded-s-2xl'} naxatw-bg-[#08519C]`}
        />
        <div className="naxatw-flex naxatw-justify-between naxatw-self-stretch">
          <span className="naxatw-body-caption naxatw-text-matt-100">
            Start Date{' '}
            <strong className="naxatw-text-primary-700">
              {formatStartDate}
            </strong>
          </span>

          <span className="naxatw-body-caption naxatw-text-matt-100">
            End Date{' '}
            <strong className="naxatw-text-primary-700">{formatEndDate}</strong>
          </span>
        </div>
      </FlexColumn>
    </DefaultCard>
  );
};

export default StatusBar;
