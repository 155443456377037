/* eslint-disable no-nested-ternary */
import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ProvinceIntitailState {
  selectedFeature: Record<string, any> | null;
  selectedCountOption: string;
  searchedText: string;
  activeBaseLayer: string;
  chartSwitchTab: {
    sector: string;
    partner: string;
  };
  filterBy: string;
}

const initialState: ProvinceIntitailState = {
  selectedFeature: null,
  selectedCountOption: 'programme',
  searchedText: '',
  activeBaseLayer: 'mapbox-light',
  chartSwitchTab: {
    sector: 'Project',
    partner: 'Project',
  },
  filterBy: '',
};

const setProvincialProfileStates: CaseReducer<
  ProvinceIntitailState,
  PayloadAction<Record<string, any> | null>
> = (state, action) => ({
  ...state,
  ...action.payload,
});

const setSelectedFeature: CaseReducer<
  ProvinceIntitailState,
  PayloadAction<Record<string, any> | null>
> = (state, action) => {
  const district = action?.payload || {};
  return {
    ...state,
    selectedFeature: state?.selectedFeature
      ? state.selectedFeature?.id === district?.id
        ? null
        : district
      : district,
  };
};

const setSelectedCountOption: CaseReducer<
  ProvinceIntitailState,
  PayloadAction<string>
> = (state, action) => {
  const option = action?.payload ?? 'programme';
  return {
    ...state,
    selectedCountOption: option,
  };
};

const setSearchedText: CaseReducer<
  ProvinceIntitailState,
  PayloadAction<string>
> = (state, action) => {
  const text = action?.payload ?? '';
  return {
    ...state,
    searchedText: text,
  };
};

const setSectorsSelectedTab: CaseReducer<
  ProvinceIntitailState,
  PayloadAction<string>
> = (state, action) => {
  return {
    ...state,
    chartSwitchTab: {
      ...state.chartSwitchTab,
      sector: action.payload,
    },
  };
};

const setPartnersSelectedTab: CaseReducer<
  ProvinceIntitailState,
  PayloadAction<string>
> = (state, action) => {
  return {
    ...state,
    chartSwitchTab: {
      ...state.chartSwitchTab,
      partner: action.payload,
    },
  };
};

const setProvincialProfileFilterBy: CaseReducer<
  ProvinceIntitailState,
  PayloadAction<string>
> = (state, action) => ({
  ...state,
  filterBy: action.payload,
});

const provincialProfileSlice = createSlice({
  name: 'provincialProfileSlice',
  initialState,
  reducers: {
    setProvincialProfileStates,
    setSelectedFeature,
    setSelectedCountOption,
    setSearchedText,
    setSectorsSelectedTab,
    setPartnersSelectedTab,
    setFilterBy: setProvincialProfileFilterBy,
  },
});

export { provincialProfileSlice };

export default provincialProfileSlice.reducer;
