import ChartRenderer from '@Components/common/Charts/ChartRenderer';
import { useState } from 'react';

interface IChartContainer {
  chartList: any[];
}

const active = 'naxatw-bg-[#1C66A4] naxatw-text-white';

const getIconByChart = (type: string) => {
  switch (type) {
    case 'treeMap':
      return 'dashboard';
    case 'bar':
      return 'bar_chart';
    case 'pieChart':
      return 'pie_chart';
    case 'verticalBar':
      return 'align_horizontal_left';
    case 'donut':
      return 'donut_large';
    default:
      return 'align_horizontal_left';
  }
};

const ChartContainer = ({ chartList }: IChartContainer) => {
  const [onViewChartIndex, setOnViewChartIndex] = useState(0);

  return (
    <div className="naxatw-flex naxatw-h-full naxatw-w-full naxatw-rounded-lg naxatw-border">
      <div className="naxatw-h-full naxatw-w-[350px] naxatw-border-r naxatw-px-4">
        <h3 className="naxatw-mb-1 naxatw-py-5 naxatw-text-lg naxatw-font-bold naxatw-leading-5 naxatw-text-gray-500">
          Charts Overview
        </h3>
        <div className="naxatw-flex naxatw-flex-col naxatw-gap-2">
          {chartList?.map((chart, index: number) => (
            <button
              type="button"
              key={chart?.id}
              className={`naxatw-flex naxatw-cursor-pointer naxatw-gap-2  naxatw-rounded-lg naxatw-p-2 hover:naxatw-bg-[#1C66A4] hover:naxatw-text-white ${index === onViewChartIndex ? active : ''}`}
              onClick={() => setOnViewChartIndex(index)}
            >
              <span className="material-icons naxatw-text-xl">
                {getIconByChart(chart?.chartType)}
              </span>
              <div className="naxatw-text-left naxatw-text-xs naxatw-font-normal">
                {chart?.title}
              </div>
            </button>
          ))}
        </div>
      </div>
      <div className="naxatw-w-full">
        {chartList?.[onViewChartIndex] && (
          <ChartRenderer
            data={chartList?.[onViewChartIndex]}
            key={chartList?.[onViewChartIndex]?.id}
          />
        )}
      </div>
    </div>
  );
};

export default ChartContainer;
