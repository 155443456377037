/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useEffect, useRef } from 'react';
import osm from '@Assets/images/layers/osm.png';
import satellite from '@Assets/images/layers/satellite.png';
import mapbox from '@Assets/images/layers/mapbox.png';
import openTopo from '@Assets/images/layers/open-topo-map.png';
import stadia from '@Assets/images/layers/stadia-adilade-smooth.png';
import none from '@Assets/images/layers/none.png';
import hybrid from '@Assets/images/layers/hybrid-baseLayer.png';

// import MapAdditionalTools from '@Components/MainDashboard/MapSection/MapAdditionalTools';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import Image from '@Components/RadixComponents/Image';
import { IBaseLayerSwitcher } from '../types';
import baseLayersData from './baseLayers';

const layerIcons: {
  [key: string]: any;
  osm: any;
  satellite: any;
  mapbox: any;
} = {
  osm,
  satellite,
  hybrid,
  mapbox,
  stadia,
  openTopo,
};

const baseLayerOptions = [
  { id: 'osm', name: 'OSM', image: osm },
  { id: 'satellite', name: 'Satellite', image: satellite },
  { id: 'hybrid', name: 'Hybrid', image: hybrid },
  { id: 'mapbox-light', name: 'MapBox Light', image: mapbox },
  // { id: 'stadia-adilade-smooth', name: 'Stadia adilade smooth', image: stadia },
  { id: 'open-topo-map', name: 'OpenTopoMap', image: openTopo },
  { id: 'none', name: 'None', image: none },
];

export default function BaseLayerSwitcher({
  map,
  isMapLoaded,
  baseLayers = baseLayersData,
  activeLayer = 'osm',
  onBaseLayerChange,
}: IBaseLayerSwitcher) {
  const popupRef = useRef<HTMLDivElement>(null);
  const previouslyActiveLayer = useRef(activeLayer);

  const [showLayerList, setShowLayerList] = useState(false);

  // add all base layers to map
  useEffect(() => {
    if (!map || !isMapLoaded) return;
    Object.entries(baseLayers).forEach(([key, { layer, source }]) => {
      map.addSource(key, source);
      map.addLayer(layer);
    });
    if (!map.getLayer(activeLayer)) return;
    map.setLayoutProperty(activeLayer, 'visibility', 'visible');
    previouslyActiveLayer.current = activeLayer;
  }, [map, baseLayers, isMapLoaded]); // eslint-disable-line

  // change visibility layout property based on active layer
  useEffect(() => {
    if (!map || !isMapLoaded) return;
    map.setLayoutProperty(previouslyActiveLayer.current, 'visibility', 'none');
    if (!map.getLayer(activeLayer)) return;
    map.setLayoutProperty(activeLayer, 'visibility', 'visible');
    previouslyActiveLayer.current = activeLayer;
  }, [map, activeLayer, isMapLoaded]);

  const getLayerIcon = (layer: string) => {
    switch (layer) {
      case 'mapbox-light':
        return layerIcons.mapbox;
      case 'stadia-adilade-smooth':
        return layerIcons.stadia;
      case 'open-topo-map':
        return layerIcons.openTopo;
      default:
        return layerIcons[layer];
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        setShowLayerList?.(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setShowLayerList, popupRef]);

  return (
    <div
      className="actions naxatw-absolute naxatw-left-[1.37%] naxatw-top-[10rem]"
      title="Base layer switcher"
      ref={popupRef}
    >
      <FlexColumn gap={2} className="naxatw-w-10">
        {/* <MapAdditionalTools map={map} /> */}
      </FlexColumn>
      <FlexRow gap={3.5}>
        <div
          style={{
            backgroundImage: `url(${getLayerIcon(activeLayer)})`,
          }}
          onClick={() => setShowLayerList(!showLayerList)}
          className="naxatw-z-10 naxatw-mt-2 naxatw-h-10 naxatw-w-10 !naxatw-cursor-pointer naxatw-rounded-full naxatw-border-2 naxatw-border-[#41588f] naxatw-bg-contain naxatw-animate-in"
        />
        {showLayerList && (
          <FlexColumn className="naxatw-z-[1000] naxatw-w-[12rem] naxatw-cursor-pointer naxatw-rounded naxatw-bg-white naxatw-p-1">
            {baseLayerOptions.map(layer => (
              <FlexRow
                key={layer.id}
                className={`naxatw-items-center naxatw-px-2 naxatw-py-1 hover:naxatw-bg-primary-200  ${layer.id === activeLayer ? 'naxatw-bg-primary-200' : ''}`}
                gap={3}
                onClick={() => {
                  onBaseLayerChange?.(layer.id);
                  setShowLayerList(false);
                }}
              >
                <Image
                  src={layer.image}
                  width={30}
                  className="naxatw-rounded-md"
                />
                <span className="naxatw-text-xs">{layer.name}</span>
              </FlexRow>
            ))}
          </FlexColumn>
        )}
      </FlexRow>
    </div>
  );
}
