/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Button } from '@Components/RadixComponents/Button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  PopoverArrow,
} from '@Components/RadixComponents/Popover';
import Icon from '@Components/common/Icon';
import { getReportFilterKeys } from '@Services/report';
import Searchbar from '@Components/common/SearchBar';
import { useSearchParams } from 'react-router-dom';
import isEmpty from '@Utils/isEmpty';
import Checkbox from '@Components/common/FormUI/CheckBox';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import CheckBoxSkeleton from '@Components/common/FormComponent/CheckBoxSkeleton';
import { useDispatch } from 'react-redux';
import {
  setFilterState,
  clearFilterState,
  setAreaFilterState,
  setDaterangeFilterState,
  clearDateRangeFilterState,
} from '@Store/actions/dataBankForms';
import useDebouncedInput from '@Hooks/useDebouncedInput';
import { useTypedSelector } from '@Store/hooks';
import NoChartDataComponent from '@Components/common/Charts/NoChartDataComponent';
import {
  mappedFilterFieldsKeys,
  mappedFilterKeys,
  mappedFilterParmas,
  nationalLevel,
  singleFilterkeys,
} from '@Constants/FormConstants/reports';
import MultipleDatePicker from '@Components/common/FormUI/MultipleDatePicker/MultipleDatePicker';

const ReportFilterPopOver = ({ onClick, headerId }: any) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState<any>('');
  const [searchFilterParams] = useSearchParams();
  const searchFilterCategory = searchFilterParams.get('filtercategory') || '';
  const isEnabled = searchFilterParams.get('filter') === 'true';
  const [selectedFilterData, setSelectedFilterData] = useState<
    (string | number)[]
  >([]);

  const filterFields = useTypedSelector(
    state => state.databankforms?.report?.filterParams,
  );

  const areaFilterField = useTypedSelector(
    state => state.databankforms?.report?.area,
  );

  const startDateOneFilterParams = useTypedSelector(
    state => state.databankforms.report.filterParams?.start_date_1,
  );

  const startDateTwoFilterParams = useTypedSelector(
    state => state.databankforms.report.filterParams?.start_date_2,
  );

  const endDateOneFilterParams = useTypedSelector(
    state => state.databankforms.report.filterParams?.end_date_1,
  );

  const endDateTwoFilterParams = useTypedSelector(
    state => state.databankforms.report.filterParams?.end_date_2,
  );

  const [searchText, handleSearchText] = useDebouncedInput({
    ms: 400,
    init: searchTerm,
    onChange: debouncedEvent => setSearchTerm(debouncedEvent.target.value),
  });

  const { data: listedData, isFetching: fetchingFilterListData } = useQuery({
    queryKey: [
      'get-filters-key',
      searchFilterCategory,
      searchTerm,
      filterFields,
      startDateOneFilterParams,
      startDateTwoFilterParams,
      endDateOneFilterParams,
      endDateTwoFilterParams,
    ],
    queryFn: () =>
      getReportFilterKeys({
        search: searchText,
        key: `${mappedFilterKeys[searchFilterCategory]}` || '',
        project_id:
          searchFilterCategory !== 'project'
            ? filterFields?.project
              ? filterFields.project.join(',')
              : ''
            : '',
        program_id:
          searchFilterCategory !== 'program'
            ? filterFields?.program
              ? filterFields?.program.join(',')
              : ''
            : '',
        first_tier_partner_id:
          searchFilterCategory !== 'first_tier_partner'
            ? filterFields?.first_tier_partner
              ? filterFields?.first_tier_partner.join(',')
              : ''
            : '',
        sector_id:
          searchFilterCategory !== 'sector'
            ? filterFields?.sector
              ? filterFields?.sector.join(',')
              : ''
            : '',
        marker_id:
          searchFilterCategory !== 'marker'
            ? filterFields?.marker
              ? filterFields?.marker.join(',')
              : ''
            : '',
        stakeholder_type:
          searchFilterCategory !== 'stakeholder'
            ? filterFields?.stakeholder_name
              ? filterFields?.stakeholder_name.join(',')
              : ''
            : '',
        province_id:
          searchFilterCategory !== 'working_area'
            ? filterFields?.province
              ? filterFields?.province.join(',')
              : ''
            : '',
        start_date_1: startDateOneFilterParams || '',
        start_date_2: startDateTwoFilterParams || '',
        end_date_1: endDateOneFilterParams || '',
        end_date_2: endDateTwoFilterParams || '',
      }),
    enabled: isEnabled && !!searchFilterCategory,
    select: res => {
      if (!res?.data) return [];
      const responseData = res?.data || [];
      const manipulatedData = responseData?.map((responseItem: any) => {
        if (singleFilterkeys?.includes(searchFilterCategory)) {
          return {
            id: responseItem,
            name: responseItem,
          };
        }
        return {
          id: responseItem[`${mappedFilterFieldsKeys[searchFilterCategory]}id`],
          name: responseItem[
            `${mappedFilterFieldsKeys[searchFilterCategory]}name`
          ],
        };
      });

      if (searchFilterCategory === 'working_area') {
        return [...manipulatedData, nationalLevel];
      }

      return manipulatedData;
    },
  });

  const handleFilterChange = (list: Record<string, any>) => {
    const selectedId = list.id;
    setSelectedFilterData(prev => {
      if (prev.includes(selectedId)) {
        return prev.filter(id => id !== selectedId);
      }
      return [...prev, selectedId];
    });
  };

  const handleSelectAll = () => {
    if (selectedFilterData?.length === listedData?.length) {
      setSelectedFilterData([]);
    } else {
      const allIds = listedData?.map((list: Record<string, any>) => list.id);
      setSelectedFilterData(allIds);
    }
  };

  useEffect(() => {
    if (filterFields || areaFilterField) {
      const selectedData =
        filterFields[mappedFilterParmas[searchFilterCategory]] || [];
      const finalData = [...selectedData, areaFilterField]?.filter(
        filterItem => filterItem !== '',
      );
      setSelectedFilterData(finalData);
    }
  }, [filterFields, searchFilterCategory, areaFilterField]);

  const isFiltered = filterFields?.[mappedFilterParmas[headerId]];

  return (
    <div className="report-filter-wrapper" onClick={onClick}>
      {headerId === 'start_date' ? (
        <MultipleDatePicker
          startDate={startDateOneFilterParams}
          setStartDate={(date: Date) =>
            dispatch(
              setDaterangeFilterState({
                key: 'start_date_1',
                value: date,
              }),
            )
          }
          endDate={startDateTwoFilterParams}
          setEndDate={(date: Date) =>
            dispatch(
              setDaterangeFilterState({
                key: 'start_date_2',
                value: date,
              }),
            )
          }
          clearDateRange={() =>
            dispatch(
              clearDateRangeFilterState({
                type: 'start_date',
              }),
            )
          }
          contentSideOffset={8}
          showContentArrow
          datePickerColor="naxatw-bg-primary-600 hover:!naxatw-bg-primary-600"
          iconStyles={
            filterFields &&
            (filterFields.start_date_1 || filterFields.start_date_2)
              ? 'naxatw-text-secondary-500'
              : ''
          }
        />
      ) : headerId === 'end_date' ? (
        <MultipleDatePicker
          startDate={endDateOneFilterParams}
          setStartDate={(date: Date) =>
            dispatch(
              setDaterangeFilterState({
                key: 'end_date_1',
                value: date,
              }),
            )
          }
          endDate={endDateTwoFilterParams}
          setEndDate={(date: Date) =>
            dispatch(
              setDaterangeFilterState({
                key: 'end_date_2',
                value: date,
              }),
            )
          }
          clearDateRange={() =>
            dispatch(
              clearDateRangeFilterState({
                type: 'end_date',
              }),
            )
          }
          contentSideOffset={8}
          showContentArrow
          datePickerColor="naxatw-bg-primary-600 hover:!naxatw-bg-primary-600"
          iconStyles={
            filterFields && (filterFields.end_date_1 || filterFields.end_date_2)
              ? 'naxatw-text-secondary-500'
              : ''
          }
        />
      ) : (
        <Popover open={isOpen} onOpenChange={setIsOpen}>
          <PopoverTrigger className="naxatw-flex">
            <Icon
              name="filter_alt"
              type={isFiltered && 'material-icons'}
              className={`naxatw-text-xl naxatw-leading-5 naxatw-text-matt-100 ${isFiltered ? 'naxatw-text-secondary-500' : ''}`}
            />
          </PopoverTrigger>
          <PopoverContent
            className="popover-content-wrapper !naxatw-w-[20rem] !naxatw-bg-white  !naxatw-p-0"
            align="center"
            sideOffset={12}
          >
            <div className="popover-content-container naxatw-h-full naxatw-w-full">
              {/* listed data */}
              <div className="lists-wrapper">
                <div className="lists-wrapper-inner naxatw-h-full naxatw-w-full naxatw-px-5 !naxatw-pt-5 naxatw-pb-0">
                  <div className="select-all-clear-btn naxatw-flex naxatw-items-center naxatw-justify-between naxatw-pb-3 naxatw-pl-1 naxatw-pt-2">
                    <Checkbox
                      label="Select all"
                      labelClassName="naxatw-text-matt-200"
                      onChange={handleSelectAll}
                      checked={
                        selectedFilterData?.length === listedData?.length &&
                        listedData?.length > 0
                      }
                    />
                    {(filterFields?.[searchFilterCategory] ||
                      !isEmpty(selectedFilterData)) && (
                      <span
                        className="naxatw-cursor-pointer naxatw-text-[0.875rem] naxatw-font-semibold naxatw-text-[#417EC9]"
                        onClick={() => {
                          if (filterFields) {
                            dispatch(clearFilterState({}));
                          }
                          setSelectedFilterData([]);
                        }}
                      >
                        Clear
                      </span>
                    )}
                  </div>
                  <Searchbar
                    value={searchText}
                    onChange={handleSearchText}
                    className="searchbar"
                    isSmall
                  />
                  {fetchingFilterListData ? (
                    <CheckBoxSkeleton numRows={3} />
                  ) : isEmpty(listedData) ? (
                    <NoChartDataComponent className="naxatw-p-8" />
                  ) : (
                    <div className="list-container scrollbar naxatw-my-1 naxatw-max-h-[15rem] naxatw-overflow-x-auto naxatw-pl-1">
                      <div className="lists-items">
                        {listedData?.map((listItem: Record<string, any>) => (
                          <Checkbox
                            label={listItem.name}
                            key={listItem.id}
                            mainWrapperStyles="naxatw-py-2 last:naxatw-pb-4"
                            labelClassName="naxatw-body-sm naxatw-truncate naxatw-text-matt-200"
                            onChange={() => handleFilterChange(listItem)}
                            name={listItem.name}
                            checked={selectedFilterData?.includes(listItem.id)}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div className="buttons naxatw-flex naxatw-items-center naxatw-justify-center naxatw-gap-3 naxatw-px-6 naxatw-py-3 naxatw-shadow-formshadow">
                  <Button
                    variant="secondary"
                    onClick={() => setIsOpen(false)}
                    className="!naxatw-h-9 !naxatw-px-4 !naxatw-py-2"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={e => {
                      e.preventDefault();
                      if (isEmpty(listedData)) return;
                      if (searchFilterCategory === 'working_area') {
                        if (selectedFilterData?.includes(nationalLevel?.name)) {
                          dispatch(setAreaFilterState(nationalLevel?.name));
                        }
                        dispatch(
                          setFilterState({
                            [mappedFilterParmas[searchFilterCategory]]:
                              selectedFilterData?.filter(
                                filterItem =>
                                  filterItem !== nationalLevel?.name,
                              ),
                          }),
                        );
                      } else {
                        dispatch(
                          setFilterState({
                            [mappedFilterParmas[searchFilterCategory]]:
                              selectedFilterData,
                          }),
                        );
                      }

                      setIsOpen(false);
                    }}
                    className="!naxatw-h-9 !naxatw-px-4 !naxatw-py-2"
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </div>
            <PopoverArrow />
          </PopoverContent>
        </Popover>
      )}
    </div>
  );
};

export default hasErrorBoundary(ReportFilterPopOver);
