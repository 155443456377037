import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import Skeleton from '@Components/RadixComponents/Skeleton';
import {
  getComponentDetailCount,
  getProgrammeDataByProgrammeId,
} from '@Services/provincialProfile';
import { useQuery } from '@tanstack/react-query';
import { convertToCurrencySystem } from '@Utils/index';
import { useParams, useSearchParams } from 'react-router-dom';
import Metric from '../Cards/Metric';

function prefixWithZero(val: number) {
  return val > 0 && val <= 9 ? `0${val}` : val;
}

function MetricSkeleton() {
  return (
    <FlexRow className="naxatw-items-center naxatw-gap-3 naxatw-rounded-xl naxatw-border naxatw-border-gray-100 naxatw-bg-gray-50 naxatw-p-3">
      <Skeleton className="naxatw-h-12 naxatw-w-12 naxatw-rounded-full" />
      <FlexColumn className="naxatw-flex-1 naxatw-gap-3">
        <Skeleton className="naxatw-h-4 naxatw-w-6/12" />
        <Skeleton className="naxatw-h-5 naxatw-w-8" />
      </FlexColumn>
    </FlexRow>
  );
}

function Metrices() {
  const { programmeId } = useParams();
  const [searchParams] = useSearchParams();
  const componentId = searchParams.get('componentId');

  const { data: programmeDataList, isLoading: isProgrammeLoading } = useQuery({
    queryKey: ['programmeList', programmeId],
    queryFn: async () =>
      getProgrammeDataByProgrammeId({ program: programmeId }),
    // enabled: !!provinceId || !!searchedText,
    select: res => res.data[0],
    enabled: !!programmeId,
  });

  const { data: componentDataList, isLoading: isComponentDataLoading } =
    useQuery({
      queryKey: ['componentList', programmeId, componentId],
      queryFn: async () =>
        getComponentDetailCount({
          program: programmeId,
          project: componentId,
        }),
      enabled: !!componentId,
      select: res => res.data[0],
    });

  return (
    <div className="naxatw-grid-1 naxatw-grid naxatw-gap-6 sm:naxatw-grid-cols-2 lg:naxatw-grid-cols-4">
      {isProgrammeLoading ||
        (isComponentDataLoading && componentId ? (
          <>
            {!componentId && <MetricSkeleton />}
            <MetricSkeleton />
            <MetricSkeleton />
            <MetricSkeleton />
          </>
        ) : (
          <>
            {!componentId && (
              <Metric
                label="Project"
                metricCount={prefixWithZero(
                  componentDataList?.project_count ||
                    programmeDataList?.project_count,
                )}
                iconName="folder_open"
              />
            )}
            <Metric
              label="Sectors"
              metricCount={prefixWithZero(
                componentDataList?.sector_count ||
                  programmeDataList?.sector_count,
              )}
              iconName="category"
            />
            <Metric
              label="Total Allocated Budget"
              metricCount={convertToCurrencySystem(
                componentDataList?.allocated_budget ||
                  programmeDataList?.allocated_budget,
              )}
              iconName="currency_pound"
            />
            <Metric
              label="Markers"
              metricCount={prefixWithZero(
                componentDataList?.marker_count ||
                  programmeDataList?.marker_count,
              )}
              iconName="donut_small"
            />
          </>
        ))}
    </div>
  );
}
export default hasErrorBoundary(Metrices);
