import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
// import { viewByProgramme } from '@Constants/exploreByLocation';
// import SwitchTab from '@Components/common/FormUI/SwitchTab';
import { FlexRow } from '@Components/common/Layouts';
import Searchbar from '@Components/common/SearchBar';
import useDebouncedInput from '@Hooks/useDebouncedInput';
import { useTypedSelector } from '@Store/hooks';
import {
  setSearchedText,
  // setSelectedCountOption,
} from '@Store/actions/provincialProfile';
import SearchList from './SearchList';

const MapFilter = () => {
  const dispatch = useDispatch();
  const [clicked, setClicked] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const searchText = useTypedSelector(
    state => state.provincialProfile.searchedText,
  );
  const [value, handleChange] = useDebouncedInput({
    ms: 400,
    init: searchText,
    onChange: debouncedEvent => {
      dispatch(setSearchedText(debouncedEvent.target.value));
    },
  });

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setClicked(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <FlexRow className="actions naxatw-pointer-events-none naxatw-absolute naxatw-left-4 naxatw-top-[17px] naxatw-z-[20] naxatw-flex naxatw-w-full naxatw-flex-col naxatw-items-center naxatw-gap-2 naxatw-px-2 sm:naxatw-left-16 sm:naxatw-flex-row md:naxatw-left-16 lg:naxatw-left-10 lg:naxatw-px-6">
      {/* <SwitchTab
        title="View By"
        options={viewByProgramme}
        activeLabel="programme"
        activeClassName=" !naxatw-bg-[#5DB8B2] naxatw-text-white"
        wrapperClassName="-naxatw-ml-2"
        onChange={label => dispatch(setSelectedCountOption(label))}
      /> */}
      <div
        className="naxatw-pointer-events-auto naxatw-relative naxatw-w-[75%] md:naxatw-w-[30%]"
        ref={ref}
      >
        <Searchbar
          onChange={handleChange}
          placeholder="search municipality"
          value={value}
          onClick={() => setClicked(!clicked)}
        />
        {clicked && <SearchList onClose={() => setClicked(false)} />}
      </div>
    </FlexRow>
  );
};

export default MapFilter;
