import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LabelList,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { convertToCurrencySystem } from '@Utils/index';
import { IChartProps } from '../types';

const CustomizedLabel = (props: Record<string, any>) => {
  const { x, y, payload, currencyConvert } = props;
  return (
    <text
      x={payload?.value.toString().length === 1 ? +x - 8 : +x - 25 || 0}
      y={y + 10 || 0}
      fontSize="16"
      fontFamily="sans-serif"
      fill="#000000"
      color="red"
      textAnchor="start"
    >
      {!currencyConvert
        ? convertToCurrencySystem(payload?.value)
        : // @ts-ignore
          convertToCurrencySystem(payload?.value)}
    </text>
  );
};
const EllipsisLabel = ({ x, y, value, height, exportMode }: any) => {
  return (
    <text
      x={x + 5} // Center horizontally inside the bar
      y={y + height / 2} // Center vertically inside the bar
      dy={5} // Vertical adjustment (adjust as needed)
      textAnchor="start" // Align text to center
      fill="#484848" // White text inside bar (change as needed)
      fontSize={exportMode ? 12 : 14} // Adjust font size as needed
    >
      {value.length > 45 ? `${value.slice(0, 45)}...` : value}
    </text>
  );
};

const CustomTooltip = ({ active, payload, hoverTitle, fills }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip naxatw-relative naxatw-z-20 naxatw-rounded-xl naxatw-border-2 naxatw-bg-white naxatw-px-3 naxatw-py-2 naxatw-text-sm">
        <p className="label naxatw-font-bold">{payload[0].payload.fullName}</p>
        {payload?.map((dataPoint: any, index: number) => {
          if (dataPoint.dataKey !== 'name')
            return (
              <div
                key={dataPoint.dataKey}
                className="naxatw-flex naxatw-w-fit naxatw-items-center naxatw-justify-between naxatw-gap-5"
              >
                <div className="naxatw-flex naxatw-items-center naxatw-justify-center naxatw-gap-1">
                  <div
                    className="naxatw-h-3 naxatw-w-3 naxatw-rounded-sm"
                    style={{ backgroundColor: `${fills[index]}` }}
                  />
                  <span>{hoverTitle}</span>
                </div>
                <p className="naxatw-pt-1 naxatw-font-semibold">
                  {convertToCurrencySystem(dataPoint?.value)}
                </p>
              </div>
            );
          return <></>;
        })}
      </div>
    );
  }

  return null;
};
function VerticalBarChart({
  data,
  fills = ['#B7E7E4'],
  scrollable = false,
  width = '150%',
  height = '24rem',
  currencyConvert = false,
  hoverTitle = 'Budget',
  showFullScreen = false,
  exportMode,
}: IChartProps) {
  return (
    <div style={{ height: showFullScreen ? '100%' : height }}>
      <ResponsiveContainer
        width={scrollable && width ? width : '100%'}
        height="100%"
      >
        <BarChart
          width={500}
          height={230}
          data={data}
          barCategoryGap={3}
          layout="vertical"
          margin={{
            top: 5,
            right: 0,
            left: 30,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="1" />
          <XAxis
            type="number"
            domain={[0, 'dataMax + 1']}
            allowDecimals={false}
            tick={<CustomizedLabel currencyConvert={currencyConvert} />}
          />
          <Tooltip
            content={<CustomTooltip hoverTitle={hoverTitle} fills={fills} />}
          />
          <YAxis type="category" width={40} hide dataKey="name" />
          <Bar
            dataKey="value"
            fill="#B7E7E4"
            barSize={27}
            radius={[4, 4, 0, 0]}
          >
            <LabelList
              dataKey="name"
              position="inside"
              fill="#484848"
              width={300}
              fontSize={14}
              content={<EllipsisLabel exportMode={exportMode} />}
              dy={50}
            />

            {data.map((single: Record<string, any>, index: number) => (
              <Cell
                key={`cell-${single?.value || 1 + index}`}
                fill={
                  fills?.length === 1 ? fills[0] : fills[index] || '#B7E7E4'
                }
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default hasErrorBoundary(VerticalBarChart);
