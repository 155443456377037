import DefaultCard from '@Components/ProgrammeDetails/Cards/Default';
import { v4 as uuid } from 'uuid';

type Props = {
  markerName: string;
  markers: string[];
  className?: string;
};
const MarkerCard = ({ markerName, markers, className }: Props) => {
  return (
    <DefaultCard>
      <div className="naxatw-flex naxatw-gap-3">
        <div
          className={`naxatw-flex naxatw-items-center naxatw-justify-center ${className}`}
        >
          <p className="naxatw-body-sm naxatw-text-matt-200">{markerName}</p>
        </div>
        <div className="naxatw-w-[1px] naxatw-bg-[#E3E4E3]" />
        <ul className="naxatw-flex naxatw-list-inside naxatw-list-disc naxatw-flex-col naxatw-gap-1">
          {markers?.map((marker: string) => (
            <li className="naxatw-table-body naxatw-text-matt-100" key={uuid()}>
              {marker}
            </li>
          ))}
        </ul>
      </div>
    </DefaultCard>
  );
};

export default MarkerCard;
