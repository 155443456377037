import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import Icon from '@Components/common/Icon';
import { FlexRow } from '@Components/common/Layouts';

type MetricProps = {
  label: string;
  metricCount: number | string;
  iconName: string;
  className?: string;
};

function MetricCard({ label, metricCount, iconName, className }: MetricProps) {
  return (
    <FlexRow
      className={`naxatw-items-center naxatw-gap-3 naxatw-rounded-xl naxatw-border naxatw-border-gray-200 naxatw-p-3 naxatw-shadow-xs ${className || ''}`}
    >
      <Icon
        name={iconName}
        className="naxatw-cursor-default naxatw-rounded-full naxatw-bg-[#F4F7FE] naxatw-p-3 naxatw-text-[#5DB8B2]"
      />
      <article className="naxatw-min-w-0 naxatw-flex-1">
        <p className="naxatw-body-sm naxatw-mb-2 naxatw-min-w-0 naxatw-truncate">
          {label}
        </p>
        <h5 className="naxatw-text-[#06367D]">{metricCount}</h5>
      </article>
    </FlexRow>
  );
}

export default hasErrorBoundary(MetricCard);
