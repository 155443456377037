const mapParams = { project_name: 'project', programs: 'program' };

export const mapSortbyParamValue = (key: string) =>
  mapParams[key as keyof typeof mapParams] || key;

export const reverseSortbyParamValue = (key: string) =>
  Object.values(mapParams).reduce(
    (acc: Record<string, string>, cur: string, idx) => ({
      ...acc,
      [cur]: Object.keys(mapParams)[idx],
    }),
    {},
  )[key] || key;
