import { provincialProfileSlice } from '@Store/slices/provincialProfile';

export const {
  setProvincialProfileStates,
  setSelectedFeature,
  setSelectedCountOption,
  setSearchedText,
  setPartnersSelectedTab,
  setSectorsSelectedTab,
  setFilterBy,
} = provincialProfileSlice.actions;
