import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { getWorkingAreas } from '@Services/program';
import { getProvincialCountData } from '@Services/provincialProfile';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import SegmentCard from '../Cards/Segment';

type WorkingArea = {
  province: string;
  district: string;
  municipality: string;
};
type Props = {
  programmeId: string;
  projectId?: string;
};

type Counts = {
  program_count: number;
  project_count: number;
  first_tier_partner_count: number;
  sector_count: number;
  province_count: number;
  district_count: number;
  municipality_count: number;
  allocated_budget: number | null;
};

const DefaultPerSliceCount = 8;

function WorkingAreas({ programmeId, projectId }: Props) {
  const [searchParams] = useSearchParams();
  const componentId = searchParams.get('componentId');
  const [perSliceCount, setPerSliceCount] = useState(DefaultPerSliceCount);

  const { data, isLoading } = useQuery<any, any, WorkingArea[], any>({
    queryKey: ['workingAreas', programmeId, projectId],
    queryFn: () =>
      getWorkingAreas(
        projectId ? { project: projectId } : { program: programmeId },
      ),
    select: res => res.data[0].data,
  });
  const { data: dataCounts } = useQuery<any, any, Counts, any>({
    queryKey: ['programmeList', 'programmeCount', programmeId, componentId],
    queryFn: async () =>
      getProvincialCountData({
        [projectId ? 'project' : 'program']: projectId || programmeId,
      }),
    select: res => res.data,
  });

  const dataLen = data?.length || 0;
  const subtitle = `${dataCounts?.province_count || 0} Provinces, ${dataCounts?.district_count || 0} Districts and ${dataCounts?.municipality_count || 0} Palikas`;

  return (
    <SegmentCard title="Working Areas" iconName="place" subTitle={subtitle}>
      {isLoading ? (
        <div className="naxatw-flex naxatw-flex-wrap naxatw-gap-2">
          {Array(perSliceCount)
            .fill(0)
            .map((_, idx) => (
              <Skeleton
                // eslint-disable-next-line react/no-array-index-key
                key={idx}
                className="naxatw-h-9 naxatw-w-32 naxatw-px-3 naxatw-py-2"
              />
            ))}
        </div>
      ) : (
        <div className="scrollbar naxatw-flex naxatw-max-h-96 naxatw-flex-wrap naxatw-gap-2 naxatw-overflow-y-auto naxatw-pb-3">
          {data?.slice(0, perSliceCount).map(
            area =>
              area.province !== null && (
                <span
                  className={`naxatw-table-body naxatw-min-w-0 naxatw-truncate naxatw-rounded-3xl naxatw-border naxatw-px-3 naxatw-py-2 ${area === null ? 'naxatw-hidden' : 'naxatw-block'}`}
                  key={uuidv4()}
                >
                  <>
                    {area?.province}{' '}
                    {area?.district?.trim() && `/ ${area?.district}`}
                    {area?.municipality?.trim() && `/ ${area?.municipality}`}
                  </>
                </span>
              ),
          )}
          {dataLen > DefaultPerSliceCount && (
            <button
              type="button"
              className="naxatw-body-caption naxatw-rounded-xl naxatw-px-3 naxatw-py-2 naxatw-font-bold naxatw-text-primary-700 hover:naxatw-bg-primary-700 hover:naxatw-text-white"
              onClick={() =>
                setPerSliceCount(prevCount =>
                  prevCount >= dataLen
                    ? DefaultPerSliceCount
                    : prevCount + DefaultPerSliceCount,
                )
              }
            >
              See {perSliceCount >= dataLen ? 'Less' : 'More'}
            </button>
          )}
        </div>
      )}
    </SegmentCard>
  );
}
export default hasErrorBoundary(WorkingAreas);
