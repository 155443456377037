/* eslint-disable react/no-danger */
import { useQuery } from '@tanstack/react-query';
import pdfSvg from '@Assets/images/pdf.svg';
import docSvg from '@Assets/images/doc.svg';
import textSvg from '@Assets/images/text.svg';
import xlsSvg from '@Assets/images/sheets.svg';
import csvSvg from '@Assets/images/csv.svg';
import { format, parseISO } from 'date-fns';
import getFileExtension from '@Utils/getFileExtension';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import isEmpty from '@Utils/isEmpty';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { ReactNode, useRef, useState } from 'react';
import useSeeMoreButton from '@Hooks/useSeeMoreButton';
import ToolTip from '@Components/RadixComponents/ToolTip';
import { getKnowRepoDocuemntLink } from '@Services/publicKnowledgeRepository';
import { getFileNameFromURL } from '@Utils/index';
import Icon from '@Components/common/Icon';
import DocumentShareModal from '../ShareModal';

function getFileLogo(fileType: string) {
  switch (fileType) {
    case 'pdf':
      return pdfSvg;
    case 'doc':
      return docSvg;
    case 'docx':
      return docSvg;
    case 'txt':
      return textSvg;
    case 'xls':
      return xlsSvg;
    case 'xlsx':
      return xlsSvg;
    case 'csv':
      return csvSvg;
    default:
      return pdfSvg;
  }
}

type TableCommonProps = {
  className?: string;
  children?: ReactNode;
};

function TRow({ children, className }: TableCommonProps) {
  return (
    <tr className={`!naxatw-bg-transparent ${className || ''}`}>{children}</tr>
  );
}

function TData({ children, className }: TableCommonProps) {
  return (
    <td
      className={`!naxatw-bg-transparent naxatw-py-2 naxatw-align-baseline first:naxatw-pr-2 ${className || ''}`}
    >
      {children}
    </td>
  );
}

const KnowledgeCard = ({ repository }: { repository: Record<string, any> }) => {
  const summaryRef = useRef(null);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [showDocGenerateLink, setShwDocGenerateLink] = useState(false);

  const {
    file_type: fileType,
    published_date: date,
    thematic_field: thematicField,
    document_link: dLink,
    summary,
    tags,
    id,
    documents,
    programs,
  } = repository;
  // handle knowDoc Link fectch
  const { data: knoDocLink } = useQuery({
    queryKey: ['generate-document-link'],
    queryFn: () => getKnowRepoDocuemntLink({ id }),
    enabled: showDocGenerateLink,
    select: response => response?.data?.zip_url,
  });

  const allTags = tags?.split(',')?.filter((tag: string) => tag !== '');
  const { isOpen, setIsOpen, showSeeMoreBtn } = useSeeMoreButton(summaryRef);

  return (
    <>
      <div className="naxatw-w-full naxatw-border-b naxatw-border-[#D7D7D7] naxatw-p-5 last:naxatw-border-b-0">
        <FlexRow className="naxatw-mb-3 naxatw-items-center naxatw-gap-2">
          <p className="naxatw-text-base naxatw-font-medium naxatw-capitalize naxatw-leading-5 naxatw-text-[#0B2E62]">
            {repository?.title || '-'}
          </p>

          <div
            className="share-content naxatw-flex naxatw-items-center naxatw-gap-1 naxatw-px-4 naxatw-py-2"
            onClick={() => {
              setIsShareModalOpen(true);
              setShwDocGenerateLink(true);
            }}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
          >
            <ToolTip
              message="Share this document"
              name="share"
              className="naxatw-text-[1.25rem] !naxatw-text-secondary-500"
            />
          </div>
        </FlexRow>
        <div className="naxatw-grid naxatw-grid-cols-1 naxatw-gap-12 lg:naxatw-grid-cols-[3fr_2fr]">
          {/* summary */}
          <div className="content-left naxatw-flex naxatw-flex-col naxatw-gap-y-8">
            <div className="summary naxatw-min-h-32">
              <div
                className={`editor-content naxatw-text-xs naxatw-font-normal naxatw-leading-4 naxatw-text-matt-200 ${isOpen ? '' : 'naxatw-line-clamp-[4]'}`}
                ref={summaryRef}
                dangerouslySetInnerHTML={{
                  __html: summary || '<span>No summary to show.</span>',
                }}
              />
              {showSeeMoreBtn && (
                <button
                  type="button"
                  className="naxatw-text-[0.75rem] naxatw-font-bold naxatw-leading-[1.25rem] naxatw-text-[#41588F]"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  See {isOpen ? 'Less' : 'More'}
                </button>
              )}
            </div>

            <FlexColumn className="naxatw-gap-3">
              {/* download */}
              {isEmpty(documents) ? null : (
                <FlexColumn className="naxatw-flex naxatw-w-full naxatw-gap-2">
                  <span className="naxatw-text-xs naxatw-font-bold naxatw-tracking-[-0.015rem] naxatw-text-matt-100">
                    Download:
                  </span>
                  <div className="naxatw-grid naxatw-grid-cols-1 sm:naxatw-grid-cols-2">
                    {repository?.documents &&
                      repository?.documents.map((doc: Record<string, any>) => {
                        const docName = getFileNameFromURL(doc.file)
                          .split('?')
                          .shift();
                        return (
                          <div
                            key={doc.id}
                            className="naxatw-flex naxatw-items-center naxatw-gap-1 naxatw-overflow-hidden naxatw-rounded-[0.25rem] naxatw-bg-white naxatw-p-2 naxatw-shadow-[-1px_-1px_2px_0px_rgba(16,24,40,0.08),1px_1px_2px_0px_rgba(16,24,40,0.08)] hover:naxatw-shadow-[-2px_-2px_4px_0px_rgba(16,24,40,0.08),2px_2px_4px_0px_rgba(16,24,40,0.08)]"
                          >
                            <img
                              src={
                                getFileLogo(
                                  getFileExtension(doc?.file)?.split('?')[0] ||
                                    '-',
                                ) || '-'
                              }
                              alt="file"
                              className="naxatw-h-[1.125rem] naxatw-w-[1.125rem]"
                            />
                            <div className="naxatw-flex naxatw-w-full naxatw-items-center naxatw-justify-between naxatw-gap-1 naxatw-overflow-hidden">
                              <span className="naxatw-min-w-0 naxatw-truncate naxatw-text-xs naxatw-font-medium">
                                {docName}
                              </span>
                              <a
                                href={doc.file}
                                download
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Icon
                                  name="download"
                                  className="naxatw-flex naxatw-rounded-full naxatw-p-1 naxatw-text-[1.125rem] !naxatw-font-normal !naxatw-text-matt-100 hover:naxatw-bg-green-100"
                                />
                              </a>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </FlexColumn>
              )}

              {/* document links */}
              {dLink && (
                <div className="naxatw-flex naxatw-items-center naxatw-gap-2 naxatw-truncate">
                  <Icon
                    name="link"
                    className="naxatw-text-xl naxatw-text-matt-100"
                  />
                  <a
                    href={dLink}
                    className="naxatw-body-caption naxatw-min-w-0 naxatw-max-w-[32rem] naxatw-truncate naxatw-text-matt-100 naxatw-underline hover:naxatw-text-matt-200"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {dLink}
                  </a>
                </div>
              )}
            </FlexColumn>
          </div>
          <div className="content-right">
            <div
              className={`top-details ${
                !isEmpty(thematicField) ||
                programs?.[0]
                  ?.knowledge_repository_program__associate_program__name
                  ? 'naxatw-mb-6 '
                  : ''
              } naxatw-flex naxatw-flex-col naxatw-gap-2`}
            >
              <div className="bottom-details naxatw-flex naxatw-flex-col naxatw-gap-6">
                {/* date */}
                <div className="naxatw-flex naxatw-items-center naxatw-gap-3 naxatw-text-xs naxatw-font-medium">
                  <p className="naxatw-text-nowrap naxatw-rounded-[50px] naxatw-bg-[#CFE0F1] naxatw-px-2 naxatw-py-1 naxatw-text-matt-200">
                    {fileType || '-'}
                  </p>
                  <div className="naxatw-flex naxatw-items-center naxatw-gap-1">
                    <p className="naxatw-text-xs naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-200">
                      Published Date:
                    </p>
                    <span className="naxatw-text-xs naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-100">
                      {' '}
                      {format(parseISO(date), 'MMMM d, yyyy') || '-'}
                    </span>
                  </div>
                </div>
                <table className="naxatw-w-full naxatw-border-collapse">
                  <tbody>
                    {/* Program Row */}
                    {programs?.length > 0 && (
                      <TRow>
                        <TData className="naxatw-body-caption naxatw-font-bold naxatw-text-matt-100">
                          Programme:
                        </TData>
                        <TData>
                          <div className="naxatw-flex naxatw-flex-col naxatw-gap-[0.625rem]">
                            {programs?.map((programmeDetails: any) => (
                              <FlexColumn
                                key={
                                  programmeDetails?.knowledge_repository_program__associate_program__id
                                }
                                className="naxatw-gap-1"
                              >
                                <p className="naxatw-body-caption naxatw-font-bold naxatw-text-matt-200">
                                  {
                                    programmeDetails?.knowledge_repository_program__associate_program__name
                                  }
                                </p>
                                <FlexRow className="naxatw-flex-wrap naxatw-items-center">
                                  {programmeDetails?.projects?.map(
                                    (project: any, index: number) => {
                                      return (
                                        <div
                                          key={project?.id}
                                          className="naxatw-flex naxatw-items-center"
                                        >
                                          <span className="naxatw-body-caption naxatw-mr-[0.5rem] naxatw-font-medium naxatw-text-matt-200 last:naxatw-mr-0">
                                            {project?.name}
                                          </span>
                                          {programmeDetails?.projects?.length >
                                            0 &&
                                            index <
                                              (programmeDetails?.projects
                                                ?.length || 0) -
                                                1 && (
                                              <span className="naxatw-pr-[0.5rem] naxatw-text-secondary-300">
                                                |
                                              </span>
                                            )}
                                        </div>
                                      );
                                    },
                                  )}
                                </FlexRow>
                              </FlexColumn>
                            ))}
                          </div>
                        </TData>
                      </TRow>
                    )}
                    {/* Sector Row */}
                    {thematicField?.length > 0 && (
                      <TRow>
                        <TData className="naxatw-body-caption naxatw-font-bold naxatw-text-matt-100">
                          Sector:
                        </TData>
                        <TData>
                          {thematicField?.length > 0 && (
                            <ul className="naxatw-pl-2">
                              {thematicField?.map(
                                (sectorItem: Record<string, any>) => (
                                  <li
                                    key={sectorItem.id}
                                    className="naxatw-my-1 naxatw-flex naxatw-gap-2 naxatw-text-xs naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-100"
                                  >
                                    <i className="naxatw-mt-[0.4rem] naxatw-inline-block naxatw-h-1 naxatw-w-1 naxatw-rounded-full naxatw-bg-matt-100" />
                                    <span>
                                      {sectorItem.thematic_field__name}
                                    </span>
                                  </li>
                                ),
                              )}
                            </ul>
                          )}
                        </TData>
                      </TRow>
                    )}
                    {/* Tag Row */}
                    {!isEmpty(allTags) && (
                      <TRow>
                        <TData className="naxatw-body-caption naxatw-font-bold naxatw-text-matt-100">
                          Tags:
                        </TData>
                        <TData>
                          <div className="tags-container naxatw-flex naxatw-flex-wrap naxatw-items-center naxatw-gap-1">
                            {allTags.map((tag: string) => {
                              return (
                                <div
                                  className="naxatw-flex naxatw-items-center naxatw-justify-center naxatw-rounded-[0.25rem] naxatw-border naxatw-border-matt-200 naxatw-px-2 naxatw-py-1 naxatw-capitalize"
                                  key={tag}
                                >
                                  <p className="naxatw-text-xs naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-200">
                                    {tag}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </TData>
                      </TRow>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isShareModalOpen && (
        <DocumentShareModal
          handleClose={() => setIsShareModalOpen(false)}
          id={id}
          isShareModalOpen={isShareModalOpen}
          knowledgeRepDoc={knoDocLink}
        />
      )}
    </>
  );
};

export default hasErrorBoundary(KnowledgeCard);
