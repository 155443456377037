// import PartnerForm from '@Components/AdminPanel/Partners/PartnersForm';
import { adminSidebarData, adminSubSideBar } from '@Constants/adminSidebarData';
import Programmes from '@Components/AdminPanel/Programs';
import ComponentForm from '@Components/AdminPanel/Components/ComponentForm';
import Sector from '@Components/AdminPanel/Sectors/Sector';
import SubSector from '@Components/AdminPanel/Sectors/SubSector';
import MarkerCategories from '@Components/AdminPanel/Markers/MarkerCategories';
import MarkerValues from '@Components/AdminPanel/Markers/MarkerValues';
import KnowledgeRepository from '@Components/AdminPanel/KnowledgeRepository';
import Indicators from '@Components/AdminPanel/Indicators';
// import PartnerReportingRegistration from '@Components/AdminPanel/PartnerReporting/PartnerReportingRegistration';
import ComponentUpdateRegistration from '@Components/AdminPanel/ComponentUpdate/ComponentUpdateRegistration';
// import PartnerReportingForm from '@Components/AdminPanel/PartnerReporting/PartnerReportingForm';
import Partners from '@Components/AdminPanel/Partners';
import ProvincialPrioritiesForm from '@Components/AdminPanel/ProvincialProfile/ProvincialPriorities/ProvincialPrioritiesForm';
import ProvincialProfile from '@Components/AdminPanel/ProvincialProfile';
import UserManagement from '@Components/AdminPanel/UserSecurity/UserManagement';
import RoleManagement from '@Components/AdminPanel/UserSecurity/RoleManagement';
import MapLayers from '@Components/AdminPanel/MapLayers';
import Components from '@Components/AdminPanel/Components';
import ComponentUpdate from '@Components/AdminPanel/ComponentUpdate';
import DailyTrivia from '@Components/AdminPanel/DailyTrivia';
import ActivityLogs from '@Components/AdminPanel/ActivityLogs';
import VideosTutorial from '@Components/AdminPanel/Tutorials/Videos';
import FAQComponent from '@Components/AdminPanel/Tutorials/Questions';
import Manual from '@Components/AdminPanel/Tutorials/Manual';

import { IRoute } from './types';

function getRoutes(data: IRoute[]): IRoute[] {
  return data.map(({ name, path, component, children, permission }) => ({
    path,
    name,
    component,
    authenticated: false,
    permission,
    children: children ? getRoutes(children) : undefined,
  }));
}

export const adminDashboardRoutes: IRoute[] = [
  ...getRoutes(adminSidebarData),
  ...getRoutes(adminSubSideBar),
  {
    name: 'Partner Form Add',
    path: 'partners/add',
    component: Partners,
    permission: 'Can add organization',
  },
  {
    name: 'Partner Form Edit',
    path: 'partners/edit/:partnerID',
    component: Partners,
    permission: 'Can change organization',
  },
  {
    name: 'Program Form Add',
    path: 'programmes/add',
    component: Programmes,
    permission: 'Can add program',
  },
  {
    name: 'Program Form Edit',
    path: 'programmes/edit/:programID',
    component: Programmes,
    permission: 'Can change program',
  },
  {
    name: 'Components',
    icon: 'extension',
    path: 'components',
    component: Components,
    permission: 'Can view project',
  },
  {
    name: 'Component Form Add',
    path: 'project/add',
    component: ComponentForm,
    permission: 'Can add project',
  },
  {
    name: 'Component',
    icon: 'lab_profile',
    path: 'project-data',
    component: ComponentUpdate,
  },
  {
    name: 'Component Form Edit',
    path: 'project/edit/:projectID',
    component: ComponentForm,
    permission: 'Can change project',
  },
  {
    name: 'Component Update Registration',
    path: 'project-data/registration',
    component: ComponentUpdateRegistration,
  },
  {
    name: 'Component Update Form Edit',
    path: 'project-data/edit/:portfolioID',
    component: ComponentUpdateRegistration,
    permission: 'Can change project data',
  },
  {
    name: 'Sector Form Add',
    path: 'sectors/sector/add',
    component: Sector,
    permission: 'Can add sector',
  },
  {
    name: 'Sector Form Edit',
    path: 'sectors/sector/edit/:sectorId',
    component: Sector,
    permission: 'Can change sector',
  },
  {
    name: 'Sector Form Add',
    path: 'sectors/sub-sector/add',
    component: SubSector,
  },
  {
    name: 'Sector Form Edit',
    path: 'sectors/sub-sector/edit/:subSectorId',
    component: SubSector,
  },
  {
    name: 'Marker Categories Form Add',
    path: 'markers/markers-categories/add',
    component: MarkerCategories,
    permission: 'Can add marker category',
  },
  {
    name: 'Marker Categories Edit',
    path: 'markers/markers-categories/edit/:categoryId',
    component: MarkerCategories,
    permission: 'Can change marker category',
  },
  {
    name: 'Marker Values Add',
    path: 'markers/markers-values/add',
    component: MarkerValues,
    permission: 'Can add marker value',
  },
  {
    name: 'Marker Values Edit',
    path: 'markers/markers-values/edit/:valueId',
    component: MarkerValues,
    permission: 'Can change marker value',
  },
  {
    name: 'Knowledge Repository Add',
    path: 'knowledge-repository/add',
    component: KnowledgeRepository,
    permission: 'Can add knowledge repository',
  },
  {
    name: 'Knowledge Repository Edit',
    path: 'knowledge-repository/edit/:id',
    component: KnowledgeRepository,
    permission: 'Can change knowledge repository',
  },
  {
    name: 'Knowledge Repository Contextual',
    path: 'knowledge-repository/contextual',
    component: KnowledgeRepository,
    permission: 'Can view knowledge repository',
  },
  {
    name: 'Knowledge Repository Contextual Add',
    path: 'knowledge-repository/contextual/add',
    component: KnowledgeRepository,
    permission: 'Can add knowledge repository',
  },
  {
    name: 'Knowledge Repository Contextual Edit',
    path: 'knowledge-repository/contextual/edit/:id',
    component: KnowledgeRepository,
    permission: 'Can change knowledge repository',
  },
  {
    name: 'Indicators Add',
    path: 'indicators/add',
    component: Indicators,
  },
  {
    name: 'Indicators Edit',
    path: 'indicators/edit/:indicatorId',
    component: Indicators,
  },
  {
    name: 'Provnicial Priorities Add',
    path: 'provincial-profile/priorities/edit/:provinceId',
    component: ProvincialPrioritiesForm,
    permission: 'Can add provincial goal',
  },
  {
    name: 'Provnicial Summary Add',
    path: 'provincial-profile/summary/edit/:provinceId',
    component: ProvincialProfile,
    permission: 'Can add provincial goal',
  },
  {
    name: 'Provnicial Ministry Add',
    path: 'provincial-profile/ministry/edit/:Id',
    component: ProvincialProfile,
    permission: 'Can add provincial goal',
  },
  {
    name: 'User Management Form Add',
    path: 'user-security/user-management/add',
    component: UserManagement,
    permission: 'Admin-Only',
  },
  {
    name: 'User Management Form Edit',
    path: 'user-security/user-management/edit/:userId',
    component: UserManagement,
    permission: 'Admin-Only',
  },
  {
    name: 'User Management Change Password Form Edit',
    path: 'user-security/user-management/change-password/:userId',
    component: UserManagement,
    permission: 'Admin-Only',
  },
  {
    name: 'Role Management Form Add',
    path: 'user-security/role-management/add',
    component: RoleManagement,
    permission: 'Admin-Only',
  },
  {
    name: 'Role Management Form Edit',
    path: 'user-security/role-management/edit/:roleId',
    component: RoleManagement,
    permission: 'Admin-Only',
  },
  {
    name: 'Map Layers',
    path: 'map-layers',
    component: MapLayers,
    permission: 'Admin-Only',
  },
  {
    name: 'Map Layers',
    path: 'map-layers/add',
    component: MapLayers,
    permission: 'Admin-Only',
  },
  {
    name: 'Map Layers Edit',
    path: 'map-layers/edit/:mapLayerId',
    component: MapLayers,
    permission: 'Admin-Only',
  },
  {
    name: 'Daily Trivia Add',
    path: 'fun-trivia/add',
    component: DailyTrivia,
    permission: 'Admin-Only',
  },
  {
    name: 'Daily Trivia Edit',
    path: 'fun-trivia/edit/:triviaId',
    component: DailyTrivia,
    permission: 'Admin-Only',
  },
  {
    name: 'Activity Logs',
    path: 'activity-logs',
    component: ActivityLogs,
    permission: 'admin-only',
  },
  {
    name: 'Tutorials',
    path: 'tutorials/videos',
    component: VideosTutorial,
    permission: 'Admin-Only',
  },
  {
    name: 'Tutorials',
    path: 'tutorials/manual',
    component: Manual,
    permission: 'Admin-Only',
  },
  {
    name: 'Tutorials',
    path: 'tutorials/faqs',
    component: FAQComponent,
    permission: 'Admin-Only',
  },
];

export default adminDashboardRoutes;
