import LegendItem from '../LegendItem';
import DonutLegendItem from '../DonutLegendItem';
import { ILegendProps } from '../types';
import { removeKeyFromObject } from '../utils';
import PieChartLegend from './PieChartLegend';
import PieChartLegendItem from './PieChartLegendItem';

export default function ChartLegend<T>({
  data,
  type = 'bar',
  fills = ['#418FDE', '#FF671F'],
  chartId,
  showPound,
  hasDetails,
}: ILegendProps<T>) {
  if (type === 'donut') {
    return (
      <div className="legend naxatw-col-span-12 naxatw-flex naxatw-items-center naxatw-justify-start sm:naxatw-col-span-6 lg:naxatw-col-span-6">
        <div className="naxatw-flex naxatw-w-full naxatw-justify-center">
          <div className="scrollbar naxatw-flex naxatw-flex-col naxatw-justify-center naxatw-gap-4">
            {data.map((key: any, index: any) => (
              <DonutLegendItem
                key={key.name}
                color={fills[index] || '#0088F8'}
                name={key.name}
                value={key.value}
                showPound={showPound}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (type === 'pieChart') {
    return (
      <div className="legend-pie-chart-wrapper naxatw-col-span-12 naxatw-hidden  naxatw-w-full naxatw-justify-self-center md:naxatw-block lg:naxatw-col-span-5">
        <div className="legend-pie-chart-container naxatw-h-full">
          {hasDetails ? (
            <PieChartLegend
              data={data}
              chartId={chartId}
              showPound={showPound}
            />
          ) : (
            <div className=" naxatw-grid naxatw-h-full naxatw-place-items-center">
              <div className="!naxatw-mb-8 lg:!naxatw-mb-0">
                {data?.map((key: any) => (
                  <PieChartLegendItem
                    key={key.name}
                    data={key}
                    chartId={chartId}
                    showPound={showPound}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  const keys: string[] = Object.keys(
    removeKeyFromObject(data[0], 'name' as keyof (typeof data)[0]),
  );

  return (
    <div className="legend naxatw-col-span-11 naxatw-col-start-1 naxatw-col-end-13">
      <div className="naxatw-flex naxatw-w-full naxatw-justify-center ">
        <div className="cover naxatw-flex naxatw-h-full naxatw-max-h-[4rem] naxatw-gap-4 naxatw-overflow-y-auto">
          {keys.map((key, index) => (
            <LegendItem
              key={key}
              color={fills[index] || '#0088F8'}
              name={key}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
