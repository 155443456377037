/* eslint-disable import/prefer-default-export */
// import prepareFormData from '@Utils/prepareFormData';
import { api, authenticated, fastApi } from '.';

export const getProgram = (params: Record<string, any>) => {
  return authenticated(api).get('/program/', { params });
};

export const getProgramByID = (id: string) => {
  return authenticated(api).get(`/program/${id}/`);
};
export const deleteProgramByID = (id: string) => {
  return authenticated(api).delete(`/program/${id}/`);
};
export const postProgram = (data: Record<string, any>) => {
  return authenticated(api).post('/program/', data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export const patchProgram = (payload: Record<string, any>, id: any) => {
  return authenticated(api).patch(`/program/${id}/`, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const getProgramTypeOptions = () => {
  return authenticated(api).get('/choice/?type=program_type');
};

export const getProgramList = () => {
  return authenticated(api).get('/previous-program/');
};

export const getWorkingAreas = (params: any) => {
  return authenticated(fastApi).get(`/infographics/working-area/`, { params });
};

export const getMarkers = (params: Record<string, any>) => {
  return authenticated(fastApi).get(`/infographics/program-marker/`, {
    params,
  });
};

export const generateProgramReport = (programmeId: any) => {
  return authenticated(fastApi).get(
    `/core/generate-program-data-report/?program=${programmeId}`,
  );
};
export const generateProgramDetails = (params: Record<string, any>) => {
  return authenticated(fastApi).get(`/core/generate-program-data-report/`, {
    params,
  });
};
