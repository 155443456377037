import { Link } from 'react-router-dom';
import { ColumnSort } from '@tanstack/react-table';
import { useQuery } from '@tanstack/react-query';
import { useTypedSelector } from '@Store/hooks';
import StatusChips from '@Components/common/StatusChips';
import { mappedStatus } from '@Constants/mainDashboard';
import ToolTip from '@Components/RadixComponents/ToolTip';
import { useState } from 'react';
import useSortingConfig from '@Hooks/useSortingConfig';
import {
  mapSortbyParamValue,
  reverseSortbyParamValue,
} from '@Constants/programmeDetails';
import { getProgrammeDetails } from '@Services/provincialProfile';
import NestedDataTable from '../NestedDataTable';

const columns = [
  {
    header: 'PROGRAMME',
    accessorKey: 'programs',
    className: 'naxatw-min-w-[300px]',
  },
  {
    header: 'PROJECT',
    accessorKey: 'project_name',
    className: 'naxatw-min-w-[300px]',
  },
  {
    header: 'FIRST TIER PARTNER',
    accessorKey: 'first_tier_partner',
    className: 'naxatw-min-w-[200px]',
  },
  {
    header: 'SECTOR',
    accessorKey: 'sector',
    className: 'naxatw-min-w-[250px]',
  },
  {
    header: 'WORKING PROVINCE',
    accessorKey: 'working_province',
  },
  {
    header: 'BUDGET ALLOCATED (£)',
    accessorKey: 'budget',
  },
  {
    header: 'CAMPAIGN GOALS',
    accessorKey: 'campaign_goal',
  },
  {
    header: 'STATUS',
    accessorKey: 'project_status',
    cell: ({ childRowData }: Record<string, any>) => {
      return (
        <div className="naxatw-mr-auto naxatw-py-4">
          {childRowData?.project_status ? (
            <StatusChips
              label={childRowData.project_status || ''}
              status={mappedStatus[childRowData.project_status] || ''}
            />
          ) : (
            '-'
          )}
        </div>
      );
    },
  },
  {
    header: '',
    accessorKey: '',
    cell: ({ parentRowData, childRowData }: Record<string, any>) => {
      return (
        <Link
          to={`/explore-by/programmes/${parentRowData?.program_id}/?componentId=${childRowData?.project_id}`}
        >
          <ToolTip
            name="open_in_new"
            message="Project Details"
            className="naxatw-rounded-full naxatw-p-1 naxatw-text-xl naxatw-text-primary-600 naxatw-duration-300 hover:naxatw-bg-primary-200"
            iconClick={() => {
              window.open(
                `/explore-by/programmes/${parentRowData?.program_id}/?componentId=${childRowData?.project_id}`,
                '_blank',
              );
            }}
          />
        </Link>
      );
    },
  },
];

interface IProgrammeTable {
  searchTerm: string;
}

const ProgrammeTable = ({ searchTerm }: IProgrammeTable) => {
  const programmeFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.program,
  );

  const componentFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.component,
  );

  const firstTierPartnerParams = useTypedSelector(
    state => state.mainDashboard.filterParams.firstTierPartner,
  );

  const sectorGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.sector,
  );

  const markerGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.markerGroup,
  );

  const subMarkersParams = useTypedSelector(
    state => state.mainDashboard.filterParams.subMarkers,
  );

  const startDate = useTypedSelector(
    state => state.mainDashboard.filterParams.startDate,
  );
  const endDate = useTypedSelector(
    state => state.mainDashboard.filterParams.endDate,
  );

  const statusFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.status,
  );

  const [sorting, setSorting] = useState<ColumnSort>({ id: '', desc: false });

  const { sortBy, sortOrderKey, sortDir } = useSortingConfig(
    sorting?.id,
    sorting.desc,
  );

  const { data: programmeDetails, isLoading: programmeDetailsIsLoading } =
    useQuery({
      queryKey: [
        'programme-details-table-data',
        programmeFilterParams,
        componentFilterParams,
        firstTierPartnerParams,
        sectorGroupParams,
        markerGroupParams,
        subMarkersParams,
        startDate,
        endDate,
        statusFilterParams,
        searchTerm || '',
        sortBy,
        sortDir,
      ],
      queryFn: async () =>
        getProgrammeDetails({
          program: programmeFilterParams?.join(','),
          project: componentFilterParams?.join(','),
          first_tier_partner: firstTierPartnerParams?.join(','),
          sector: sectorGroupParams?.join(','),
          marker: markerGroupParams?.join(','),
          marker_category: subMarkersParams?.join(','),
          start_date: startDate,
          end_date: endDate,
          status: statusFilterParams,
          search_term: searchTerm || '',
          [sortOrderKey]: sortDir,
          sort_by: sortBy,
        }),
      select: res => res.data,
    });

  return (
    <div className="naxatw-h-full">
      <NestedDataTable
        columns={columns}
        data={programmeDetails}
        mainDataKey="programs"
        nestingDataKey="data"
        isLoading={programmeDetailsIsLoading}
        sortingState={{ ...sorting, id: reverseSortbyParamValue(sorting.id) }}
        onSorting={opt =>
          setSorting({ id: mapSortbyParamValue(opt?.id), desc: opt.desc })
        }
      />
    </div>
  );
};

export default ProgrammeTable;
