import z from 'zod';

const passwordSchema = z.object({
  password: z
    .string()
    .min(8, 'Min 8 characters required')
    .regex(/[a-zA-Z]/, 'At least one alphabet required')
    .regex(/[0-9]/, 'At least one Number required')
    .regex(/[!@#$%^&*(),.?":{}|<>]/, 'At least one Special character required'),
  confirmPassword: z.string().min(1, 'Confirm Password Required'),
});
export default passwordSchema;
