import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTypedSelector } from '@Store/hooks';
import ChartRenderer from '@Components/common/Charts/ChartRenderer';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { viewByOptionsSectorsCard } from '@Constants/mainDashboard';
import {
  getTopFiveSectorsByComponent,
  getTopFiveSectorsByProgramme,
} from '@Services/mainDashboard';
import { setSectorsSelectedTab } from '@Store/actions/provincialProfile';

function Sectors() {
  const { provinceId } = useParams();
  const downloadComponentRef = useRef<any>(null);

  const sector = useTypedSelector(
    state => state.provincialProfile.chartSwitchTab.sector,
  );
  const filterBy = useTypedSelector(state => state.provincialProfile.filterBy);

  const chartData = {
    id: `top-sectors-by-budget-allocation-province-${provinceId}-${sector}`,
    queryFn:
      sector === 'Project'
        ? getTopFiveSectorsByComponent
        : getTopFiveSectorsByProgramme,
    params: { province: provinceId, status: filterBy, project_status: sector },
    title: `Top Sectors by ${sector}`,
    hasDownloadBtn: true,
    chartType: 'bar',
    hasHeader: true,
    fill: ['#B7E7E4'],
    xLabel: 'Sectors',
    yLabel: `${sector} Count`,
    name: 'top-sectors-by-programme-project',
    height: '18rem',
    xLabelClassname: '!naxatw-py-4',
    switchTabData: {
      switchTabOptions: viewByOptionsSectorsCard,
      dispatchAction: setSectorsSelectedTab,
      activeLabel: sector,
    },
    hoverTitle: `${sector} Count`,
    yLabelClassNames: 'custom-y-label',
    showYAxisIntOnly: true,
  };

  return (
    <div ref={downloadComponentRef} className="naxatw-min-h-[22rem]">
      <ChartRenderer data={chartData} />
    </div>
  );
}
export default hasErrorBoundary(Sectors);
