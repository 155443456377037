import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { getMarkers } from '@Services/program';
import { useQuery } from '@tanstack/react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import SegmentCard from '../Cards/Segment';
import MarkersCard from './card';

type Marker = {
  marker_category: string;
  marker_value: string[];
};

function Markers() {
  const [searchParams] = useSearchParams();

  const { programmeId } = useParams();
  const componentId = searchParams.get('componentId');
  const { data, isLoading } = useQuery<any, any, { data: Marker[] }[], any>({
    queryKey: ['markers', programmeId, componentId],
    queryFn: () =>
      getMarkers({
        [componentId ? 'project' : 'program']: componentId || programmeId,
      }),
    select: res => res.data,
  });

  if (!isLoading && (!data || data?.length === 0)) return null;
  return (
    <SegmentCard title="Markers" iconName="donut_small">
      {isLoading ? (
        <div className="naxatw-grid naxatw-grid-cols-2 naxatw-gap-3">
          <Skeleton className="naxatw-h-[3rem] naxatw-w-full naxatw-rounded-lg" />
          <Skeleton className="naxatw-h-[3rem] naxatw-w-full naxatw-rounded-lg" />
          <Skeleton className="naxatw-h-[3rem] naxatw-w-full naxatw-rounded-lg" />
          <Skeleton className="naxatw-h-[3rem] naxatw-w-full naxatw-rounded-lg" />
        </div>
      ) : (
        <div className="naxatw-grid naxatw-w-full naxatw-grid-cols-2 naxatw-gap-2">
          {data?.map(markers =>
            markers?.data?.map(marker => (
              <MarkersCard
                markers={marker?.marker_value}
                markerName={marker?.marker_category}
                key={uuidv4()}
                className="naxatw-min-w-[30%]"
              />
            )),
          )}
        </div>
      )}
    </SegmentCard>
  );
}
export default hasErrorBoundary(Markers);
