/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import Skeleton from '@Components/RadixComponents/Skeleton';
import { getMunicipalityList } from '@Services/portfolioProfile';
import { useTypedSelector } from '@Store/hooks';
import { useQuery } from '@tanstack/react-query';
import { useParams, useSearchParams } from 'react-router-dom';

export default function SearchList({ onClose }: { onClose: () => void }) {
  const [, setSearchParams] = useSearchParams();
  const { provinceId } = useParams();
  const searchedText = useTypedSelector(
    state => state.provincialProfile.searchedText,
  );
  const filterBy = useTypedSelector(state => state.provincialProfile.filterBy);
  const { data: municipalityList, isLoading } = useQuery({
    queryKey: ['municipality-list', provinceId, searchedText, filterBy],
    queryFn: async () =>
      getMunicipalityList({
        province: provinceId,
        search: searchedText,
        status: filterBy,
      }),
    enabled: !!provinceId || !!searchedText,
    select: res => res.data,
  });

  return (
    <div className=" naxatw-absolute naxatw-top-12 naxatw-flex  naxatw-max-h-[22rem] naxatw-w-full naxatw-flex-col naxatw-overflow-y-scroll naxatw-bg-white naxatw-p-2 naxatw-py-1 naxatw-shadow-lg">
      {isLoading ? (
        <div className=" naxatw-flex naxatw-flex-col naxatw-gap-1 naxatw-border-b naxatw-p-2">
          <Skeleton className="naxatw-h-8 naxatw-w-full" />
          <Skeleton className="naxatw-h-8 naxatw-w-full" />
          <Skeleton className="naxatw-h-8 naxatw-w-full" />
        </div>
      ) : municipalityList?.length === 0 ? (
        <div className="naxatw-p-2 naxatw-text-center naxatw-text-sm naxatw-font-semibold naxatw-text-[#475467]">
          No data found
        </div>
      ) : (
        municipalityList?.map((municipality: any) => (
          <div
            className="naxatw-cursor-pointer naxatw-border-b naxatw-border-[#EAECF0] naxatw-p-2 naxatw-text-sm naxatw-font-semibold naxatw-text-[#475467]"
            key={municipality?.id}
            onClick={() => {
              setSearchParams({
                municipality: municipality?.id,
              });
              onClose();
            }}
          >
            {municipality.name}
          </div>
        ))
      )}
    </div>
  );
}
