/* eslint-disable no-unneeded-ternary */
/* eslint-disable consistent-return */
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useMapLibreGLMap } from '@Components/common/MapLibreComponents';
import MapContainer from '@Components/common/MapLibreComponents/MapContainer';
import VectorTileLayer from '@Components/common/MapLibreComponents/Layers/VectorTileLayer';
import MapTools from '@Components/common/MapTools';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { useTypedSelector } from '@Store/hooks';
import { useEffect, useRef, useState } from 'react';
import Spinner from '@Components/common/Spinner';
import { defaultLayerStyle } from '@Constants/map';
import { getStyleByGeomType } from '@Utils/index';

import {
  getFederalWiseCount,
  getOverlayLayerList,
} from '@Services/mainDashboard';
import {
  setMainDashboardStates,
  toggleOverlayPopup,
} from '@Store/actions/mainDashboard';
import { setProvincialProfileStates } from '@Store/actions/provincialProfile';
import BaseLayerSwitcher from '@Components/common/MapLibreComponents/BaseLayerSwitcher';
import MapAdditionalTools from '@Components/MainDashboard/MapSection/MapAdditionalTools';
import MapHeader from '@Components/MainDashboard/MapSection/MapHeader';
import switchTile from '@Utils/switchVectorTile';
import useRouteParamQuery from '@Hooks/useRouteParamQuery';
import RangeSlider from '@Components/RadixComponents/RangelSlider';
import AdditionalInfoPopup from '@Components/MainDashboard/MapSection/Map/AdditionalInfoPopup';
import Legend from './Legend';

const BASEURL = process.env.API_URL_V1;

interface MapProps {
  lat: number;
  lon: number;
  zoom?: number;
}
const Map = ({ lat, lon, zoom }: MapProps) => {
  const mapRef = useRef(null);
  const dispatch = useDispatch();
  const getQueryParams = useRouteParamQuery();
  const { provinceId, programmeId } = useParams();
  const componentId = getQueryParams.get('componentId');
  const queryClient = useQueryClient();

  const [hoveredFeature, setHoveredFeature] = useState<Record<
    string,
    any
  > | null>(null);
  const [fillOpacity, setFillOpacity] = useState(0.7);
  const [showAdditionalInfo, setShowAdditionalInfo] = useState<boolean>(true);

  const activeBaseLayer = useTypedSelector(
    state => state.provincialProfile.activeBaseLayer,
  );
  const activeViewBy = useTypedSelector(
    state => state.mainDashboard.map.activeViewBy,
  );
  const overlayLayerList = useTypedSelector(
    state => state.mainDashboard.overlayLayerList,
  );

  // fetch layers and set them to state
  useQuery({
    queryKey: ['overlay-layers'],
    queryFn: getOverlayLayerList,
    select: res => res.data.data,
    onSuccess: res => {
      const modifiedResponse = res?.map((layer: Record<string, any>) => ({
        ...layer,
        checked: false,
      }));
      dispatch(setMainDashboardStates({ overlayLayerList: modifiedResponse }));
    },
    enabled: overlayLayerList === null,
  });

  const { data: countMaping, isLoading } = useQuery({
    queryKey: ['province-count-mapping', activeViewBy, componentId],
    queryFn: async () =>
      getFederalWiseCount({
        federal_type: activeViewBy,
        by: 'program',
        program: programmeId,
        project: componentId,
      }),
    enabled: !!programmeId,
    select: res => res.data,
  });

  const { map, isMapLoaded } = useMapLibreGLMap({
    mapOptions: {
      center: [lon || 87.2720188783414, lat || 27.2101109726257],
      minZoom: zoom || 7,
      maxZoom: 19,
      preserveDrawingBuffer: true,
      maxBounds: [
        [73.916016, 23.785345],
        [94.669189, 32.879587],
      ],
    },
  });

  // code block to change the fill and outline on hover
  const getExpressionForFillOnly = (fillIds: string[], colorCode: string) => {
    const expression = ['match', ['get', 'id']];
    if (!fillIds?.length) return '#FFFFFF';
    fillIds?.forEach((id: string) => {
      if (expression.includes(id) || !id) return;
      expression.push(id);
      expression.push(colorCode);
    });
    expression.push('#FFFFFF');
    return expression;
  };

  useEffect(() => {
    if (!map || !countMaping || !isMapLoaded) return;
    const fillData = getExpressionForFillOnly(
      countMaping?.map((itm: any) => itm?.id),
      '#08519C',
    );
    if (!map.getLayer(`vectorTile-${activeViewBy}-${componentId}`)) return;
    map.setPaintProperty(
      `vectorTile-${activeViewBy}-${componentId}`,
      'fill-color',
      fillData,
    );

    // to set the outline to green of the map when hovered
    if (hoveredFeature?.isHovered) {
      map.setPaintProperty(
        `vectorTile-${activeViewBy}-${componentId}-outline`,
        'line-color',
        ['match', ['get', 'id'], hoveredFeature?.id, '#DE0A26', '#808080'],
      );
      map.setPaintProperty(
        `vectorTile-${activeViewBy}-${componentId}-outline`,
        'line-opacity',
        ['match', ['get', 'id'], hoveredFeature?.id, 1.5, 0.1],
      );
    } else {
      map.setPaintProperty(
        `vectorTile-${activeViewBy}-${componentId}-outline`,
        'line-color',
        '#808080',
      );
    }
  }, [
    map,
    provinceId,
    hoveredFeature,
    countMaping,
    activeViewBy,
    componentId,
    isMapLoaded,
  ]);

  useEffect(() => {
    if (!map) return;
    if (
      !map.getLayer(`vectorTile-${activeViewBy}-${componentId}`) &&
      !map.getLayer(`vectorTile-${activeViewBy}-${componentId}-outline`)
    )
      return;
    map.setPaintProperty(
      `vectorTile-${activeViewBy}-${componentId}`,
      'fill-opacity',
      fillOpacity,
    );
  }, [activeViewBy, fillOpacity, map, componentId]);

  useEffect(
    () => () => {
      queryClient.invalidateQueries({
        queryKey: ['overlay-layers'],
      });
      dispatch(toggleOverlayPopup(false));
    },
    [dispatch, queryClient],
  );

  return (
    <div style={{ width: '100%', height: '100%' }} ref={mapRef}>
      <MapContainer
        style={{ width: '100%', height: '100%' }}
        map={map}
        isMapLoaded={isMapLoaded}
      >
        <MapHeader />
        <MapAdditionalTools
          mapRef={mapRef}
          isFetching={isLoading}
          sourceIdToLoad={`vectorTile-${activeViewBy}-${componentId}`}
        />
        <BaseLayerSwitcher
          activeLayer={activeBaseLayer}
          onBaseLayerChange={layerId =>
            dispatch(setProvincialProfileStates({ activeBaseLayer: layerId }))
          }
        />

        {overlayLayerList &&
          overlayLayerList?.map(layer => (
            <VectorTileLayer
              key={layer.id}
              id={`vectorTile-${layer.layer__name_en}`}
              isMapLoaded={isMapLoaded}
              url={`${BASEURL}/feature_collection_tile/{z}/{x}/{y}.pbf?feature=${layer.id}`}
              layerOptions={getStyleByGeomType(
                layer?.geometry_type,
                layer?.vector_layer_style__style_json,
              )}
              visibleOnMap={layer.checked}
              showLabel
            />
          ))}

        <VectorTileLayer
          id={`vectorTile-${activeViewBy}-${componentId}`}
          isMapLoaded={isMapLoaded}
          url={`${BASEURL}/${switchTile(activeViewBy)}/{z}/{x}/{y}.pbf`}
          layerOptions={defaultLayerStyle}
          onHover={feature => {
            setHoveredFeature(feature);
          }}
          interactions={['hover']}
          showOutline
          showNamePopup
        />

        {/* --------------- map legend ---------------  */}

        {!isMapLoaded && (
          <div className="naxatw-absolute naxatw-right-[50%] naxatw-top-[50%] naxatw-block naxatw-max-w-sm naxatw-items-center naxatw-p-6">
            <Spinner />
          </div>
        )}

        {/* --------------- map tools ---------------  */}
        <MapTools map={map} />
        <div className="actions naxatw-absolute naxatw-bottom-5 naxatw-right-36 naxatw-z-10 naxatw-w-28">
          <RangeSlider
            className="naxatw-h-8"
            max={100}
            step={1}
            value={[fillOpacity * 100]}
            onValueChange={(value: number[]) => setFillOpacity(value[0] / 100)}
          />
        </div>

        {showAdditionalInfo && (
          <AdditionalInfoPopup
            hasCloseBtn
            onClose={() => setShowAdditionalInfo(false)}
            countMaping={countMaping}
          />
        )}
        <Legend />
      </MapContainer>
    </div>
  );
};

export default hasErrorBoundary(Map);
