import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import InfoDialog from '@Components/common/InfoDialog';
import { FlexColumn } from '@Components/common/Layouts';
import InputLabel from '@Components/common/InputLabel';
import { FormControl, Input } from '@Components/common/FormUI';
import { Button } from '@Components/RadixComponents/Button';
import { forgotPassword } from '@Services/authentication';
import EmailImg from '@Assets/images/email.png';

const initialState = {
  email: '',
};

function isValidEmail(email: string) {
  return /\S+@\S+\.\S+/.test(email);
}

const ForgotPassword = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: initialState,
    mode: 'onBlur',
  });

  const { mutate, isError, error, isLoading, isSuccess } = useMutation({
    mutationFn: forgotPassword,
  });

  function onSubmit(data: typeof initialState) {
    mutate({ email: data?.email?.trim() });
  }

  return (
    <>
      {!isSuccess ? (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="naxatw-flex naxatw-flex-col naxatw-gap-10"
        >
          <FlexColumn className="naxatw-gap-3">
            <h6 className="naxatw-text-xl naxatw-font-medium naxatw-text-black">
              Forgot Password
            </h6>
            <p className="naxatw-body-sm">
              Enter the email address and we will send you a link to reset your
              password.
            </p>
          </FlexColumn>
          {isError ? (
            <InfoDialog status="error">
              {/* @ts-ignore */}
              {error?.response?.data?.Message || 'Something is not right.'}
            </InfoDialog>
          ) : null}
          <FlexColumn className="naxatw-gap-5">
            <FormControl>
              <InputLabel label="Email" />
              <Input
                id="email"
                placeholder="Enter your Email"
                type="text"
                className="naxatw-mt-2"
                {...register('email', {
                  validate: {
                    notWhitespace: value =>
                      value?.trim() !== '' || 'Enter an email address',
                    pattern: value =>
                      isValidEmail(value) || 'Invalid email format',
                  },
                })}
              />
              {errors?.email && (
                <span className="naxatw-mt-2 naxatw-text-sm naxatw-text-red-500">
                  {errors?.email?.message}
                </span>
              )}
            </FormControl>
          </FlexColumn>
          <FlexColumn className="naxatw-gap-3">
            <Button type="submit" isLoading={isLoading} disabled={isLoading}>
              Next
            </Button>
            <Button
              type="button"
              variant="link"
              className="naxatw-mx-auto naxatw-w-fit !naxatw-p-0 naxatw-font-medium"
              onClick={() => {
                navigate('/login');
              }}
            >
              Back to Login
            </Button>
          </FlexColumn>
        </form>
      ) : (
        <FlexColumn className="naxatw-gap-10 naxatw-py-10">
          <img
            src={EmailImg}
            alt="Email"
            className="naxatw-mx-auto naxatw-w-[6.25rem]"
          />
          <FlexColumn className="naxatw-gap-3">
            <h6 className="naxatw-text-center naxatw-text-black naxatw-opacity-[87%]">
              Check Your Mail
            </h6>
            <p className="naxatw-text-center naxatw-text-[0.875rem] naxatw-text-black naxatw-opacity-[87%]">
              We have sent a password recover instruction in your mail.
            </p>
          </FlexColumn>
        </FlexColumn>
      )}
    </>
  );
};

export default ForgotPassword;
