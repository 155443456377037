/* eslint-disable no-nested-ternary */
import Icon from '@Components/common/Icon';
import Skeleton from '@Components/RadixComponents/Skeleton';
import isEmpty from '@Utils/isEmpty';

interface INoDataAvailableProps {
  hasCloseBtn?: boolean;
  onClose?: () => void;
  text?: string;
  countMaping?: Record<string, any>[];
  className?: string;
}

const AdditionalInfoPopup = ({
  hasCloseBtn,
  onClose = () => {},
  text,
  countMaping,
  className,
}: INoDataAvailableProps) => {
  return (
    <div
      className={`naxatw-absolute naxatw-right-5 naxatw-top-5 naxatw-z-10 naxatw-max-w-[18rem] naxatw-overflow-hidden naxatw-rounded-lg naxatw-border naxatw-border-[#D7D7D7] naxatw-bg-white naxatw-p-2 ${className || ''}`}
    >
      {hasCloseBtn && (
        <div className="naxatw-absolute naxatw-right-[0.35rem] naxatw-top-[0.35rem] naxatw-cursor-pointer naxatw-rounded-full hover:naxatw-bg-green-100">
          <Icon
            name="close"
            className="naxatw-text-[0.85rem]"
            onClick={() => onClose()}
          />
        </div>
      )}
      <div className="naxatw-mb-1 naxatw-flex  naxatw-items-center naxatw-gap-1">
        <p className=" naxatw-text-[0.8rem] naxatw-font-bold naxatw-leading-4 naxatw-text-[#833177]">
          Note
        </p>{' '}
      </div>
      <p className="naxatw-mb-2 naxatw-py-0 naxatw-text-xs naxatw-leading-4 naxatw-text-matt-200 ">
        {text || ''}
      </p>

      <p className="naxatw-mb-1 naxatw-text-xs naxatw-font-medium naxatw-leading-4 naxatw-text-matt-200">
        Number of Projects by Working Area coverage:
      </p>
      <div className="naxatw-flex naxatw-flex-col">
        <span className="naxatw-flex naxatw-items-center naxatw-gap-1 naxatw-text-xs naxatw-text-matt-200">
          National Level -{' '}
          {!countMaping ? (
            <Skeleton className="naxatw-h-4 naxatw-w-[0.875rem]" />
          ) : isEmpty(countMaping) ? (
            0
          ) : (
            (countMaping?.find(
              (count: Record<string, any>) => count.name === 'National Level',
            )?.count ?? 0)
          )}
        </span>
        <span className="naxatw-flex naxatw-items-center naxatw-gap-1 naxatw-text-xs naxatw-text-matt-200">
          Provincial Level -{' '}
          {!countMaping ? (
            <Skeleton className="naxatw-h-4 naxatw-w-[0.875rem]" />
          ) : isEmpty(countMaping) ? (
            0
          ) : (
            (countMaping?.find(
              (count: Record<string, any>) => count.name === 'Province Count',
            )?.count ?? 0)
          )}
        </span>

        <span className="naxatw-flex naxatw-items-center naxatw-gap-1 naxatw-text-xs naxatw-text-matt-200">
          Palika Level -{' '}
          {!countMaping ? (
            <Skeleton className="naxatw-h-4 naxatw-w-[0.875rem]" />
          ) : isEmpty(countMaping) ? (
            0
          ) : (
            (countMaping?.find(
              (count: Record<string, any>) =>
                count.name === 'Municipality Count',
            )?.count ?? 0)
          )}
        </span>
      </div>
    </div>
  );
};

export default AdditionalInfoPopup;
