/* eslint-disable no-unused-vars */
import { IRoute } from '@Routes/types';
import Partners from '@Components/AdminPanel/Partners';
import Programmes from '@Components/AdminPanel/Programs';
import Components from '@Components/AdminPanel/Components';
import Sector from '@Components/AdminPanel/Sectors/Sector';
import MarkerCategories from '@Components/AdminPanel/Markers/MarkerCategories';
import MarkerValues from '@Components/AdminPanel/Markers/MarkerValues';
import KnowledgeRepository from '@Components/AdminPanel/KnowledgeRepository';
import ProvincialProfile from '@Components/AdminPanel/ProvincialProfile';
import MapLayers from '@Components/AdminPanel/MapLayers';
import ReportComponent from '@Components/AdminPanel/Report';
import UserManagement from '@Components/AdminPanel/UserSecurity/UserManagement';
import RoleManagement from '@Components/AdminPanel/UserSecurity/RoleManagement';
import DailyTrivia from '@Components/AdminPanel/DailyTrivia';
import { checkKeyInPermssion } from '@Utils/index';
import ActivityLogs from '@Components/AdminPanel/ActivityLogs';
import VideosTutorial from '@Components/AdminPanel/Tutorials/Videos';
import Feedback from '@Components/AdminPanel/Feedback';

export const otherUserDashboardLinks = (permissions: any) => {
  return [
    {
      name: '5WH Report',
      icon: 'lab_profile',
      path: 'report',
      component: ReportComponent,
    },
    ...(checkKeyInPermssion(permissions, 'program')
      ? [
          {
            name: 'Programme Registration',
            icon: 'description',
            path: 'programmes',
            component: Programmes,
          },
        ]
      : []),
    ...(checkKeyInPermssion(permissions, 'project data') ||
    checkKeyInPermssion(permissions, 'project')
      ? [
          {
            name: '5WH Data',
            icon: 'folder_open',
            path: 'projects',
            component: Components,
          },
        ]
      : []),
    ...(checkKeyInPermssion(permissions, 'knowledge repository')
      ? [
          {
            name: 'Knowledge Repository',
            icon: 'menu_book',
            path: 'knowledge-repository',
            component: KnowledgeRepository,
          },
        ]
      : []),
    ...(checkKeyInPermssion(permissions, 'organization')
      ? [
          {
            name: 'First Tier Partners',
            icon: 'handshake',
            path: 'partners',
            component: Partners,
          },
        ]
      : []),
    ...(checkKeyInPermssion(permissions, 'provincial goal')
      ? [
          {
            name: 'Provincial Profile',
            icon: 'lab_profile',
            path: 'provincial-profile',
            component: ProvincialProfile,
          },
        ]
      : []),
    ...(checkKeyInPermssion(permissions, 'marker category') ||
    checkKeyInPermssion(permissions, 'marker value') ||
    checkKeyInPermssion(permissions, 'sector')
      ? [
          {
            name: 'Others',
            icon: 'more_horiz',
            path: '#',
            component: Partners,
          },
        ]
      : []),
  ];
};

export const otherUserSubSideBarDashboardLinks = (permissions: any) => {
  return [
    ...(checkKeyInPermssion(permissions, 'sector')
      ? [
          {
            name: 'Sectors',
            path: 'sectors/sector',
            component: Sector,
          },
        ]
      : []),
    ...(checkKeyInPermssion(permissions, 'marker category')
      ? [
          {
            name: 'Markers Categories',
            path: 'markers/markers-categories',
            component: MarkerCategories,
          },
        ]
      : []),
    ...(checkKeyInPermssion(permissions, 'marker value')
      ? [
          {
            name: 'Markers Values',
            path: 'markers/markers-values',
            component: MarkerValues,
          },
        ]
      : []),
  ];
};

export const adminSidebarData: IRoute[] = [
  {
    name: '5WH Report',
    icon: 'lab_profile',
    path: 'report',
    component: ReportComponent,
  },
  {
    name: '5WH Data',
    icon: 'folder_open',
    path: 'projects',
    component: Components,
  },
  {
    name: 'Programme Registration',
    icon: 'description',
    path: 'programmes',
    component: Programmes,
  },
  {
    name: 'Provincial Profile',
    icon: 'lab_profile',
    path: 'provincial-profile',
    component: ProvincialProfile,
  },
  {
    name: 'Knowledge Repository',
    icon: 'menu_book',
    path: 'knowledge-repository',
    component: KnowledgeRepository,
  },
  {
    name: 'First Tier Partners',
    icon: 'handshake',
    path: 'partners',
    component: Partners,
    permission: 'Can view organization',
  },
  {
    name: 'Others',
    icon: 'more_horiz',
    path: '#',
    component: Partners,
  },
];

export const adminSubSideBar: IRoute[] = [
  {
    name: 'Sectors',
    path: 'sectors/sector',
    component: Sector,
  },
  {
    name: 'Markers Categories',
    path: 'markers/markers-categories',
    component: MarkerCategories,
  },
  {
    name: 'Markers Values',
    path: 'markers/markers-values',
    component: MarkerValues,
  },
  {
    name: 'User Management',
    path: 'user-security/user-management',
    component: UserManagement,
    permission: 'Admin-Only',
  },
  {
    name: 'Role Management',
    path: 'user-security/role-management',
    component: RoleManagement,
    permission: 'Admin-Only',
  },
  {
    name: 'Map Layers',
    path: 'map-layers',
    component: MapLayers,
    permission: 'Admin-Only',
  },

  {
    name: 'Fun Trivia',
    path: 'fun-trivia',
    component: DailyTrivia,
    permission: 'Admin-Only',
  },
  {
    name: 'Activity Logs',
    path: 'activity-logs',
    component: ActivityLogs,
    permission: 'Admin-Only',
  },
  {
    name: 'Tutorials',
    path: 'tutorials/videos',
    component: VideosTutorial,
    permission: 'Admin-Only',
  },
  {
    name: 'Feedback',
    path: 'feedback',
    component: Feedback,
    permission: 'Admin-Only',
  },
];

export const programManagerSidebarData: IRoute[] = [
  {
    name: '5WH Report',
    icon: 'lab_profile',
    path: 'report',
    component: ReportComponent,
  },
  {
    name: '5WH Data',
    icon: 'folder_open',
    path: 'projects',
    component: Components,
  },
  {
    name: 'Programme Registration',
    icon: 'description',
    path: 'programmes',
    component: Programmes,
  },
  {
    name: 'Knowledge Repository',
    icon: 'menu_book',
    path: 'knowledge-repository',
    component: KnowledgeRepository,
  },
];

export const generalUserSidebarData: IRoute[] = [
  {
    name: '5WH Report',
    icon: 'lab_profile',
    path: 'report',
    component: ReportComponent,
  },
];

export const pefUserSidebarData: IRoute[] = [
  {
    name: '5WH Report',
    icon: 'lab_profile',
    path: 'report',
    component: ReportComponent,
  },
  {
    name: 'Provincial Profile',
    icon: 'lab_profile',
    path: 'provincial-profile',
    component: ProvincialProfile,
  },
  {
    name: 'Knowledge Repository',
    icon: 'menu_book',
    path: 'knowledge-repository',
    component: KnowledgeRepository,
  },
];
