/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import { FC, useEffect, useId, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button } from '@Components/RadixComponents/Button';
import ErrorMessage from '@Components/common/ErrorMessage';
import BreadCrumb from '@Components/common/FormComponent/BreadCrumb';
import getInputElement from '@Components/common/FormComponent/GetInputElement';
import { FormControl } from '@Components/common/FormUI';
import IconButton from '@Components/common/IconButton';
import InputLabel from '@Components/common/InputLabel';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import {
  isSuccessorDropdown,
  otherOption,
  ProgramFormFields,
} from '@Constants/FormConstants/programFormConstants';
import {
  FormFieldProps,
  ProgrammeFormProps,
} from '@Constants/interface/FormInterface';
import groupFormElements, { convertStringToBoolean } from '@Utils/index';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getProgramByID,
  getProgramList,
  getProgramTypeOptions,
  patchProgram,
  postProgram,
} from '@Services/program';
import { ProgramFormValidationSchema } from '@Validations/ProgramForm';
import { zodResolver } from '@hookform/resolvers/zod';
import FormSkeleton from '@Components/common/FormComponent/FormSkeleton';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { animated, useSpring } from '@react-spring/web';

const ProgramForm: FC<ProgrammeFormProps> = ({ onClose, setProgramme }) => {
  const [hasAnimated, setHasAnimated] = useState(false);
  const { programID } = useParams();
  const randomId = useId();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const queryClient = useQueryClient();
  const programFormField = groupFormElements(ProgramFormFields);

  const props = useSpring({
    from: { scale: 0 },
    to: { scale: 1 },
    config: { duration: 100 }, // Adjust duration as needed
    onRest: () => {
      if (!hasAnimated) {
        setHasAnimated(true); // Set it to true after the animation
      }
    },
  });

  const {
    formState: { errors },
    register,
    getValues,
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { dirtyFields, isSubmitting },
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      code: '',
      program_type: '',
      start_date: '',
      end_date: '',
      is_successor: '',
      previous_programs: [],
      other_previous_program: '',
    },
    resolver: zodResolver(ProgramFormValidationSchema),
  });

  const formProps = {
    register,
    control,
    getValues,
    setValue,
    errors,
    dirtyFields,
    reset,
    watch,
  };

  const disableEndDate = watch('start_date');
  const previousProgram: number | string[] = watch('previous_programs');
  const hasSuccessor = useWatch({
    control,
    name: 'is_successor',
    defaultValue: 'false',
  });
  const hasPreviousProgramme = convertStringToBoolean(hasSuccessor) || false;

  // dropdown options
  const { data: programList, isFetching: isProgramListFetching } = useQuery({
    queryFn: getProgramList,
    queryKey: ['program-list'],
    select: res =>
      res?.data?.map((program: Record<string, any>, index: number) => {
        return {
          id: index + 1,
          label: program.name,
          value: program.id,
        };
      }),
    onError: () => toast.error('Error occured!. Please try again'),
  });

  const { data: programTypeOptions, isFetching: isFetchingProgramTypeOptions } =
    useQuery({
      queryFn: getProgramTypeOptions,
      queryKey: ['program-type'],
      select: res =>
        res?.data?.map((program: Record<string, any>, index: number) => {
          return {
            id: index + 1,
            label: program.name,
            value: program.name,
          };
        }),
      onError: () => toast.error('Error occured!. Please try again'),
    });

  const dropDownOptions: any = {
    is_successor: isSuccessorDropdown,
    program_type: programTypeOptions,
    previous_programs: programList
      ? [...programList, otherOption]
      : [otherOption],
  };

  // post programme
  const { mutateAsync: postProgramData, isLoading: ispostProgramDataLoading } =
    useMutation({
      mutationFn: (payloadData: Record<string, any>) =>
        postProgram(payloadData),
      onSuccess: sucessResponse => {
        if (!sucessResponse) return;
        if (pathname?.includes('project')) {
          setProgramme(sucessResponse?.data?.id);
        }
      },
    });

  // patch programme
  const {
    mutateAsync: patchProgramData,
    isLoading: ispatchProgramDataLoading,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      patchProgram(payloadData, programID),
  });

  // data fetch to populate in edit
  const { isFetching: isFetchingProgramData } = useQuery({
    enabled: !!programID,
    queryKey: ['program', programID],
    queryFn: () => getProgramByID(programID || ''),
    select: data => data?.data,
    onError: () => toast.error('Error Occured!. Please try again.'),
    onSuccess: data => {
      if (data) {
        Object.keys(data)?.forEach((key: any) => {
          if (key === 'is_successor') {
            const booleanValueAsString = String(
              typeof data[key] === 'boolean' ? data[key] : '',
            );
            setValue(key, booleanValueAsString);
          } else if (key === 'previous_program_detail') {
            const previousData = data.previous_program_detail?.reduce(
              (
                selectedData: Record<string, any>,
                currData: Record<string, any>,
              ) => {
                selectedData.push(currData.id);
                return selectedData;
              },
              [],
            );
            const updatedPreviousData = data?.other_previous_program
              ? [...previousData, 'other-programs']
              : previousData;

            setValue('previous_programs', updatedPreviousData);
          } else {
            setValue(key, data[key]);
          }
        });
      }
    },
  });

  const getDirtyFieldValues = () => {
    const allValues = getValues();
    const dirtyValues: any = {};
    Object.keys(allValues).forEach((key: string) => {
      if (dirtyFields[key as keyof typeof dirtyFields]) {
        dirtyValues[key] = allValues[key as keyof typeof dirtyFields];
      }
    });

    return dirtyValues;
  };

  const handleFormSubmit = async () => {
    try {
      const values = getValues();
      const {
        is_successor: isSuccessor,
        previous_programs: previousPrograms,
        other_previous_program: otherPrograms,
        ...restValues
      } = values;

      const editFields = getDirtyFieldValues();

      if (programID) {
        if (editFields.hasOwnProperty('previous_programs')) {
          if (editFields?.previous_programs?.includes('other-programs')) {
            editFields.other_previous_program = otherPrograms;
          } else {
            editFields.other_previous_program = '';
          }
          editFields.previous_programs = previousPrograms.filter(
            prevPrograms => prevPrograms !== 'other-programs',
          );
        }

        if (editFields.hasOwnProperty('is_successor')) {
          editFields.is_successor = convertStringToBoolean(
            editFields.is_successor,
          );

          if (editFields.is_successor === false) {
            editFields.previous_programs = [];
            editFields.other_previous_program = '';
          }
        }
        await patchProgramData(editFields);
      } else {
        const payloadData = {
          ...restValues,
          is_successor: convertStringToBoolean(isSuccessor),
          previous_programs: previousPrograms.filter(
            prevPrograms => prevPrograms !== 'other-programs',
          ),
          otherPrograms,
        };

        await postProgramData(payloadData);
      }
      if (pathname?.includes('programmes')) {
        queryClient.invalidateQueries({ queryKey: ['program'] });
      } else {
        queryClient.invalidateQueries({
          queryKey: ['get-program-type-options'],
        });
      }

      toast.success(
        programID
          ? 'Programme details updated successfully'
          : 'New Programme added successfully ',
      );
      navigate(
        pathname?.includes('programmes')
          ? '/data-bank/programmes'
          : '/data-bank/project/add',
      );
      onClose();
      reset();
    } catch (e: any) {
      const errror = e?.response?.data?.error[0]?.details;

      toast.error(
        programID ? 'Failed to update the programme. Please try again' : errror,
      );
    }
  };

  useEffect(() => {
    setHasAnimated(false); // Reset before starting
  }, []);

  return (
    <div className="naxatw-absolute naxatw-left-1/2 naxatw-top-1/2 naxatw-flex naxatw-w-full naxatw-translate-x-[-50%] naxatw-translate-y-[calc(-50%+31.5px)] naxatw-items-center naxatw-justify-center sm:naxatw-w-[34.75rem]">
      <animated.div
        className="naxatw-flex naxatw-w-full naxatw-flex-col naxatw-rounded-2xl naxatw-border naxatw-border-gray-300 naxatw-bg-[#fff] naxatw-transition-all naxatw-duration-200 "
        style={{
          transform: props.scale.to(scale => `scale(${scale})`),
        }}
      >
        <div className="naxatw-flex naxatw-w-full naxatw-items-center naxatw-justify-between naxatw-px-7 naxatw-py-5 naxatw-shadow-light">
          <BreadCrumb
            heading={`Programme ${programID ? 'Edit' : 'Registration'} Form`}
            overlayStatus={() => onClose()}
          />
          <IconButton
            name="close"
            className="!naxatw-h-9 !naxatw-w-9 naxatw-gap-1 naxatw-rounded-lg hover:naxatw-bg-gray-100"
            iconClassName="naxatw-font-normal naxatw-text-[#757575] naxatw-text-[24px] naxatw-leading-[24px]"
            onClick={() => onClose()}
          />
        </div>
        <div className="naxatw-flex naxatw-w-full naxatw-gap-3 ">
          <div className="naxatw-w-full">
            <form
              onSubmit={e => {
                if (pathname?.includes('project')) {
                  e.stopPropagation();
                  handleSubmit(handleFormSubmit)(e);
                } else {
                  return handleSubmit(handleFormSubmit)(e);
                }
                return null;
              }}
            >
              <div className="naxatw-py-5 naxatw-pl-6 naxatw-pr-4">
                <FlexColumn className="scrollbar naxatw-h-[calc(100vh-20.8rem)] naxatw-w-full  naxatw-overflow-y-scroll naxatw-pr-2">
                  {isFetchingProgramData ||
                  isFetchingProgramTypeOptions ||
                  isProgramListFetching ? (
                    <FormSkeleton numRows={6} className="naxatw-w-full" />
                  ) : (
                    programFormField?.map(
                      (fieldRow: Record<string, any>, index: number) => (
                        <FlexRow
                          className="naxatw-mb-5 naxatw-gap-x-5 last:naxatw-mb-0 [&:nth-last-child(-n+3)]:naxatw-mb-0"
                          key={`${randomId}-${index}`}
                        >
                          {fieldRow?.map((field: FormFieldProps) => {
                            const { id } = field;
                            if (id === 'end_date') {
                              field.disabledDays = disableEndDate;
                            } else if (id === 'previous_programs') {
                              field.isVisible = hasPreviousProgramme;
                            } else if (id === 'other_previous_program') {
                              field.isVisible = previousProgram?.includes(
                                'other-programs',
                              )
                                ? hasPreviousProgramme
                                : false;
                            }
                            if (!field?.isVisible) return null;
                            return (
                              field.isVisible && (
                                <FormControl
                                  className={`naxatw-w-full naxatw-gap-[0.25rem] ${
                                    field.isVisible &&
                                    (field.id === 'other_previous_program' ||
                                      field.id === 'previous_programs')
                                      ? 'naxatw-mt-5'
                                      : ''
                                  }`}
                                  key={`${field.id}- ${field.name}`}
                                >
                                  <InputLabel
                                    label={field.label || ''}
                                    astric={field.required}
                                    id={field.id}
                                  />
                                  {getInputElement(
                                    {
                                      ...field,
                                    },
                                    /* @ts-ignore */
                                    formProps,

                                    dropDownOptions?.[id] || [],
                                  )}

                                  {
                                    /* @ts-ignore */
                                    formProps.errors[id] && (
                                      <ErrorMessage
                                        /* @ts-ignore */
                                        message={formProps.errors[id]?.message}
                                      />
                                    )
                                  }
                                </FormControl>
                              )
                            );
                          })}
                        </FlexRow>
                      ),
                    )
                  )}
                </FlexColumn>
              </div>

              <div className=" naxatw-flex naxatw-justify-center naxatw-py-5 naxatw-shadow-formshadow">
                <Button
                  size="normal"
                  variant="primary"
                  className="naxatw-px-4 naxatw-py-2"
                  type="submit"
                  isLoading={
                    ispostProgramDataLoading ||
                    ispatchProgramDataLoading ||
                    isSubmitting
                  }
                  disabled={
                    isFetchingProgramData ||
                    isFetchingProgramTypeOptions ||
                    isProgramListFetching ||
                    isSubmitting
                  }
                >
                  Save
                </Button>
              </div>
            </form>
          </div>
        </div>
      </animated.div>
    </div>
  );
};

export default hasErrorBoundary(ProgramForm);
