import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { resetPassword } from '@Services/authentication';
import { useForm } from 'react-hook-form';
import InfoDialog from '@Components/common/InfoDialog';
import { FlexColumn } from '@Components/common/Layouts';
import InputLabel from '@Components/common/InputLabel';
import { FormControl } from '@Components/common/FormUI';
import { Button } from '@Components/RadixComponents/Button';
import { toast } from 'react-toastify';
import PasswordInput from '@Components/common/FormUI/PasswordInput';
import passwordSchema from '@Validations/password';
import { zodResolver } from '@hookform/resolvers/zod';

const initialState = {
  password: '',
  confirmPassword: '',
};

const ResetPassword = () => {
  const navigate = useNavigate();
  const { uid, token } = useParams();

  const { mutate, isError, error, isLoading } = useMutation({
    mutationFn: resetPassword,
    onSuccess: () => {
      toast.success('Password reset successful.');
      navigate('/login');
    },
  });

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: initialState,
    resolver: zodResolver(passwordSchema),
    reValidateMode: 'onChange',
  });

  const onSubmit = (data: typeof initialState) => {
    if (data?.password !== data?.confirmPassword) {
      setError('confirmPassword', {
        message: 'Password and confirm password does not match',
      });
      return;
    }
    mutate({
      new_password: data?.password,
      confirm_password: data?.confirmPassword,
      token,
      uid,
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="naxatw-flex naxatw-flex-col naxatw-gap-10"
    >
      <FlexColumn className="naxatw-gap-3">
        <h6 className="naxatw-text-xl naxatw-font-medium naxatw-text-black">
          Reset Password
        </h6>
        <p className="naxatw-body-sm">Enter new password</p>
      </FlexColumn>
      {isError ? (
        <InfoDialog status="error">
          {(error as Error).message || 'Something is not right.'}
        </InfoDialog>
      ) : null}
      <FlexColumn className="naxatw-gap-5">
        <FormControl>
          <InputLabel label="Password" />
          <PasswordInput
            id="password"
            placeholder="Password"
            className="naxatw-mt-2"
            {...register('password', {
              required: true,
            })}
          />
          {errors?.password && (
            <span className="naxatw-mt-2 naxatw-text-sm naxatw-text-red-500">
              {errors?.password?.message}
            </span>
          )}
        </FormControl>
        <FormControl>
          <InputLabel label="Confirm Password" />
          <PasswordInput
            id="confirm-password"
            placeholder="Confirm Password"
            className="naxatw-mt-2"
            {...register('confirmPassword', {
              required: true,
            })}
          />
          {errors?.confirmPassword && (
            <span className="naxatw-mt-2 naxatw-text-sm naxatw-text-red-500">
              {errors?.confirmPassword?.message}
            </span>
          )}
        </FormControl>
      </FlexColumn>
      <FlexColumn className="naxatw-gap-3">
        <Button type="submit" isLoading={isLoading} disabled={isLoading}>
          Reset Password
        </Button>
        <Button
          type="button"
          variant="link"
          className="naxatw-mx-auto naxatw-w-fit !naxatw-p-0 naxatw-font-medium"
          onClick={() => {
            navigate('/login');
          }}
        >
          Back to Login
        </Button>
      </FlexColumn>
    </form>
  );
};

export default ResetPassword;
